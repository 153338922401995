import React from 'react'
import './styles.scss'
import { Input } from 'antd'

const Input2 = ({ size, suffix, prefix, type, ...props }) => (
  <div className="wrapper-input" data-size={size || 'standard'}>
    {suffix || ''}
    {type === 'password' ? <Input.Password {...props} /> : <Input {...props} />}

    {prefix || ''}
  </div>
)

export default Input2
