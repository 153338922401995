import React, { useMemo } from 'react'
import CurrencyFormat from 'react-currency-format'
import './style.scss'
import Skeleton from 'components/Skeleton'
import { isValid, roundNumber } from 'helpers/CommonHelper'
import { DECIMALS } from 'constants/index'
import { Link } from 'react-router-dom'
import { WithdrawIcon, DepositIcon, HistoryIcon } from 'widgets/Svg'
import { FormattedMessage } from 'react-intl'

const CardBalance = ({ currency }) => {
  const totalBalance = useMemo(() => {
    if (currency) {
      return currency.reduce((total, current) => total + current.balance * current.usd_rate, 0)
    }
    return undefined
  }, [currency])

  return (
    <div className="card-balance">
      <img src="/images/balance.png" alt="" />

      <div className="card-name">
        <span>
          <FormattedMessage id="Lottery" />
        </span>{' '}
        <FormattedMessage id="CeDeFi" />
      </div>

      <div className="card-header">
        <div className="balance-title">
          <FormattedMessage id="Balance (USD)" />{' '}
        </div>
      </div>
      <div className="card-content">
        {isValid(totalBalance) ? (
          <>
            <span>$</span>
            <CurrencyFormat value={roundNumber(totalBalance / 10 ** DECIMALS)} displayType="text" thousandSeparator />
          </>
        ) : (
          <Skeleton height="48px" />
        )}
      </div>
      <div className="card-button">
        <Link to="/wallet/deposit/USDL">
          <button type="button" className="card-button-item">
            <FormattedMessage id="Deposit" /> <DepositIcon />
          </button>
        </Link>
        <Link to="/wallet/withdraw/USDL">
          <button type="button" className="card-button-item">
            <FormattedMessage id="Withdraw" /> <WithdrawIcon />
          </button>
        </Link>
        <Link to="/wallet/history">
          <button type="button" className="card-button-item">
            <FormattedMessage id="History" /> <HistoryIcon />
          </button>
        </Link>
        <a href="https://tienso.online/?currency=USDL" target="blank">
          <button type="button" className="card-button-item">
            <img src="https://tienso.online/brand/c2cash/logo.png" alt="" height="30px" />
          </button>
        </a>
        <a href="https://unawa.id/" target="blank">
          <button type="button" className="card-button-item">
            <img src="https://unawa.id/home/logo-1.png" alt="" height="30px" />
          </button>
        </a>
      </div>
    </div>
  )
}

export default CardBalance
