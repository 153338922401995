export const BUY_LOTTERY = 'BUY_LOTTERY'
export const GET_HISTORY_BUY = 'GET_HISTORY_BUY'
export const GET_HISTORY_BUY_SUCCESS = 'GET_HISTORY_BUY_SUCCESS'
export const GET_HISTORY_BUY_STATE = 'GET_HISTORY_BUY_STATE'
export const GET_LIST_GAME = 'GET_LIST_GAME'
export const GET_LIST_GAME_SUCCESS = 'GET_LIST_GAME_SUCCESS'
export const GET_GAME_DETAIL = 'GET_GAME_DETAIL'
export const GET_GAME_DETAIL_SUCCESS = 'GET_GAME_DETAIL_SUCCESS'
export const GET_GAME_DETAIL_STATE = 'GET_GAME_DETAIL_STATE'
export const GET_WINNER_LIST = 'GET_WINNER_LIST'
export const GET_GAME_REPORT = 'GET_GAME_REPORT'
export const GET_GAME_REPORT_SUCCESS = 'GET_GAME_REPORT_SUCCESS'
