import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import { Form } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import './styles.scss'

import { setAuthToken } from 'helpers/auth'
import AuthCard from 'modules/auth/components/AuthCard'
import AuthWrapper from 'modules/auth/components/AuthWrapper'
import ButtonSubmit from 'widgets/ButtonSubmit'
import Input from 'widgets/Input'
import FormItem from 'widgets/FormItem'
import VerifyAuthenCode from 'modules/auth/components/VerifyAuthenCode'
import VerifyEmailCode from 'modules/auth/components/VerifyEmailCode'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import { getProfile } from 'state/user/actions'
import { login } from 'state/auth/actions'
import { ArrowBackIcon } from 'widgets/Svg'
import { FormattedMessage } from 'react-intl'

const SignIn = ({ history, location }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [formValue, setFormValue] = useState({})
  const [view, setView] = useState({ view: 0, dataView: null })
  const [loading, setLoading] = useState(false)

  const onFormSubmit = () => {
    form.validateFields().then((values) => {
      executeRecaptcha((tokenCaptcha) => {
        setLoading(true)
        dispatch(
          login(
            { ...values, token_captcha: tokenCaptcha },
            (receipt) => {
              if (receipt.is_email) {
                setView({ view: 1, dataView: receipt })
              }
              if (receipt.is_2fa) {
                setView({ view: 2, dataView: receipt })
              }
              setFormValue(values)
              setLoading(false)
            },
            (error) => {
              if (error?.code === 'err_email_active_required') {
                history.push('/register', { view: 1, email: values.email })
              }
              setLoading(false)
            },
          ),
        )
      })
    })
  }

  const onFinishVerifyCode = useCallback(
    (receipt) => {
      const authToken = setAuthToken(receipt?.token)
      if (authToken) {
        dispatch(
          getProfile({}, () => {
            if (get(location, 'state.from')) {
              const pathName = get(location, 'state.from.pathname')
              const search = get(location, 'state.from.search')
              history.push(`${pathName}${search}`)
            } else {
              history.push('/wallet')
            }
          }),
        )
      }
    },
    [dispatch, history, location],
  )

  const onBack = useCallback(() => {
    setView({ view: 0, dataView: null })
  }, [])

  return (
    <AuthWrapper>
      <div className="wrapper-form-login">
        {view.view === 0 && (
          <AuthCard className="form-login fadeIn">
            <div className="header-auth-card">
              <div>
                <Link className="active" to="/login">
                  <span>
                    <FormattedMessage id="Sign in" />
                  </span>
                </Link>
                <Link to="/register">
                  <span>
                    <FormattedMessage id="Sign up" />
                  </span>
                </Link>
              </div>

              <Link to="/">
                <ArrowBackIcon />
              </Link>
            </div>

            <Form layout="vertical" form={form}>
              <FormItem
                label={
                  <span className="form-title">
                    <FormattedMessage id="Email" />
                  </span>
                }
                name="email"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="Please enter email field" />,
                  },
                ]}
              >
                <Input placeholder="example@gmail.com" />
              </FormItem>
              <FormItem
                label={
                  <div className="wrapper-label">
                    <span className="form-title">
                      <FormattedMessage id="Password" />
                    </span>
                    <Link to="/forgot-password" className="des-title">
                      <FormattedMessage id="Forgot Password ?" />
                    </Link>
                  </div>
                }
                name="password"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="Please enter password field" />,
                  },
                  {
                    min: 10,
                    message: (
                      <FormattedMessage id={`The 'password' field length must be greater than or equal to 10 characters long.`} />
                    ),
                  },
                ]}
              >
                <Input
                  placeholder="**********"
                  type="password"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </FormItem>
              <FormItem>
                <ButtonSubmit
                  type="submit"
                  className="form-action"
                  style={{ maxWidth: '100%' }}
                  loading={loading}
                  onClick={onFormSubmit}
                >
                  <FormattedMessage id="SIGN IN" />
                </ButtonSubmit>
              </FormItem>
            </Form>
            <div className="form-links">
              <FormattedMessage id="I don’t have an account ?" />
              <Link to="/register">
                <FormattedMessage id="Sign up" />
              </Link>
            </div>
          </AuthCard>
        )}
        {view.view === 1 && (
          <VerifyEmailCode
            type="login"
            formValue={formValue}
            token={view.dataView?.token}
            onFinish={onFinishVerifyCode}
            onBack={onBack}
          />
        )}
        {/* type="login" */}
        {view.view === 2 && <VerifyAuthenCode type="login" token={view.dataView?.token} onFinish={onFinishVerifyCode} />}
      </div>
    </AuthWrapper>
  )
}

export default SignIn
