import messagesEn from './translations/en.json'
import messagesVi from './translations/vi.json'
import messagesCn from './translations/cn.json'
import messagesMy from './translations/my.json'

export const CACHE_LANGUAGE = 'lang'
export const DEFAULT_LANGUAGE = 'en'

export const languages = {
  en: messagesEn,
  vi: messagesVi,
  cn: messagesCn,
  my: messagesMy,
}

export const OPTIONS_LANG = [
  { id: 'en', label: 'English', image: '/images/flags/en.png' },
  {
    id: 'cn',
    label: '中文',
    image: '/images/flags/cn.png',
  },
  {
    id: 'my',
    label: 'ខ្មែរ',
    image: '/images/flags/my.png',
  },
  {
    id: 'vi',
    label: 'Tiếng việt',
    image: '/images/flags/vn.png',
  },
]
