/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-nested-ternary */
import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import './styles.scss'
import TableRecentTransaction from './tableRecentTransaction'
import ItemAccountActivity from './ItemAccountActivity'
import ButtonGradient from 'widgets/ButtonGradient'
import { EditIcon, TickIcon } from 'widgets/Svg'
import { logout, requestForgotPassword, create2FA, getHistoryLogin } from 'state/auth/actions'
import { getProfile, updateProfile } from 'state/user/actions'
import { uploadFile } from 'state/file/actions'
import { getBase64 } from 'helpers'
import ChangePassword from './ChangePassword'
import ModalTurnOn2FA, { MODAlTYPE } from 'components/ModalTurnOn2FA'
import { toast } from 'react-toastify'
import moment from 'moment'
// import CardRef from 'components/CardRef'
import { FormattedMessage } from 'react-intl'

const SECURITY_TYPE = {
  EMAIL: 'email',
  AUTHY: 'authy',
}

const Account = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()
  const { userInfo } = useSelector((state) => state.user)
  const [nameOld, setNameOld] = useState(`${userInfo?.profile?.fistname} ${userInfo?.profile?.lastname}`)
  const [name, setName] = useState(`${userInfo?.profile?.fistname} ${userInfo?.profile?.lastname}`)
  const [allowInputName, setAllowInputName] = useState(false)

  const [email, setEmail] = useState(`${userInfo.email}`)
  const [modalChangePassword, setModalChangePassword] = useState(false)
  const [modal2FA, setModal2FA] = useState({ toggle: false, dataModal: null, type: '' })

  const { historyLogin } = useSelector((state) => state.auth)

  const [paramsHistory, setParamsHistory] = useState({
    page: 1,
    pageSize: 10,
  })

  useEffect(() => {
    dispatch(getHistoryLogin(paramsHistory))
  }, [dispatch, paramsHistory])

  const handleTurnOnTypeVerify = (type) => {
    switch (type) {
      case SECURITY_TYPE.EMAIL:
        break
      case SECURITY_TYPE.AUTHY:
        if (userInfo.status_2fa === 0) {
          dispatch(
            create2FA(
              (receipt) => {
                setModal2FA({ toggle: true, dataModal: receipt, type: MODAlTYPE.VERIFY })
              },
              (e) => {
                toast.error(e?.message || 'Has an error, Please try again late.')
              },
            ),
          )
        } else {
          setModal2FA({ toggle: true, dataModal: {}, type: MODAlTYPE.UPDATE })
        }
        break
      default:
        break
    }
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  const handleChangePassword = () => {
    setModalChangePassword(true)
    dispatch(
      requestForgotPassword(
        {
          email: userInfo.email,
        },
        () => {},
      ),
    )
  }

  const [avatar, setAvatar] = useState('')

  const onSubmitChangeName = () => {
    if (nameOld !== name) {
      dispatch(
        updateProfile(
          {
            fistname: name.split(' ').slice(0, -1).join(' '),
            lastname: name.split(' ').slice(-1).join(' '),
          },
          () => {
            dispatch(getProfile())
            setLoading(false)
            setAllowInputName(false)
            setNameOld(name)
          },
          () => {
            setLoading(false)
            setAllowInputName(false)
          },
        ),
      )
    } else {
      setLoading(false)
      setAllowInputName(false)
    }
  }

  useEffect(() => {
    if (userInfo) {
      setAvatar({
        image: userInfo?.profile?.avatar,
      })
    }
  }, [form, userInfo])

  const handleChangeAvatar = (event) => {
    if (event.target.files && event.target.files[0]) {
      getBase64(event.target.files[0]).then(() => {
        dispatch(
          uploadFile(
            {
              key: 1,
              image: event.target.files[0],
            },
            (receipt) => {
              setAvatar({
                ...receipt,
                image: receipt.full_link,
              })
            },
          ),
        )
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        title: 'Date',
        dataIndex: 'last_login',
        sorter: (a, b) => a.last_login - b.last_login,
        defaultSortOrder: (a, b) => a.last_login - b.last_login,
        render: (text) => moment(text * 1000).format('YYYY/MM/DD'),
        showSorterTooltip: false,
      },
      {
        title: 'IP Address',
        dataIndex: 'ip',
      },
      {
        title: 'Device',
        dataIndex: 'os',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: () => (
          <p className="action-logout" role="presentation" onClick={handleLogout}>
            Logout
          </p>
        ),
      },
    ],
    [],
  )

  return (
    <>
      <div className="wrapper-account">
        <div className="account-banner">
          <Form form={form}>
            <div className="box-information">
              <div className="avatar">
                <label htmlFor="change_avatar">
                  <img src={avatar?.image ? `${avatar.image}` : '/images/user-default.png'} alt="" />
                  <input
                    id="change_avatar"
                    accept="image/*"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      handleChangeAvatar(e)
                    }}
                  />
                </label>
              </div>
              <div className="name">
                <p>
                  {allowInputName ? <input autoFocus value={name} onChange={(e) => setName(e.target.value)} /> : name}

                  {allowInputName ? (
                    <TickIcon onClick={() => onSubmitChangeName()} />
                  ) : (
                    <EditIcon onClick={() => setAllowInputName((prev) => !prev)} />
                  )}
                </p>
                <p>{email}</p>
              </div>
            </div>
          </Form>

          <div className="account-action">
            <button className="btn-google-auth" type="button" onClick={() => handleTurnOnTypeVerify(SECURITY_TYPE.AUTHY)}>
              <FormattedMessage id="Google Authenticator" />
            </button>

            <button className="btn-change-password" type="button" onClick={handleChangePassword}>
              <FormattedMessage id="Change Password" />
            </button>
            <ButtonGradient
              className="btn-log-out"
              gradient="linear-gradient(to right, #00540E, #27980B, #00580E)"
              background="#003c2d"
              radius="30px"
              onClick={() => handleLogout()}
            >
              <FormattedMessage id="Log Out" />
            </ButtonGradient>
          </div>
        </div>

        <p className="title-table-recent">
          <FormattedMessage id="Account activity" />
        </p>

        {window.innerWidth > 991 ? (
          <TableRecentTransaction columns={columns} dataSource={historyLogin?.rows} pagination={false} />
        ) : (
          <ItemAccountActivity data={historyLogin?.rows} />
        )}
      </div>

      <ChangePassword email={userInfo.email} visible={modalChangePassword} close={setModalChangePassword} />

      <ModalTurnOn2FA
        visible={modal2FA.toggle}
        dataModal={modal2FA.dataModal}
        modalType={modal2FA.type}
        onCancel={() => setModal2FA({ toggle: false, dataModal: null })}
      />
    </>
  )
}

export default Account
