import { AccountIcon, SecurityIcon, WalletIcon, StakeIcon } from 'widgets/Svg'
import { DOMAIN } from 'constants/index'

export const linksPublic = [
  {
    label: 'Home',
    href: '/',
    target: '',
  },
  {
    label: 'Service',
    href: '/service',
    target: '',
  },
  {
    label: 'About Us',
    href: '',
    target: '_blank',
  },
  {
    label: 'Contact Us',
    href: '',
    target: '_blank',
  },
  {
    label: 'Stake',
    href: '/stake',
    target: '_blank',
  },
]

export const linksPrivate = [
  {
    label: 'Home',
    href: '/',
    target: '',
    icon: '/images/menu/home.png',
  },
  {
    label: 'Profile',
    href: '/profile',
    target: '',
    icon: '/images/menu/user.png',
  },
  {
    label: 'Wallet',
    href: '/wallet',
    target: '',
    icon: '/images/menu/wallet.png',
  },

  {
    label: 'Affiliate Program',
    href: '/affiliate-program',
    target: '',
    icon: '/images/icons/AffiliateProgram.png',
  },
  {
    label: 'Whitepaper',
    href: 'https://url.lotcedefi.io/white-paper',
    target: '_blank',
    icon: '/images/menu/Whitepaper.png',
  },
]

export const menuGame = [
  {
    label: 'JackPot',
    href: '/buy-ticket',
    target: '',
    icon: '/images/icons/JackPot.png',
  },
  {
    label: 'Mini Game',
    href: '',
    target: '',
    icon: '/images/icons/MiniGame.png',
    tag: 'Soon',
  },
  {
    label: 'Event',
    href: '',
    target: '',
    icon: '/images/icons/Event.png',
    tag: 'Soon',
  },
  {
    label: 'Promotions',
    href: '',
    target: '',
    icon: '/images/icons/Promotions.png',
    tag: 'Soon',
  },
]

export const linksNavigatorMobile = [
  {
    label: 'Security',
    href: '/security',
    target: '',
    icon: SecurityIcon,
  },
  {
    label: 'Stake',
    href: '/stake',
    target: '',
    icon: StakeIcon,
  },
  {
    label: 'Wallet',
    href: '/wallet',
    target: '',
    icon: WalletIcon,
  },
  {
    label: 'Account',
    href: '/account',
    target: '',
    icon: AccountIcon,
  },
]
