import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { isNumber } from 'lodash'
import './styles.scss'
import { DECIMALS } from 'constants/index'
import {
  createWallet,
  fetchWalletBalance,
  getCurrencyAttr,
  getWalletAddress,
  resendVerifyWithdrawCode,
  withdraw,
} from 'state/wallets/actions'
import BackLink from 'components/BackLink'
import Amount from './component/amount'
import ButtonSubmit from 'widgets/ButtonSubmit'
import ControlInputSelect from 'components/ControlInputSelect'
import RecentTransaction from 'components/History/recentTransaction'
import ModalVerificationCode from 'components/ModalVerificationCode'
import { getTransactionList } from 'state/transactions/actions'
import Address from './component/address'
import { Row } from 'antd'
import ControlInputText from 'components/ControlInputText'
import { FormattedMessage } from 'react-intl'

const Withdraw = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { userInfo } = useSelector((state) => state.user)
  const { chainList, currency, currencyAttr, walletsAddress } = useSelector((state) => state.wallets)
  const { rows, total } = useSelector((state) => state.transactions.transactionsList)
  const [modalVerifyCode, setModalVerifyCode] = useState({ toggle: false, dataModal: null })
  const [loadingWithdraw, setLoadingWithdraw] = useState(false)
  const [errorMess, setErrorMess] = useState('')

  const [amount, setAmount] = useState('')
  const [selectCurrency, setSelectCurrency] = useState()
  const [selectChain, setSelectChain] = useState()
  const [userTokenAddress, setUserTokenAddress] = useState()
  const [walletAddressSelect, setWalletAddressSelect] = useState()

  const optionCurrency = useMemo(() => {
    if (currency) {
      return currency.map((item) => ({
        id: item._id,
        label: item.title,
        value: item.code,
        type: item.type,
        currency_code: item.code,
        image: item.icon,
      }))
    }
    return undefined
  }, [currency])

  const optionChain = useMemo(() => {
    if (chainList && currencyAttr?.length > 0) {
      return currencyAttr.map((currAttr) => {
        const fChain = chainList.find((o) => o._id === currAttr.blockchain)
        return {
          _id: fChain._id,
          label: fChain.title,
          value: fChain.chainid,
          chain_code: fChain.code,
          image: fChain.icon,
        }
      })
    }
    return undefined
  }, [chainList, currencyAttr])

  /* Clear all data when change coin */
  useEffect(() => {
    setSelectChain()
    setAmount('')
  }, [selectCurrency])

  /* Set init value of currency  */
  useEffect(() => {
    if (optionCurrency && params?.id) {
      const fCurrency = optionCurrency.find((o) => o.value === params.id)
      setSelectCurrency(JSON.stringify(fCurrency))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionCurrency])

  // Wallet currency default select (any TOKEN)
  const baseCurrency = useMemo(() => {
    if (selectCurrency) {
      return currency?.find((item) => item.code === JSON.parse(selectCurrency).currency_code)
    }
    return undefined
  }, [currency, selectCurrency])
  // Chain attribute for get fee of chain
  const chainAttribute = useMemo(() => {
    if (selectChain) {
      return currencyAttr?.find((item) => item.blockchain === JSON.parse(selectChain)._id)
    }
    return undefined
  }, [currencyAttr, selectChain])
  // Wallet default token of chain (BNB or MATIC)
  const walletChain = useMemo(() => {
    if (selectChain) {
      const pChain = JSON.parse(selectChain)
      if (pChain.chain_code === 'BEP20') {
        return currency?.find((item) => item.code === 'BNB')
      }
      if (pChain.chain_code === 'POLYGON') {
        return currency?.find((item) => item.code === 'MATIC')
      }
    }
    return undefined
  }, [currency, selectChain])

  /**
   * Fetch attr for get fee by code of currency
   */
  useEffect(() => {
    if (baseCurrency?._id) {
      dispatch(
        getCurrencyAttr({
          currency_id: baseCurrency._id,
        }),
      )
    }
  }, [baseCurrency, dispatch])
  useEffect(() => {
    if (walletsAddress && selectCurrency && selectChain) {
      const pCurrency = JSON.parse(selectCurrency)
      const pChain = JSON.parse(selectChain)

      const fWallet = walletsAddress.find((item) => {
        if (item.currency === pCurrency.currency_code && item.chain === pChain.chain_code) {
          return true
        }
        return false
      })

      if (fWallet) {
        setWalletAddressSelect(fWallet)
      } else {
        /**
         * Create new wallet if wallet is not available
         * Select wallet will be rerender and checked
         * After create new wallet this will make actions in the saga for fetch all new wallet (Search keyword: {yield put(actions.getWalletAddress})
         */
        dispatch(
          createWallet(
            {
              currency_code: pCurrency.currency_code,
              chain_code: pChain.chain_code,
              type: 'CRYPTO',
            },
            () => {
              dispatch(getWalletAddress({ page: 1, pageSize: 100 }))
            },
            (error) => {
              if (error.code === 'err_not_found') {
                setWalletAddressSelect(undefined)
              }
            },
          ),
        )
      }
    }
  }, [dispatch, walletsAddress, selectCurrency, selectChain])

  /* Withdraw */
  const handleWithdraw = () => {
    if (!baseCurrency) return setErrorMess(<FormattedMessage id="An occurred error, Please try again" />)
    if (!selectChain) return setErrorMess(<FormattedMessage id="Please select network" />)
    if (!selectCurrency) return setErrorMess(<FormattedMessage id="Please select currency" />)
    if (!userTokenAddress) return setErrorMess(<FormattedMessage id="Please enter receiver address" />)
    if (!walletAddressSelect || !chainAttribute) {
      return setErrorMess(`${JSON.parse(selectChain).label} is not support ${JSON.parse(selectCurrency).label}.`)
    }

    if (!isNumber(+amount)) return setErrorMess(<FormattedMessage id="Please enter amount" />)
    if (!userInfo?.kycInfo) {
      if (baseCurrency.usd_rate * amount > 10000) {
        return setErrorMess(
          <FormattedMessage id="Please complete KYC to increase your daily withdrawal limits to up to $100 000" />,
        )
      }
    }
    if (baseCurrency.usd_rate * amount > 1000000) {
      return setErrorMess(<FormattedMessage id="Maximum amount (per transaction) $1000000" />)
    }

    const parseAmount = amount * 10 ** DECIMALS
    if (parseAmount < baseCurrency.min_withdraw) {
      return setErrorMess(`Minimum amount (per transaction) ${baseCurrency.min_withdraw / 10 ** DECIMALS}`)
    }
    if (parseAmount > baseCurrency.balance) return setErrorMess(<FormattedMessage id="Balance is not enough" />)

    const feeChain = chainAttribute.withdraw_fee_chain
    const feeToken = (chainAttribute.withdraw_fee_token * parseAmount) / 100
    if (['BNB', 'MATIC'].includes(JSON.parse(selectCurrency).currency_code)) {
      if (feeChain + feeToken > baseCurrency.balance)
        return setErrorMess(<FormattedMessage id="Balance is not enough for pay fee" />)
    } else if (walletChain && walletChain.balance < feeChain) {
      return setErrorMess(`${walletChain.title} is not enough for pay fee`)
    }

    setErrorMess('')
    setLoadingWithdraw(false)
    dispatch(
      withdraw(
        {
          amount: parseAmount,
          address: userTokenAddress,
          chain_code: JSON.parse(selectChain).chain_code,
          currency_code: JSON.parse(selectCurrency).currency_code,
        },
        (receipt) => {
          setModalVerifyCode({ toggle: true, dataModal: { _id: receipt.TransactionCode } })
        },
        (error) => {
          if (error.code === 'err_data_existed') {
            const txHistory = rows.find((item) => item.status === 'CREATED')
            if (txHistory) {
              dispatch(
                resendVerifyWithdrawCode({
                  transaction_id: txHistory._id,
                }),
              )
              setModalVerifyCode({ toggle: true, dataModal: txHistory })
              return
            }
          }
          return null
        },
      ),
    )
  }

  /**
   * Fetch txh history
   */
  const [txParams, setTxParams] = useState({
    page: 1,
    pageSize: 10,
    currency: undefined,
    type: 'WITHDRAW',
  })
  useEffect(() => {
    if (selectCurrency) {
      setTxParams((prev) => ({
        ...prev,
        currency: JSON.parse(selectCurrency).currency_code,
      }))
    }
  }, [selectCurrency])
  useEffect(() => {
    if (txParams.currency) {
      dispatch(getTransactionList(txParams))
    }
  }, [dispatch, txParams])

  /**
   * Re verify code for withdraw and resend code
   */
  const handleReVerifyWithdraw = useCallback(
    (item) => {
      dispatch(
        resendVerifyWithdrawCode({
          transaction_id: item._id,
        }),
      )
      setModalVerifyCode({ toggle: true, dataModal: item })
    },
    [dispatch],
  )

  /**
   * Verify code success withdraw
   */
  const handleFinishWithdraw = useCallback(() => {
    fetchWalletBalance([{ code: JSON.parse(selectCurrency).currency_code }])
    dispatch(getTransactionList(txParams))
  }, [dispatch, selectCurrency, txParams])

  return (
    <>
      <div className="warp-page-withdraw">
        <div className="page-withdraw-content">
          {window.innerWidth >= 768 && <BackLink label="withdraw" to="/wallet" />}

          <div className="withdraw-content-form">
            <div className="box-input-select">
              {optionCurrency && selectCurrency && (
                <ControlInputSelect
                  title={<FormattedMessage id="Select currency" />}
                  dataSelect={optionCurrency}
                  value={selectCurrency}
                  onChange={(curr) => {
                    history.replace(`/wallet/withdraw/${JSON.parse(curr).currency_code}`)
                    setSelectCurrency(curr)
                  }}
                />
              )}
              {optionChain && (
                <ControlInputSelect
                  title={<FormattedMessage id="Select Chain" />}
                  dataSelect={optionChain}
                  placeholder="Select network"
                  value={selectChain || undefined}
                  onChange={setSelectChain}
                />
              )}
            </div>

            {selectChain && (
              <>
                <div className="box-input-text">
                  <Address
                    label={<FormattedMessage id="Address" />}
                    placeholder="Please enter the address below to withdraw"
                    value={userTokenAddress}
                    onChange={setUserTokenAddress}
                  />
                </div>
                {walletAddressSelect?.addressTag && (
                  <div className="memo-section-wrapper">
                    <Row gutter={[16, 16]} className="memo-section-content">
                      <ControlInputText label="MEMO" placeholder="Optional" />
                      <div className="note-warning-meno">
                        <span>
                          <img className="img-warning" src="/images/icons/warring.png" alt="" />
                        </span>
                        <p>
                          <FormattedMessage id="Most exchange requires MEMO for your XRP to be correctly credited. Please ensure that you have inputted the correct MEMO for your withdrawal." />
                        </p>
                      </div>
                    </Row>
                  </div>
                )}
                <div className="box-attention">
                  <ul>
                    <li>
                      <p>
                        <FormattedMessage id="Enter the correct address to withdraw" />
                      </p>
                    </li>
                    <li>
                      <p>
                        <FormattedMessage id="Ensure the network is" />{' '}
                        {selectChain && <span>{JSON.parse(selectChain).chain_code}</span>}
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="box-expected">
                  <div className="item-expected">
                    <p>
                      <FormattedMessage id="Expected arrival" />
                    </p>
                    <p>
                      <FormattedMessage id="15 network confirmations" />
                    </p>
                  </div>

                  <div className="item-expected">
                    <p>
                      <FormattedMessage id="Expected unlock" />
                    </p>
                    <p>
                      <FormattedMessage id="15 network confirmations" />
                    </p>
                  </div>
                </div>

                <Amount
                  type="number"
                  value={amount}
                  baseCurrency={baseCurrency}
                  userInfo={userInfo}
                  chainAttribute={chainAttribute}
                  onChange={setAmount}
                />
                <div className="box-discamler">
                  <div>
                    <img src="/images/icons/warring.png" alt="" />
                  </div>
                  <div>
                    <p>
                      <FormattedMessage id="Disclaimer" />
                    </p>
                    <p>
                      <FormattedMessage id="The network you have selected is BSC, please make sure your withdrawal address supports the BNB Smart Chain network. In case the other platform does not support this network, your assets may be lost. If you are unsure if the receiving platform supports this network, you can click the button below to verify it yourself." />
                    </p>
                  </div>
                </div>

                <div className="withdraw-actions text-center">
                  {errorMess && <p className="error-mess">{errorMess}</p>}
                  <ButtonSubmit style={{ maxWidth: '100%', width: '100%' }} loading={loadingWithdraw} onClick={handleWithdraw}>
                    <FormattedMessage id="WITHDRAW" />
                  </ButtonSubmit>
                </div>
              </>
            )}
            <RecentTransaction
              txParams={txParams}
              rows={rows}
              total={total}
              setTxParams={setTxParams}
              setModalVerifyCode={handleReVerifyWithdraw}
            />
          </div>
        </div>
      </div>

      <ModalVerificationCode
        visible={modalVerifyCode.toggle}
        dataModal={modalVerifyCode.dataModal}
        onCancel={setModalVerifyCode}
        onFinish={handleFinishWithdraw}
      />
    </>
  )
}

export default Withdraw
