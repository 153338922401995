import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'
import InputCode from 'components/InputCode'
import ModalCustom from 'components/BaseModal'
import { verifyWithdraw } from 'state/wallets/actions'
import { toast } from 'react-toastify'
import ResendCode from 'components/ResendCode'
import { FormattedMessage, injectIntl } from 'react-intl'

const initialField = 6

const ModalVerificationCode = ({ intl, visible, dataModal, onFinish, onCancel }) => {
  const dispatch = useDispatch()
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [emailCode, setEmailCode] = useState('')
  const [gg2faCode, setGg2faCode] = useState('')
  const [errorMess, setErrorMess] = useState(false)

  const handleNext = () => {
    if (!dataModal) return toast.error(intl.formatMessage({ id: 'An occurred error, Please try again late.' }))
    if (step === 1 && emailCode.length < 6)
      toast.error(
        <>
          {intl.formatMessage({ id: 'Please enter' })} {initialField}{' '}
          {intl.formatMessage({ id: 'characters from email of you.' })}
        </>,
      )
    if (step === 2 && gg2faCode.length < 6)
      toast.error(
        <>
          {intl.formatMessage({ id: 'Please enter' })} {initialField}{' '}
          {intl.formatMessage({ id: 'characters from Google Authenticator app on mobile of you.' })}
        </>,
      )

    setErrorMess(false)
    switch (step) {
      case 1:
        if (emailCode?.length < initialField) return setErrorMess(true)
        setStep(2)
        break
      case 2:
        if (gg2faCode?.length < initialField) return setErrorMess(true)
        setLoading(true)
        dispatch(
          verifyWithdraw(
            {
              TransactionCode: dataModal._id,
              email_code: emailCode,
              auth_code: gg2faCode,
            },
            (receipt) => {
              onFinish(receipt)
              setLoading(false)
              onCancel({ toggle: false, dataModal: null })
              setEmailCode('')
              setGg2faCode('')
            },
            () => {
              setLoading(false)
            },
          ),
        )
        break
      default:
        break
    }
  }

  return (
    <>
      {window.innerWidth >= 768 ? (
        <ModalCustom
          className="modal-verification-code"
          title={
            step === 1 ? (
              <div className="modal-title-wd">
                <FormattedMessage id="Verification Code" />
              </div>
            ) : (
              <div className="modal-title-wd">
                <FormattedMessage id="Google Authenticator" />
              </div>
            )
          }
          centered
          visible={Boolean(visible)}
          width={window.innerWidth >= 768 ? 592 : '100vw'}
          onCancel={() => {
            onCancel({ toggle: false, dataModal: null })
            setEmailCode('')
            setGg2faCode('')
          }}
        >
          {/* <p className="title-modal">{step === 1 ? 'Verification Code' : 'Google Authenticator'}</p> */}

          <div className="list-steps-verify">
            <ul>
              <li className={step === 1 ? 'current' : 'active'}>
                <p>
                  <FormattedMessage id="Verify code" />
                </p>
              </li>
              <li className={step === 2 ? 'current' : ''}>
                <p>
                  <FormattedMessage id="GG2FA" />
                </p>
              </li>
            </ul>
          </div>

          <p className="modal-verification-code-note">
            {step === 1 ? (
              <FormattedMessage id="Please check your email for OTP" />
            ) : (
              <FormattedMessage id="Please enter 6 characters in the Authenticator app to verify" />
            )}
          </p>

          {step === 1 ? (
            <InputCode initialField={initialField} hasErrored={!!errorMess} value={emailCode} onChange={setEmailCode} />
          ) : (
            <InputCode initialField={initialField} hasErrored={!!errorMess} value={gg2faCode} onChange={setGg2faCode} />
          )}

          <ResendCode className="resend-code" style={{ visibility: '' }} data={dataModal} />

          <div className="modal-scan-action">
            {step === 2 ? (
              <ButtonSecondary
                style={{ maxWidth: '150px', marginRight: '24px' }}
                onClick={() => {
                  if (step === 1) {
                    onCancel({ toggle: false, dataModal: null })
                    setEmailCode('')
                    setGg2faCode('')
                  } else {
                    setStep(1)
                  }
                }}
              >
                <FormattedMessage id="BACK" />
              </ButtonSecondary>
            ) : (
              ''
            )}
            <ButtonPrimary style={{ maxWidth: '150px' }} loading={loading} onClick={handleNext}>
              <FormattedMessage id="Next" />
            </ButtonPrimary>
          </div>

          {/* <div className="modal-scan-action-bg" /> */}
        </ModalCustom>
      ) : (
        <ModalCustom
          className="modal-verification-code"
          title={
            step === 1 ? (
              <div className="modal-title-wd">
                <FormattedMessage id="Verification Code" />
              </div>
            ) : (
              <div className="modal-title-wd">
                <FormattedMessage id="Google Authenticator" />
              </div>
            )
          }
          visible={Boolean(visible)}
          width={window.innerWidth >= 768 ? 592 : '100vw'}
          onCancel={() => {
            onCancel({ toggle: false, dataModal: null })
            setEmailCode('')
            setGg2faCode('')
          }}
        >
          {/* <p className="title-modal">{step === 1 ? 'Verification Code' : 'Google Authenticator'}</p> */}

          <div className="list-steps-verify">
            <ul>
              <li className={step === 1 ? 'current' : 'active'}>
                <p>
                  <FormattedMessage id="Verify code" />
                </p>
              </li>
              <li className={step === 2 ? 'current' : ''}>
                <p>
                  <FormattedMessage id="GG2FA" />
                </p>
              </li>
            </ul>
          </div>

          <p className="modal-verification-code-note">
            {step === 1 ? (
              <FormattedMessage id="Please check your email for OTP" />
            ) : (
              <FormattedMessage id="handleNextPlease enter 6 characters in the Authenticator app to verify" />
            )}
          </p>

          {step === 1 ? (
            <InputCode initialField={initialField} hasErrored={!!errorMess} value={emailCode} onChange={setEmailCode} />
          ) : (
            <InputCode initialField={initialField} hasErrored={!!errorMess} value={gg2faCode} onChange={setGg2faCode} />
          )}

          <ResendCode className="resend-code" style={{ visibility: '' }} data={dataModal} />

          <div className="modal-scan-action">
            {step === 2 ? (
              <ButtonSecondary
                style={{ maxWidth: '150px', marginRight: '24px' }}
                onClick={() => {
                  if (step === 1) {
                    onCancel({ toggle: false, dataModal: null })
                    setEmailCode('')
                    setGg2faCode('')
                  } else {
                    setStep(1)
                  }
                }}
              >
                <FormattedMessage id="BACK" />
              </ButtonSecondary>
            ) : (
              ''
            )}
            <ButtonPrimary style={{ maxWidth: '150px' }} loading={loading} onClick={handleNext}>
              <FormattedMessage id="Next" />
            </ButtonPrimary>
          </div>

          {/* <div className="modal-scan-action-bg" /> */}
        </ModalCustom>
      )}
    </>
  )
}

export default injectIntl(ModalVerificationCode)
