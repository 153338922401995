import React, { useEffect, useMemo, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import './styles.scss'
import { getBscScanLink } from 'utils'
import ModalCustom from 'components/BaseModal'
import { DECIMALS } from 'constants/index'
import { formatDate, roundNumber } from 'helpers/CommonHelper'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const ModalViewDetails = ({ visible, dataModal, close }) => {
  const history = useHistory()

  if (!dataModal) return <></>
  const pathName = history.location.pathname.split('/')
  const pathNameTitle = () => {
    if (pathName.includes('deposit')) {
      return (
        <>
          <FormattedMessage id="Deposit Details" />
        </>
      )
    }
    if (pathName.includes('withdraw')) {
      return (
        <>
          <FormattedMessage id="Withdraw Details" />
        </>
      )
    }
    if (pathName.includes('history')) {
      return (
        <>
          <FormattedMessage id="Transaction Details" />
        </>
      )
    }
  }
  return (
    <ModalCustom
      className="modal-history-details"
      title={<div className="history-detail-heading">{pathNameTitle()}</div>}
      centered
      visible={Boolean(visible)}
      width={window.innerWidth <= 768 ? '100vw' : 580}
      height={window.innerWidth <= 768 ? '100%' : 550}
      onCancel={() => close(false)}
    >
      <div className="modal-scan-action-bg" />

      <ul className="modal-history-details-steps">
        <li>
          <p>
            <FormattedMessage id="Withdraw order submitted" />
          </p>
          <p>{formatDate(dataModal.createdAt * 1000)}</p>
        </li>
        <li>
          <p>
            <FormattedMessage id="System processing" />
          </p>
          <p>{formatDate(dataModal.createdAt * 1000)}</p>
        </li>
        <li>
          <p>{dataModal.status}</p>
          <p>{formatDate(dataModal.createdAt * 1000)}</p>
        </li>
      </ul>

      <div className="modal-history-details-note">
        <p>
          <FormattedMessage id="Please note that you will receive a email once it is complete." />
        </p>
        <p>
          <FormattedMessage id="Why hasn’t my history arrived?" />
        </p>
      </div>

      <div className="modal-history-details-info">
        <div className="modal-history-details-info-item">
          <p>
            <FormattedMessage id="Time" />
          </p>
          <p>{formatDate(dataModal.createdAt * 1000)}</p>
        </div>

        {/* <div className="modal-history-details-info-item">
          <p>Address</p>
          <p>Address</p>
        </div> */}

        <div className="modal-history-details-info-item">
          <p>
            <FormattedMessage id="Network" />
          </p>
          <p>{dataModal.chain}</p>
        </div>

        <div className="modal-history-details-info-item">
          <p>
            <FormattedMessage id="Currency" />
          </p>
          <p>{dataModal.currency}</p>
        </div>

        <div className="modal-history-details-info-item">
          <p>
            <FormattedMessage id="Fee" />
          </p>
          <p>{dataModal.fee / 10 ** DECIMALS}</p>
        </div>
        <div className="modal-history-details-info-item">
          <p>
            <FormattedMessage id="Amount" />
          </p>
          <p>
            <CurrencyFormat
              value={roundNumber(dataModal.amount / 10 ** DECIMALS, 6)}
              displayType="text"
              thousandSeparator
              renderText={(v) => v}
            />
          </p>
        </div>
        <div className="modal-history-details-info-item">
          <p>
            <FormattedMessage id="Status" />
          </p>
          <p data-status={dataModal.status}>
            <span>{dataModal.status}</span>
          </p>
        </div>
        {/* <div className="modal-history-details-info-item">
          <p>TxID</p>
          <p>
            {dataModal.status === 'COMPLETED' ? (
              <a
                className="recent-transaction-table-amount"
                href={getBscScanLink(dataModal.txhash, 'transaction', dataModal.chain)}
                target="_blank"
                rel="noreferrer"
              >
                {formatCode(dataModal.txhash, 5, 5)}
              </a>
            ) : (
              dataModal.status
            )}
          </p>
        </div> */}
      </div>
    </ModalCustom>
  )
}

export default ModalViewDetails
