import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 9 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M-2.62268e-07 6L9 0.803846L9 11.1962L-2.62268e-07 6Z" fill="#51FB2D" />
  </Svg>
)

export default Icon
