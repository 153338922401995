import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { WalletIcon } from 'widgets/Svg'
import { Link } from 'react-router-dom'
import './style.scss'
import { roundNumber } from 'helpers/CommonHelper'
import { FormattedMessage } from 'react-intl'

const TotalBalance = ({ baseToken, ...props }) => {
  const { currency } = useSelector((state) => state.wallets)
  const [show, setShow] = useState(false)
  const totalBalance = useMemo(() => {
    if (currency) {
      return currency.reduce((total, current) => total + current.balance * current.usd_rate, 0)
    }
    return undefined
  }, [currency])
  return (
    // <div className="total-balance" {...props}>
    //   <div onClick={() => setShow((prev) => !prev)} role="presentation">
    //     <span>
    //       {show && isValid(totalBalance) ? (
    //         <>
    //           <CurrencyFormat
    //             value={roundNumber(totalBalance / 10 ** DECIMALS)}
    //             displayType="text"
    //             thousandSeparator
    //             prefix={`$ `}
    //           />
    //         </>
    //       ) : (
    //         <span style={{ marginTop: '7px', display: 'block' }}>********</span>
    //       )}
    //     </span>
    //     {show ? <EyeIcon /> : <EyeSleepIcon />}
    //   </div>
    // </div>

    <div className="total-balance">
      <div className="total-balance-info">
        <p>
          <FormattedMessage id="Balance" />
        </p>
        <span>{`${roundNumber(totalBalance / 10 ** 18)} USD`}</span>

        {/* <Select defaultValue="1" style={{ width: 136 }}>
          <Option value="1">{`${roundNumber(totalBalance / 10 ** 18)} USD`}</Option>
        </Select> */}
      </div>
      <div className="total-balance-btn">
        <Link to="/wallet">
          <button type="button">
            <WalletIcon />
            WALLET
          </button>
        </Link>
      </div>
    </div>
  )
}

export default TotalBalance
