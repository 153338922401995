import './styles.scss'
import React from 'react'
import Form from './component/form'

const History = () => (
  <div className="warp-page-history">
    <Form />
  </div>
)

export default History
