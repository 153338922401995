import React, { useState } from 'react'
import './styles.scss'
import ModalScan from 'components/ModalScan'
import { FormattedMessage } from 'react-intl'

const ShareLink = ({ text, notica, ...props }) => {
  const [showNoti, setShowNoti] = useState(false)
  const [open, setOpen] = useState(false)

  return (
    <div className="box-show-address-text" {...props}>
      <div>
        <p>{text ?? '--'}</p>
      </div>
      <div>
        <img
          role="presentation"
          onClick={() => {
            if (navigator.clipboard) {
              navigator.clipboard.writeText(text)
              setShowNoti(true)
              setTimeout(() => {
                setShowNoti(false)
              }, 1000)

              if (showNoti) setShowNoti(false)
            }
          }}
          src="/images/icons/icon-copy.png"
          alt=""
        />
        <img src="/images/icons/icon-qr.png" role="presentation" onClick={() => setOpen(true)} alt="" />
        {showNoti && <div className="tooltip">{notica || 'Copied'}</div>}
      </div>

      <ModalScan value={text ?? '--'} visible={open} setOpen={setOpen} title={<FormattedMessage id="Scan to Deposit" />} />
    </div>
  )
}

export default ShareLink
