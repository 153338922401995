import React from 'react'
import { formatDate } from 'helpers/CommonHelper'
import Nodata from 'components/Nodata'
import TableActive from './TableActive'
import ItemActive from './itemActive'
import { FormattedMessage } from 'react-intl'

const columns = [
  {
    title: (
      <div style={{ textAlign: 'left' }}>
        <FormattedMessage id="Date" />
      </div>
    ),
    dataIndex: 'last_login',
    render: (text) => formatDate(text * 1000),
  },
  {
    title: (
      <div style={{ textAlign: 'left' }}>
        <FormattedMessage id="IP Address" />
      </div>
    ),
    dataIndex: 'ip',
  },
  {
    title: (
      <div style={{ textAlign: 'left' }}>
        <FormattedMessage id="Location" />
      </div>
    ),
    dataIndex: 'index',
    render: () => <FormattedMessage id="Last Login" />,
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <FormattedMessage id="Device" />
      </div>
    ),
    dataIndex: 'os',
  },
  // {
  //   title: <div>Actions</div>,
  //   render: () => (
  //     <a className="actions" style={{ color: '#00F4A3', fontSize: window.innerWidth >= 768 ? '16px' : '14px' }}>
  //       Log out
  //     </a>
  //   ),
  // },
]

export const HistoryTable = ({ historyLogin }) => {
  if (!historyLogin) return <></>
  return (
    <>
      {window.innerWidth <= 991 ? (
        <div className="history-table" style={{ height: '100px' }}>
          {historyLogin.rows?.length > 0 ? (
            historyLogin.rows?.slice(0, 8).map((item) => <ItemActive data={item} key={item.id} />)
          ) : (
            <Nodata />
          )}
        </div>
      ) : (
        <TableActive dataSource={historyLogin.rows} scroll={{ y: 544 }} columns={columns} rowKey={(record) => record._id} />
      )}
    </>
  )
}
