/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import ProfileAccount from 'components/ProfileAccount'
import React from 'react'
import { Link } from 'react-router-dom'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'
import './styles.scss'
import { FormattedMessage } from 'react-intl'

const HeaderDesktop = ({ links, location, isLogin }) => (
  <header className="header-container-desktop">
    <div className="header-desktop">
      <div className="header-left">
        <Link to="/">
          <div className="header-logo">
            <img src="/images/logo-text.png" title="Lottery CeDeFi wallets" alt="Lottery CeDeFi wallets" />
          </div>
        </Link>
      </div>
      <ul className="header-link">
        {links.map((item, index) => {
          const isHttp = item?.href?.startsWith('http')
          const Tag = isHttp ? 'a' : Link
          const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
          return (
            <li key={`menu-${index}`} className={location.pathname === item.href ? 'active' : ''}>
              <Tag {...propsLink} rel="noreferrer">
                {item.label}
              </Tag>
            </li>
          )
        })}
      </ul>

      <div className="header-right">
        {isLogin ? (
          <ProfileAccount />
        ) : (
          <>
            <Link to="/login" className="actions" style={{ marginRight: '20px' }}>
              <ButtonPrimary
                style={{
                  minWidth: '120px',
                }}
              >
                <FormattedMessage id="Sign in" />
              </ButtonPrimary>
            </Link>
            <Link to="/register" className="actions">
              <ButtonSecondary
                style={{
                  minWidth: '120px',
                }}
              >
                <FormattedMessage id="Sign up" />
              </ButtonSecondary>
            </Link>
          </>
        )}
      </div>
    </div>
  </header>
)

export default HeaderDesktop
