import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 9 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9 6L4.64275e-07 11.1962L9.18537e-07 0.803847L9 6Z" fill="#41FB21" />
  </Svg>
)

export default Icon
