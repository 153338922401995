import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 18 16" {...props}>
    <path d="M8.88867 4.44434V8.88887H13.3332V7.11106H10.6665V4.44434H8.88867Z" fill="white" />
    <path
      d="M17.1488 4.886C16.7478 3.93491 16.1657 3.07086 15.435 2.34195C14.3288 1.23576 12.9227 0.477999 11.3905 0.162346C10.327 -0.0541155 9.23076 -0.0541155 8.16728 0.162346C6.63362 0.475983 5.2263 1.23436 4.12097 2.34283C3.39197 3.07253 2.81013 3.93563 2.40716 4.88511C1.99036 5.87056 1.77632 6.92987 1.77781 7.99984L1.7787 8.02206H0L2.66672 11.5555L5.33344 8.02206H3.55652L3.55563 7.99984C3.55307 6.76014 3.92316 5.5483 4.61787 4.52155C5.06599 3.85903 5.63651 3.2882 6.29879 2.83973C6.97253 2.38586 7.72826 2.06763 8.52373 1.90283C10.1402 1.57047 11.8226 1.89361 13.2009 2.80122C14.5792 3.70883 15.5408 5.12662 15.8741 6.74292C16.0418 7.57128 16.0418 8.42484 15.8741 9.2532C15.7115 10.0493 15.3931 10.8055 14.9372 11.4781C14.715 11.8079 14.4598 12.1181 14.1781 12.399C13.6102 12.9662 12.9388 13.4191 12.2002 13.7333C11.8241 13.8923 11.4331 14.0138 11.0331 14.096C10.205 14.2635 9.3518 14.2635 8.52373 14.096C7.72842 13.9329 6.973 13.6149 6.30057 13.1599C5.96986 12.9365 5.66119 12.682 5.37877 12.3999L4.12186 13.6568C4.86421 14.4002 5.74592 14.9898 6.71649 15.3919C7.68706 15.794 8.72742 16.0006 9.77797 16C10.8474 15.9996 11.906 15.7859 12.8918 15.3715C14.3208 14.7669 15.5435 13.7602 16.411 12.4737C17.3039 11.1527 17.7801 9.59429 17.7781 7.99984C17.7804 6.93008 17.5663 5.87092 17.1488 4.886Z"
      fill="white"
    />
  </Svg>
)

export default Icon
