import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { linksPrivate, linksPublic, menuGame } from 'components/Menu/config'
import './style.scss'
import PrivateHeader from './components/PrivateHeader'
import useIsMobile from 'hooks/useIsMobile'
import PrivateFooter from './components/PrivateFooter'
import { Select } from 'antd'
import { setLanguage } from 'state/app/actions'
import { LanguageIcon } from 'widgets/Svg'
import { FormattedMessage } from 'react-intl'

const { Option } = Select

const PrivateLayout = ({ isLogin, children }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isMobile = useIsMobile()
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => {
    document.querySelector('body').style.overflow = showMenu ? 'unset' : 'hidden'
    setShowMenu((prev) => !prev)
  }

  const panelRef = useRef(null)
  const contentRef = useRef(null)
  const { optionLanguage, language } = useSelector((state) => state.app)

  const handleChangeLang = (lang) => {
    dispatch(setLanguage({ language: lang }))
  }

  useEffect(() => {
    const handleScroll = () => {
      if (
        panelRef.current &&
        window.pageYOffset + window.innerHeight >
          contentRef.current?.clientHeight + (window.innerHeight - panelRef.current?.clientHeight)
      ) {
        panelRef.current.style.position = `absolute`
        panelRef.current.style.top = `${contentRef.current?.clientHeight - panelRef.current?.clientHeight}px`
      } else if (panelRef.current) {
        panelRef.current.style.position = `fixed`
        panelRef.current.style.top = `0`
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <div className="private-layout-container">
        {isMobile && (
          <div className="collapse-icon">
            <input type="checkbox" id="menu" checked={showMenu} onChange={toggleMenu} />
          </div>
        )}
        <div className="panel-container" ref={panelRef}>
          <div className="panel-body">
            <div className="panel-top">
              <Link to="/">
                <div className="panel-logo">
                  <img src="/images/logo-text.png" title="Lottery CeDeFi wallet" alt="Lottery CeDeFi wallet" />
                  <img src="/images/halo-logo-1.png" title="Lottery CeDeFi wallet" alt="Lottery CeDeFi wallet" />
                  <img src="/images/halo-logo-2.png" title="Lottery CeDeFi wallet" alt="Lottery CeDeFi wallet" />
                  <img src="/images/halo-logo-3.png" title="Lottery CeDeFi wallet" alt="Lottery CeDeFi wallet" />
                </div>
              </Link>

              <div className="panel-content">
                <ul className="panel-link">
                  {linksPrivate.map((entry, index) => {
                    const isHttp = entry?.href?.startsWith('http')
                    const Tag = isHttp ? 'a' : Link
                    const propsLink = isHttp ? { href: entry.href, target: entry.target } : { to: entry.href }

                    // Icon
                    const isImage = typeof entry?.icon === 'string'
                    const Icon = isImage ? 'img' : entry?.icon
                    const iconProps = isImage ? { src: entry?.icon, alt: '' } : {}
                    return (
                      <li key={`panel-mb-${index}`} className={location.pathname === entry.href ? 'active' : ''}>
                        <Tag {...propsLink} rel="noreferrer">
                          {entry.icon && (
                            <div>
                              <Icon {...iconProps} />
                            </div>
                          )}
                          <FormattedMessage id={entry.label} />
                        </Tag>
                      </li>
                    )
                  })}
                </ul>

                <ul className="panel-link">
                  <p>Game</p>

                  {menuGame.map((entry) => {
                    // Icon
                    const isImage = typeof entry?.icon === 'string'
                    const Icon = isImage ? 'img' : entry?.icon
                    const iconProps = isImage ? { src: entry?.icon, alt: '' } : {}
                    return (
                      <li key={entry.label} className={location.pathname === entry.href ? 'active' : ''}>
                        <Link to={entry.href}>
                          {entry.icon && (
                            <div>
                              <Icon {...iconProps} />
                            </div>
                          )}
                          <div className="menu-label">
                            <FormattedMessage id={entry.label} />
                            {entry.tag && (
                              <div className="menu-tag">
                                <FormattedMessage id={entry.tag} />
                              </div>
                            )}
                          </div>
                        </Link>
                      </li>
                    )
                  })}

                  <li className="menu-language">
                    <LanguageIcon />
                    <Select
                      defaultValue={language}
                      onChange={handleChangeLang}
                      dropdownClassName="dropdownClassName"
                      dropdownMatchSelectWidth={150}
                    >
                      {optionLanguage.map((item, index) => (
                        <Option value={item.id} key={`language-${index}`}>
                          <div
                            className="image-flag"
                            style={{
                              backgroundImage: `url(${item.image})`,
                            }}
                          />
                          <span className="text-flag">{item.label}</span>
                        </Option>
                      ))}
                    </Select>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="panel-footer">
            <div className="panel-footer" style={{ marginLeft: '30px' }}>
              <img src="/images/clock.png" title="Lottery CeDeFi wallets" alt="Lottery CeDeFi wallets" />
            </div>
            <Link className="" to="https://beurl.app/zodi-telegram-group" target="_blank" rel="noreferrer">
              <ButtonPrimary style={{ maxWidth: '150px' }}>JOIN NOW</ButtonPrimary>
            </Link>
            <Link className="term-privacy" to="/#" target="_blank">
              Terms & Services Privacy Policy
            </Link>
            <div className="version">Version Beta 0.1</div>
          </div> */}
          </div>
        </div>
        <div className="private-content" ref={contentRef}>
          <PrivateHeader links={linksPublic} isMobile={isMobile} isLogin={isLogin} showMenu={showMenu} toggleMenu={toggleMenu} />
          {children}
        </div>
        {/* {isMobile && <NavigationMobile showMenu={showMenu} toggleMenu={toggleMenu} />} */}
      </div>

      <div>
        <PrivateFooter links={linksPublic} location={location} isLogin={isLogin} />
      </div>
    </>
  )
}

export default PrivateLayout
