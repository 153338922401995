import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ButtonSubmit from 'widgets/ButtonSubmit'
import './styles.scss'
import { Form } from 'antd'
import FormItem from 'widgets/FormItem'
import { forgotPassword, requestForgotPassword } from 'state/auth/actions'
import InputCode from 'components/InputCode'
import { useHistory } from 'react-router-dom'
import Input from 'widgets/Input'
import ModalCustom from 'components/BaseModal'
import { Close } from 'widgets/Svg'
import { FormattedMessage, injectIntl } from 'react-intl'

const defaultTimeCountDown = 50

const ChangePassword = ({
  intl,
  visible,
  close,
  email,
  initialField,
  token,
  onFinish,
  onBack,
  children,
  title,
  timeResend,
  setTimeResend,
  ...props
}) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [timeCountDown, setTimeCountDown] = useState(defaultTimeCountDown)

  const onSubmit = () => {
    form.validateFields().then((values) => {
      if (codeValue.length < initialField && typeof codeValue !== 'number') {
        setErrorMess(
          <>
            <FormattedMessage id="Please enter" /> {initialField} <FormattedMessage id="characters from email of you." />
          </>,
        )
        console.log(codeValue.length < initialField && typeof codeValue !== 'number')
      } else {
        setErrorMess('')
        setLoading(true)
        dispatch(
          forgotPassword(
            {
              ...values,
              email: `${email}`,
              code: codeValue,
            },
            () => {
              history.push('/login')
              setLoading(false)
            },
            () => {
              setLoading(false)
            },
          ),
        )
      }
    })
  }
  const onChangeCode = (value) => {
    setCodeValue(value)
  }

  const resendCode = () => {
    dispatch(
      requestForgotPassword(
        {
          email: `${email}`,
        },
        () => {
          if (setTimeResend) {
            setTimeResend(defaultTimeCountDown)
            setTimeCountDown(defaultTimeCountDown)
          } else {
            setTimeCountDown(defaultTimeCountDown)
          }
        },
      ),
    )
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  useEffect(() => {
    if (timeResend && timeResend > 0) {
      setTimeCountDown(timeResend)
    }
  }, [timeResend])
  const CloseModal = () => {
    close(false)
  }
  return (
    <>
      <ModalCustom
        className="form-change-password"
        centered
        visible={Boolean(visible)}
        onCancel={CloseModal}
        width={611}
        {...props}
      >
        <div className="modal-change-password-header">
          <p className="modal-change-password-title">
            <FormattedMessage id="Change Password" />
          </p>
          <Close className="modal-change-password-close" onClick={CloseModal} />
        </div>
        <div className="modal-change-password-content">
          <Form layout="vertical" form={form}>
            <FormItem
              label={
                <span>
                  <FormattedMessage id="New Password" />
                </span>
              }
              name="new_password"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="Please enter new password field" />,
                },
                {
                  min: 10,
                  message: (
                    <FormattedMessage id={`The 'password' field length must be greater than or equal to 10 characters long.`} />
                  ),
                },
              ]}
            >
              <Input placeholder="************" type="password" />
            </FormItem>

            <FormItem
              label={
                <span>
                  <FormattedMessage id="Confirm Password" />
                </span>
              }
              name="confirm"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="Please confirm your password!" />,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(intl.formatMessage({ id: 'The two passwords that you entered do not match!' })),
                    )
                  },
                }),
              ]}
            >
              <Input placeholder="**********" type="password" />
            </FormItem>
            <FormItem
              className="verification-code"
              label={
                <span>
                  <FormattedMessage id="Verification code" />
                </span>
              }
              rules={[
                {
                  required: true,
                  message: (
                    <>
                      <FormattedMessage id="Please enter" /> {initialField}{' '}
                      <FormattedMessage id="characters from email of you." />
                    </>
                  ),
                },
              ]}
            >
              <div className="verify-input">
                <InputCode
                  value={codeValue}
                  initialField={initialField}
                  hasErrored={Boolean(errorMess)}
                  onChange={onChangeCode}
                  type="number"
                />
                <div className="verify-resend" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '14px' }}>
                  <div
                    className="time-count"
                    style={{ opacity: timeCountDown > 0 ? '1' : '0', color: '#fff', fontWeight: '500', textAlign: 'left' }}
                  >
                    <FormattedMessage id="Resend in" /> {timeCountDown}s
                  </div>
                  {timeCountDown <= 0 && (
                    <a
                      onClick={() => resendCode()}
                      role="presentation"
                      style={{ fontWeight: '700', textAlign: 'right', color: '#00F4A3' }}
                    >
                      <FormattedMessage id="Resend" />
                    </a>
                  )}
                </div>
                <div className="verify-error">{errorMess}</div>
              </div>
            </FormItem>
            <div className="verify-action" style={{ textAlign: 'center', padding: '24px 0' }}>
              <ButtonSubmit
                loading={loading}
                onClick={onSubmit}
                style={{
                  color: history.location.pathname === '/register' ? '#000' : '#fff',
                  width: '80%',
                  maxWidth: '223px',
                  borderRadius: '30px',
                }}
              >
                <FormattedMessage id="CHANGE" />
              </ButtonSubmit>
            </div>
          </Form>
        </div>
      </ModalCustom>
    </>
  )
}

ChangePassword.defaultProps = {
  onFinish: () => null,
  onBack: () => null,
  initialField: 6,
}

export default injectIntl(React.memo(ChangePassword))
