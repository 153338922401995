import produce from 'immer'
import { CACHE_USER_INFO } from 'constants/index'
import * as types from './constants'

const initialState = {
  userInfo: JSON.parse(localStorage.getItem(CACHE_USER_INFO)),
  refChildFloor: undefined,
}

export default function HomeReducer(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_PROFILE_SUCCESS:
        localStorage.setItem(CACHE_USER_INFO, JSON.stringify(payload))
        draft.userInfo = payload
        break
      case types.GET_TOTAL_CHILD_FLOOR_SUCCESS:
        draft.refChildFloor = payload
        break

      default:
        return { ...state }
    }
  })
}
