import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import './styles.scss'
import CardRef from 'components/CardRef'
import { getTotalChildFloor } from 'state/user/actions'
import { getGameReport } from 'state/lottery/actions'
import { Empty, Row, Col } from 'antd'

const Account = () => {
  const dispatch = useDispatch()
  const { userInfo, refChildFloor } = useSelector((state) => state.user)
  const { gameReport } = useSelector((state) => state.lottery)

  useEffect(() => {
    dispatch(getTotalChildFloor({ floor: 10 }))
    dispatch(getGameReport())
  }, [dispatch])

  return (
    <div className="wrapper-affiliate">
      <CardRef userInfo={userInfo} />

      <div className="wrapper-info-affiliate">
        <Row gutter={[25, 25]}>
          <Col className="wrapper-info-affiliate-item" span={24} lg={{ span: 12 }}>
            <div>
              <p>
                <FormattedMessage id="Sales Commission" />
              </p>
              <p>{gameReport?.sale_commission / 10 ** 18}</p>
            </div>
          </Col>

          <Col className="wrapper-info-affiliate-item" span={24} lg={{ span: 12 }}>
            <div>
              <p>
                <FormattedMessage id="Additional Rewards" />
              </p>
              <p>{gameReport?.reward_commission / 10 ** 18}</p>
            </div>
          </Col>
        </Row>
      </div>

      <div className="wrapper-info-affiliate">
        <Row gutter={[25, 25]}>
          <Col className="wrapper-info-affiliate-item" span={24} lg={{ span: 12 }}>
            <div>
              <p>
                <FormattedMessage id="Self Bought" />
              </p>
              <p>{gameReport?.totalBuy / 10 ** 18}</p>
            </div>
          </Col>

          <Col className="wrapper-info-affiliate-item" span={24} lg={{ span: 12 }}>
            <div>
              <p>
                <FormattedMessage id="Self Rewards" />
              </p>
              <p>{gameReport?.totalReward / 10 ** 18}</p>
            </div>
          </Col>
        </Row>
      </div>

      <p className="title-table-recent">
        <FormattedMessage id="My Referral" />
      </p>

      <div className="table-ref-floor">
        {refChildFloor?.listChilds?.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="Floor" />
                </th>
                <th>
                  <FormattedMessage id="Total Members" />
                </th>
              </tr>
            </thead>

            <tbody>
              {refChildFloor?.listChilds.map((refChild) => (
                <tr key={refChild.level}>
                  <td>{refChild.level}</td>
                  <td>{refChild?.data?.length ?? 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="empty-table">
            <Empty />
          </div>
        )}
      </div>
    </div>
  )
}

export default Account
