import { all, takeLatest, call, put, takeEvery } from 'redux-saga/effects'
import qs from 'query-string'
import fetchHelper from 'helpers/FetchHelper'
import { ROOT_API_URL } from 'constants/index'
import * as types from './constants'
import * as actions from './actions'

/**
 * Buy lottery
 */

function buyLotteryApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/lottery/buy`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* buyLottery({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(buyLotteryApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('buyLottery', error)
    if (cbe) cbe(error)
  }
}

function getListGameApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/game/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListGame({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(getListGameApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
      yield put(actions.getListGameSuccess(data.data))
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('getListGame', error)
    if (cbe) cbe(error)
  }
}

function getHistoryBuyFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/lotteryDetail/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getHistoryBuy({ payload }) {
  try {
    const { data } = yield call(getHistoryBuyFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getHistoryBuySuccess(data.data))
    }
  } catch (error) {
    console.error('getHistoryBuy', error)
  }
}

function* getHistoryBuyState({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(getHistoryBuyFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('getHistoryBuyState', error)
    if (cbe) cbe(error)
  }
}

function getGameDetailFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/game/detailCode?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getGameDetail({ payload }) {
  try {
    const { data } = yield call(getGameDetailFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getGameDetailSuccess(data.data))
    }
  } catch (error) {
    console.error('getGameDetail', error)
  }
}

function* getGameDetailState({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(getGameDetailFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('getGameDetailState', error)
    if (cbe) cbe(error)
  }
}

function getWinnerListFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/game/result?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getWinnerList({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(getWinnerListFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('getWinnerList', error)
    if (cbe) cbe(error)
  }
}

function getGameReportFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/game/report?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getGameReport({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(getGameReportFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getGameReportSuccess(data.data))
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('getGameReport', error)
    if (cbe) cbe(error)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(types.BUY_LOTTERY, buyLottery),
    takeLatest(types.GET_HISTORY_BUY, getHistoryBuy),
    takeLatest(types.GET_HISTORY_BUY_STATE, getHistoryBuyState),
    takeLatest(types.GET_LIST_GAME, getListGame),
    takeLatest(types.GET_GAME_DETAIL, getGameDetail),
    takeEvery(types.GET_GAME_DETAIL_STATE, getGameDetailState),
    takeLatest(types.GET_WINNER_LIST, getWinnerList),
    takeLatest(types.GET_GAME_REPORT, getGameReport),
  ])
}
