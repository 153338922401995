import { all } from 'redux-saga/effects'

import App from './app/sagas'
import User from './user/sagas'
import Home from './home/sagas'
import Auth from './auth/sagas'
import Wallet from './wallets/sagas'
import Transactions from './transactions/sagas'
import BuyLottery from './lottery/sagas'
import File from './file/sagas'

export default function* rootSaga() {
  yield all([App(), User(), Home(), Auth(), Wallet(), Transactions(), BuyLottery(), File()])
}
