import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 5 4" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.6766 3.90698C2.59811 4.03101 2.40189 4.03101 2.3234 3.90698L0.0276235 0.279069C-0.0508646 0.155038 0.0472458 -1.94565e-07 0.204222 -1.80842e-07L4.79578 2.20565e-07C4.95275 2.34288e-07 5.05086 0.155039 4.97238 0.27907L2.6766 3.90698Z"
      fill="white"
    />
  </Svg>
)

export default Icon
