import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { Form, Upload, DatePicker, Row, Col } from 'antd'
import './styles.scss'
import BackLink from 'components/BackLink'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ControlInputSelect from 'components/ControlInputSelect'
import ControlInputText from 'components/ControlInputText'
import { dummyRequest, getBase64 } from 'helpers'
import { getListCountry } from 'state/app/actions'
import { getProfile, updateKyc } from 'state/user/actions'
import { uploadFile } from 'state/file/actions'
import moment from 'moment'
import { FormattedMessage, injectIntl } from 'react-intl'

const { Dragger } = Upload

export const ID_TYPE = [
  {
    label: <FormattedMessage id="Passport" />,
    value: 'PASSPORT',
  },
  {
    label: <FormattedMessage id="ID Card" />,
    value: 'INDENTITY_CARD',
  },
]

const DOCUMENT_IMAGE_TYPE = {
  FRONT: 'FRONT',
  BACK: 'BACK',
  SELFIE: 'SELFIE',
}

const PrimaryKYC = ({ intl }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()

  const listCountry = useSelector((state) => state.app.listCountry)

  const [idType, setIdType] = useState(ID_TYPE[0].value)
  const [loading, setLoading] = useState(false)
  const [frontImageData, setFrontImageData] = useState(null)
  const [backImageData, setBackImageData] = useState(null)
  const [selfieImageData, setSelfieImageData] = useState(null)
  const [frontImageError, setFrontImageError] = useState('')
  const [backImageError, setBackImageError] = useState(null)
  const [selfieImageError, setSelfieImageError] = useState(null)
  const [pickerOpen, setPickerOpen] = useState(false)

  const handleOnChangeImage = (e, type) => {
    if (e.file.status === 'uploading') {
      return
    }
    if (e.file.status === 'done') {
      getBase64(e.file.originFileObj).then(() => {
        switch (type) {
          case DOCUMENT_IMAGE_TYPE.FRONT: {
            setFrontImageError('')

            dispatch(
              uploadFile(
                {
                  key: 1,
                  image: get(e, 'file.originFileObj', null),
                },
                (receipt) => {
                  setFrontImageData({
                    ...receipt,
                    image: receipt.full_link,
                  })
                },
              ),
            )
            break
          }
          case DOCUMENT_IMAGE_TYPE.BACK: {
            dispatch(
              uploadFile(
                {
                  key: 2,
                  image: get(e, 'file.originFileObj', null),
                },
                (receipt) => {
                  setBackImageData({
                    ...receipt,
                    image: receipt.full_link,
                  })
                },
              ),
            )
            setBackImageError('')
            break
          }
          case DOCUMENT_IMAGE_TYPE.SELFIE: {
            dispatch(
              uploadFile(
                {
                  key: 3,
                  image: get(e, 'file.originFileObj', null),
                },
                (receipt) => {
                  setSelfieImageData({
                    ...receipt,
                    image: receipt.full_link,
                  })
                },
              ),
            )
            setSelfieImageError('')
            break
          }
          default:
            break
        }
      })
    }
  }

  const handleSubmitForm = () => {
    try {
      if (!frontImageData) setFrontImageError(<FormattedMessage id="Error" />)
      if (!backImageData) setBackImageError(<FormattedMessage id="Error" />)
      if (!selfieImageData) setSelfieImageError(<FormattedMessage id="Error" />)
      form.validateFields().then((values) => {
        setLoading(true)
        const country = JSON.parse(values.country_code)
        const payload = {
          ...values,
          image_front: frontImageData._id,
          image_back: backImageData._id,
          image_selfie: selfieImageData._id,
          country_code: country.value,
          type: values?.type.toUpperCase(),
          birthday: moment(values?.birthday?._d.getTime()).format('YYYY-MM-DD'),
        }
        dispatch(
          updateKyc(
            payload,
            () => {
              setLoading(false)
              dispatch(getProfile())
              history.push('/security')
            },
            () => {
              setLoading(false)
            },
          ),
        )
      })
    } catch (error) {
      setLoading(false)
    }
  }

  const countryOptions = useMemo(() => {
    if (listCountry) {
      return listCountry.map((item) => ({
        label: item.title,
        value: item.code,
        // image: '/images/flag/vietnam.png',
        ...item,
      }))
    }
    return []
  }, [listCountry])

  useEffect(() => {
    dispatch(getListCountry())
  }, [dispatch])

  return (
    <div className="warp-page-primary-kyc">
      <div className="page-primary-kyc-content">
        <div className="go-back">
          <BackLink label={<FormattedMessage id="Primary KYC" />} to="/security" />
        </div>

        <Form form={form}>
          <div className="primary-kyc-content-form">
            <div className="box-input-select">
              <Form.Item
                name="country_code"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="Please Select nationality" />,
                  },
                ]}
              >
                <ControlInputSelect
                  style={{ fontSize: '16px' }}
                  label={<FormattedMessage id="Nationality" />}
                  placeholder={intl.formatMessage({ id: 'Select nationality' })}
                  dataSelect={countryOptions}
                />
              </Form.Item>
              <Form.Item name="type" initialValue="Passport">
                <ControlInputSelect
                  label={<FormattedMessage id="ID Type" />}
                  placeholder={intl.formatMessage({ id: 'Select ID type' })}
                  dataSelect={ID_TYPE}
                  onChange={(v) => setIdType(JSON.parse(v).value)}
                />
              </Form.Item>
            </div>

            <div className="box-input-text">
              <Form.Item name="number" rules={[{ required: true, message: <FormattedMessage id="Please enter ID Number" /> }]}>
                <ControlInputText
                  label={<FormattedMessage id="ID Number" />}
                  placeholder={intl.formatMessage({ id: 'Please enter your ID number' })}
                />
              </Form.Item>

              <Row style={{ width: '100%' }}>
                <Col lg={{ span: 12 }} span={24}>
                  <Form.Item
                    name="fistname"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="Please enter first name" />,
                      },
                    ]}
                  >
                    <ControlInputText
                      label={<FormattedMessage id="First Name" />}
                      placeholder={intl.formatMessage({ id: 'Please enter your First Name' })}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 12 }} span={24}>
                  <Form.Item
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="Please enter last name" />,
                      },
                    ]}
                  >
                    <ControlInputText
                      label={<FormattedMessage id="Last Name" />}
                      placeholder={intl.formatMessage({ id: 'Please enter your Last Name' })}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                rules={[
                  {
                    message: <FormattedMessage id="Please enter Date of birth" />,
                  },
                ]}
                className="form-item-calendar"
              >
                <p>
                  <FormattedMessage id="Date of Birth" />
                </p>
                <div>
                  <DatePicker
                    name="birthday"
                    placeholder={intl.formatMessage({ id: 'Please enter your Date of birth' })}
                    autoComplete="true"
                    format="YYYY-MM-DD"
                    allowClear={false}
                    open={pickerOpen}
                    onClick={() => setPickerOpen((prev) => !prev)}
                  />
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={() => setPickerOpen((prev) => !prev)}
                    src="/images/icons/icon-calendar.png"
                    role="presentation"
                    alt=""
                  />
                </div>
              </Form.Item>
            </div>

            <div className="box-input-file">
              <div className="primary-kyc-update-card-photo-title">
                {idType === ID_TYPE[0].value ? (
                  <>
                    <p className="input-file-item-title">
                      <FormattedMessage id="Passport Photo" />
                    </p>
                    <p className="input-file-item-description">
                      <FormattedMessage id="Please make sure the content of the photo is complete and clearly visible" /> <br />
                      <FormattedMessage id="Only supports JPG, JPEF, PNG, image formats" /> <br />
                      <FormattedMessage id="Image size cannot exceed 5MB" />
                    </p>
                  </>
                ) : (
                  <>
                    <p className="input-file-item-title">
                      <FormattedMessage id="ID Card Photo" />
                    </p>
                    <p className="input-file-item-description">
                      <FormattedMessage id="Please make sure the content of the photo is complete and clearly visible" /> <br />
                      <FormattedMessage id="Only supports JPG, JPEF, PNG, image formats" /> <br />
                      <FormattedMessage id="Image size cannot exceed 5MB" />
                    </p>
                  </>
                )}
              </div>
              <div className="primary-kyc-update-card-photo">
                {idType === ID_TYPE[0].value ? (
                  <div className="input-file-item" style={{ maxWidth: '100%' }}>
                    <Dragger
                      customRequest={dummyRequest}
                      showUploadList={false}
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => {
                        handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.FRONT)
                      }}
                      style={{ border: frontImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                    >
                      {frontImageData ? (
                        <img src={frontImageData.full_link} alt="" />
                      ) : (
                        <>
                          <p className="top-sub">
                            <FormattedMessage id="Passport photo Front" />
                          </p>
                          <p className="ant-upload-drag-icon">
                            <img src="/images/upload-file.png" alt="" />
                          </p>
                          <p className="ant-upload-text">
                            <FormattedMessage id="Click me to upload files!" />
                          </p>
                        </>
                      )}
                    </Dragger>
                  </div>
                ) : (
                  <div className="input-file-item">
                    <Dragger
                      customRequest={dummyRequest}
                      showUploadList={false}
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => {
                        handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.FRONT)
                      }}
                      style={{ marginBottom: '32px', border: frontImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                    >
                      {frontImageData ? (
                        <img src={frontImageData.full_link} alt="" />
                      ) : (
                        <>
                          <p className="top-sub">
                            <FormattedMessage id="ID photo Front" />
                          </p>
                          <p className="ant-upload-drag-icon">
                            <img src="/images/upload-file.png" alt="" />
                          </p>
                          <p className="ant-upload-text">
                            <FormattedMessage id="Click me to upload files!" />
                          </p>
                        </>
                      )}
                    </Dragger>
                  </div>
                )}

                {idType === ID_TYPE[0].value ? (
                  <div className="input-file-item">
                    <Dragger
                      customRequest={dummyRequest}
                      showUploadList={false}
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => {
                        handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.BACK)
                      }}
                      style={{ border: backImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                    >
                      {backImageData ? (
                        <img src={backImageData.full_link} alt="" />
                      ) : (
                        <>
                          <p className="top-sub">
                            <FormattedMessage id="Passport photo Back" />
                          </p>
                          <p className="ant-upload-drag-icon">
                            <img src="/images/upload-file.png" alt="" />
                          </p>
                          <p className="ant-upload-text">
                            <FormattedMessage id="Click me to upload files!" />
                          </p>
                        </>
                      )}
                    </Dragger>
                  </div>
                ) : (
                  <div className="input-file-item">
                    <Dragger
                      customRequest={dummyRequest}
                      showUploadList={false}
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => {
                        handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.BACK)
                      }}
                      style={{ border: backImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                    >
                      {backImageData ? (
                        <img src={backImageData.full_link} alt="" />
                      ) : (
                        <>
                          <p className="top-sub">
                            <FormattedMessage id="ID photo Back" />
                          </p>
                          <p className="ant-upload-drag-icon">
                            <img src="/images/upload-file.png" alt="" />
                          </p>
                          <p className="ant-upload-text">
                            <FormattedMessage id="Click me to upload files!" />
                          </p>
                        </>
                      )}
                    </Dragger>
                  </div>
                )}
              </div>

              <div className="input-file-item">
                <p className="input-file-item-title">
                  <FormattedMessage id="Portrait Photo" />
                </p>
                <p className="input-file-item-description">
                  <FormattedMessage id="Please make sure the content of the photo is complete and clearly visible" /> <br />
                  <FormattedMessage id="Only supports JPG, JPEF, PNG, image formats" /> <br />
                  <FormattedMessage id="Image size cannot exceed 5MB" />
                </p>
                <div className="box-input">
                  <Dragger
                    customRequest={dummyRequest}
                    showUploadList={false}
                    accept=".jpg,.jpeg,.png"
                    onChange={(e) => {
                      handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.SELFIE)
                    }}
                    style={{ border: selfieImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                  >
                    {selfieImageData ? (
                      <img src={selfieImageData.full_link} alt="" />
                    ) : (
                      <>
                        <p className="ant-upload-drag-icon">
                          {window.innerWidth >= 768 ? (
                            <img src="/images/upload-file.png" alt="" />
                          ) : (
                            <img src="/images/user-default.png" alt="" />
                          )}
                        </p>
                        <p className="ant-upload-text">
                          <FormattedMessage id="Click me to upload files!" />
                        </p>
                      </>
                    )}
                  </Dragger>

                  <div className="image-example">
                    <img src="/images/file-input.png" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <ButtonPrimary style={{ maxWidth: '150px' }} loading={loading} onClick={handleSubmitForm}>
                <FormattedMessage id="SUBMIT" />
              </ButtonPrimary>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default injectIntl(PrimaryKYC)
