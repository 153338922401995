import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { DatePicker } from 'antd'
import { DropDownIcon, ArrowPrevDayIcon, ArrowNextDayIcon, ArrowNextToDayIcon } from 'widgets/Svg'
import TableWinner from './tableWinner'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getWinnerList } from 'state/lottery/actions'
import { FormattedMessage } from 'react-intl'

const LotteryResults = ({ setParamsGetGameDetail, gameDetail, listGame, currentGame, dayEnable, setDay, day }) => {
  const dispatch = useDispatch()
  const [listWinner, setListWinner] = useState()

  const [paramsWinnerList, setParamsWinnerList] = useState({
    game_code: '',
  })

  useEffect(() => {
    setDay(currentGame?.game_code)
  }, [currentGame?.game_code, setDay])

  useEffect(() => {
    setParamsGetGameDetail((prev) => ({
      ...prev,
      game_code: day,
    }))
  }, [day, setParamsGetGameDetail])

  useEffect(() => {
    setParamsWinnerList((prev) => ({
      ...prev,
      game_code: day,
    }))
  }, [day])

  useEffect(() => {
    dispatch(getWinnerList(paramsWinnerList, (data) => setListWinner(data)))
  }, [dispatch, paramsWinnerList])

  const handlerNextDay = () => {
    if (dayEnable.indexOf(day) - 1 !== -1) {
      setDay(dayEnable[dayEnable.indexOf(day) - 1])
    }
  }

  const handlerPrevDay = () => {
    if (dayEnable.indexOf(day) + 1 !== dayEnable.length) {
      setDay(dayEnable[dayEnable.indexOf(day) + 1])
    }
  }

  const handlerNextToday = () => {
    setDay(currentGame?.game_code)
  }

  const disabledDate = (current) => !dayEnable.includes(current.format('YYYYMMDD'))

  return (
    <div className="home-lottery-results">
      <div className="home-lottery-results-content">
        <div className="home-lottery-results-content-action">
          <Link to="/my-ticket">
            <button type="button">
              <FormattedMessage id="My Tickets" />
            </button>
          </Link>
        </div>

        <div className="home-lottery-results-content-info-result">
          <div className="content-info-result-left">
            {listGame?.rows?.length > 0 && (
              <div className="action-choose-day">
                <p>
                  <FormattedMessage id="Game ID" />
                </p>

                <div className="action-choose-day-gr-icon">
                  <ArrowPrevDayIcon className="prev-day-icon" onClick={() => handlerPrevDay()} />
                  <div className="warp-date-picker">
                    <DatePicker
                      format="YYYYMMDD"
                      allowClear={false}
                      onChange={(date, dateString) => setDay(dateString)}
                      value={moment(day, 'YYYYMMDD')}
                      disabledDate={(current) => disabledDate(current)}
                    />
                    <DropDownIcon />
                  </div>
                  <ArrowNextDayIcon className="next-day-icon" onClick={() => handlerNextDay()} />
                  <ArrowNextToDayIcon className="next-to-day-icon" onClick={() => handlerNextToday()} />
                </div>
              </div>
            )}

            <div className="action-choose-day" style={{ visibility: !gameDetail && 'hidden' }}>
              <p>{moment(+moment(day, 'YYYYMMDD').format('x')).format('YYYY/MM/DD')}</p>
              <p>{gameDetail?.gameSource?.end_time} UTC</p>
            </div>
          </div>
          <div className="content-info-result-center">
            <div className="content-info-result-center-lucky-number" style={{ backgroundImage: "url('/images/bg_ticket.png')" }}>
              {gameDetail ? (
                <>
                  <p>
                    <FormattedMessage id="Lucky Number" />
                  </p>
                  <div className="result-lucky-number">
                    <ul>
                      {gameDetail?.game_result ? (
                        <>
                          {gameDetail?.game_result
                            .replace('[', '')
                            .replace(']', '')
                            ?.split('-')
                            ?.map((item, index) => (
                              <li key={index} style={{ backgroundImage: "url('/images/ball.png')" }}>
                                {item}
                              </li>
                            ))}
                        </>
                      ) : (
                        <>
                          {[...Array(gameDetail?.gameType?.max_number)]?.map((x, i) => (
                            <li key={i} style={{ backgroundImage: "url('/images/ball.png')" }}>
                              ?
                            </li>
                          ))}
                        </>
                      )}
                    </ul>
                  </div>
                </>
              ) : (
                <p style={{ maxWidth: '100%', marginRight: '0' }}>
                  <FormattedMessage id="Empty" />
                </p>
              )}
            </div>

            <div
              className="content-info-result-center-lucky-note"
              style={{ backgroundImage: "url('/images/bg_ticket.png')", visibility: !gameDetail && 'hidden' }}
            >
              <p>{gameDetail?.gameSource?.description}</p>
              {gameDetail?.gameSource?.link.startsWith('http') && (
                <p>
                  <a href={gameDetail?.gameSource?.link} target="_blank" rel="noreferrer">
                    <FormattedMessage id="View" />
                  </a>
                </p>
              )}
            </div>
          </div>
          <div className="content-info-result-right" style={{ visibility: !gameDetail && 'hidden' }}>
            <p>
              <FormattedMessage id="Total tickets sold" /> <br /> <FormattedMessage id="this round" />
            </p>
            <p>{gameDetail?.totalSold}</p>
          </div>
        </div>

        <div className="home-lottery-results-winner-list">
          <p>
            <FormattedMessage id="Winner List" />
          </p>
        </div>

        <TableWinner
          dataSource={listWinner}
          gameResult={gameDetail?.game_result?.replace('[', '').replace(']', '')?.split('-')}
        />
      </div>
    </div>
  )
}

export default LotteryResults
