import { all, takeLatest, call, put } from 'redux-saga/effects'
import { get } from 'lodash'
import fetchHelper from 'helpers/FetchHelper'
import * as types from './constants'
import * as actions from './actions'
import { ROOT_API_URL } from 'constants/index'

function getStaticDataFromApi() {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/staticData/data`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getStaticData({ payload }) {
  try {
    const { data } = yield call(getStaticDataFromApi, payload)
    if (get(data, 'code') === 200) {
      yield put(actions.getStaticDataSuccess(data.data))
    }
  } catch (error) {
    console.error('getStaticData', error)
  }
}

function getCountryListFromApi() {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/country/list`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getCountryList({ payload }) {
  try {
    const { data } = yield call(getCountryListFromApi, payload)
    if (get(data, 'code') === 200) {
      yield put(actions.getListCountrySuccess(data.data))
    }
  } catch (error) {
    console.error('getCountryList', error)
  }
}

export default function* rootSaga() {
  yield all([takeLatest(types.GET_STATIC_DATA, getStaticData)])
  yield all([takeLatest(types.GET_LIST_COUNTRY, getCountryList)])
}
