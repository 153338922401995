import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGameDetailState, getListGame } from 'state/lottery/actions'
import CountUp from 'react-countup'
import './styles.scss'
import LotteryResults from './component/lotteryResults'

const MyTicket = () => {
  const dispatch = useDispatch()
  const { listGame } = useSelector((state) => state.lottery)
  const [gameDetail, setGameDetail] = useState()
  const [gameDetailToDay, setGameDetailToDay] = useState()
  const [dayEnable, setDayEnable] = useState([])
  const [currentGame, setCurrentGame] = useState()

  useEffect(() => {
    dispatch(getListGame({ page: 1, pageSize: 100 }))
  }, [dispatch])

  useEffect(() => {
    document.querySelector('body').style.overflow = 'unset'
  }, [])

  useEffect(() => {
    document.querySelector('body').style.overflow = 'unset'
  }, [])

  const [paramsGetGameDetail, setParamsGetGameDetail] = useState({
    game_code: '',
  })

  useEffect(() => {
    setParamsGetGameDetail({ game_code: currentGame?.game_code })
  }, [currentGame?.game_code])

  useEffect(() => {
    dispatch(
      getGameDetailState(
        paramsGetGameDetail,
        (data) => setGameDetail(data),
        () => {
          setGameDetail(null)
        },
      ),
    )
  }, [dispatch, paramsGetGameDetail])

  const [paramsGetGameDetailToDay, setParamsGetGameDetailToDay] = useState({
    game_code: '',
  })

  useEffect(() => {
    setParamsGetGameDetailToDay({ game_code: gameDetail?.game_code })
  }, [gameDetail?.game_code])

  useEffect(() => {
    dispatch(
      getGameDetailState(
        paramsGetGameDetailToDay,
        (data) => setGameDetailToDay(data),
        () => setGameDetailToDay(null),
      ),
    )
  }, [dispatch, paramsGetGameDetailToDay])

  useEffect(() => {
    if (listGame.rows) {
      const arrayForSort = [...listGame.rows]
        ?.filter((item) => item.start_date * 1000 < new Date().getTime())
        ?.sort((a, b) => (a.game_code > b.game_code ? -1 : 1))

      setDayEnable(arrayForSort?.map((item) => item.game_code) || [])
    }
  }, [listGame])

  useEffect(() => {
    if (
      listGame?.rows?.filter(
        (item) => item.start_date * 1000 < new Date().getTime() && new Date().getTime() < item.end_date * 1000,
      )?.[0]
    ) {
      setCurrentGame(
        listGame?.rows?.filter(
          (item) => item.start_date * 1000 < new Date().getTime() && new Date().getTime() < item.end_date * 1000,
        )?.[0],
      )
    } else {
      setCurrentGame(
        listGame?.rows
          ?.filter((item) => item.start_date * 1000 < new Date().getTime())
          ?.sort((a, b) => (a.game_code > b.game_code ? -1 : 1))?.[0],
      )
    }
  }, [listGame])

  const bannerRef = useRef(null)

  const handleImageLoad = () => {
    const scaleFactor = bannerRef.current.clientWidth / 990
    const height = bannerRef.current.clientHeight * (1 / scaleFactor)
    const width = bannerRef.current.clientWidth * (1 / scaleFactor)
    bannerRef.current.style.width = `${width}px`
    bannerRef.current.style.height = `${height}px`
    bannerRef.current.style.transform = `scale(${scaleFactor})`
    bannerRef.current.style.marginBottom = `-${height - height * scaleFactor}px`
  }

  return (
    <div className="my-ticket-container fadeIn">
      <div className="my-ticket-container-banner" ref={bannerRef}>
        <img src="/images/my-ticket/banner.png" alt="banner" onLoad={handleImageLoad} />
        <span style={{ display: 'flex' }}>
          <span>$</span>
          <CountUp end={gameDetailToDay?.gameType?.total_reward / 10 ** 18} separator="," />
        </span>
      </div>

      <LotteryResults
        setParamsGetGameDetail={setParamsGetGameDetail}
        gameDetail={gameDetail}
        currentGame={currentGame}
        dayEnable={dayEnable}
        listGame={listGame}
      />
    </div>
  )
}

export default MyTicket
