const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds))

const wait = (data, timer = 10 * 1000) =>
  new Promise((resolve, reject) => {
    if (data) {
      resolve()
    } else {
      setTimeout(reject, timer)
    }
  })

const expiredUnixTime = (expireTime) => {
  const time = Math.floor(new Date() / 1000)

  return time > expireTime
}

const executeAllPromises = (promises) => {
  const resolvingPromises = promises.map(
    (promise) =>
      new Promise((resolve) => {
        const payload = new Array(2)
        promise
          .then((result) => {
            payload[0] = result
          })
          .catch((error) => {
            payload[1] = error
          })
          .then(() => {
            resolve(payload)
          })
      }),
  )

  const errors = []
  const results = []

  // Execute all wrapped Promises
  return Promise.all(resolvingPromises).then((items) => {
    items.forEach((payload) => {
      if (payload[1]) {
        errors.push(payload[1])
      } else {
        results.push(payload[0])
      }
    })

    return {
      errors,
      results,
    }
  })
}

export default {
  sleep,
  wait,
  expiredUnixTime,
  executeAllPromises,
}
