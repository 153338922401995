import React from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import './styles.scss'
import { socials } from './config'
import InputWithButton from 'widgets/InputWithButton'
import { FormattedMessage, injectIntl } from 'react-intl'

const Footer = ({ intl }) => (
  <footer className="footer-container">
    <div className="footer-content">
      <Row gutter={[20, 24]}>
        <Col xs={24} sm={24} lg={24} xl={9}>
          <Link to="/">
            <div className="footer-logo">
              <img src="/images/logo-text.png" title="Lottery CeDeFi Wallets" alt="Lottery CeDeFi Wallets" />
            </div>
          </Link>
          <p className="footer-des">
            <FormattedMessage id="Provides all you need in asset management" />
          </p>
          <div className="socials-list">
            {socials.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <a key={item?.link_to + index} href={item?.link_to} target={item?.target} rel="noreferrer">
                <img src={item?.img_url} alt={item?.label} width={`${item?.width || '40px'}`} title={item?.label} />
              </a>
            ))}
          </div>
        </Col>
        <Col xs={12} sm={12} lg={6} xl={3}>
          <div className="quick-links">
            <div className="footer-title">
              <FormattedMessage id="Quick Links." />
            </div>
            <ul>
              <li>
                <Link to="" target="_blank" rel="noreferrer" className="footer-sub-title">
                  <FormattedMessage id="Document" />
                </Link>
              </li>
              <li>
                <Link to="" target="_blank" rel="noreferrer" className="footer-sub-title">
                  <FormattedMessage id="BLog" />
                </Link>
              </li>
              <li>
                <Link to="/wallet" rel="noreferrer" className="footer-sub-title">
                  <FormattedMessage id="Pricing" />
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col xs={12} sm={12} lg={6} xl={3}>
          <div className="quick-links">
            <div className="footer-title" style={{ opacity: 0 }}>
              <FormattedMessage id="Quick Links." />
            </div>
            <ul>
              <li>
                <Link to="" target="_blank" rel="noreferrer" className="footer-sub-title">
                  <FormattedMessage id="Dashboard" />
                </Link>
              </li>
              <li>
                <Link to="" target="_blank" rel="noreferrer" className="footer-sub-title">
                  <FormattedMessage id="Feature" />
                </Link>
              </li>
              <li>
                <Link to="/" rel="noreferrer" className="footer-sub-title">
                  <FormattedMessage id="Market" />
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={9}>
          <div className="footer-actions">
            <div className="footer-title">
              <FormattedMessage id="Submit for updates." />
            </div>
            <p className="footer-actions-des">
              <FormattedMessage id="Subscribe to get update and notify our exchange and products" />
            </p>
            <InputWithButton button="SEND" placeholder={intl.formatMessage({ id: 'Enter your email address' })} />
          </div>
        </Col>
      </Row>
      <div className="footer-bottom">
        <p>
          <FormattedMessage id="Lottery CeDeFi Wallets©. All rights reserved." />
        </p>
        <div className="footer-bottom-right">
          <Link to="https://beurl.app/zodi-term" target="_blank" rel="noreferrer">
            <FormattedMessage id="Term of Service" />
          </Link>
          <span className="hr-hor" />
          <Link to="https://beurl.app/zodi-privacy" target="_blank" rel="noreferrer">
            <FormattedMessage id="Privacy Policy" />
          </Link>
        </div>
      </div>
    </div>
  </footer>
)

export default injectIntl(Footer)
