/* eslint-disable no-case-declarations */
import produce from 'immer'
import * as types from './constants'
import { CACHE_CHAIN_LIST } from 'constants/index'

const chainList = JSON.parse(localStorage.getItem(CACHE_CHAIN_LIST))

const initialState = {
  chainList,
  chainMap: ['BEP20', 'POLYGON'],
  currency: undefined,
  balance: undefined,
  currencyAttr: undefined,
  walletsAddress: undefined,
}

export default function WalletsReducer(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_CHAIN_LIST_SUCCESS:
        draft.chainList = payload
        draft.chainMap = payload.map((item) => [item.code])
        break
      case types.GET_CURRENCY_SUCCESS:
        draft.currency = payload
        break
      case types.FETCH_WALLET_BALANCE_SUCCESS:
        const { currency } = state
        const currencyWithBalance = currency.map((item) => ({
          ...item,
          usdValue: (payload[item.code] * item.usd_rate) / 1e18,
          balance: payload[item.code],
        }))
        draft.currency = currencyWithBalance.sort((a, b) => b.balance * b.usd_rate - a.balance * a.usd_rate)
        draft.balance = payload
        break
      case types.GET_CURRENCY_ATTR_SUCCESS:
        draft.currencyAttr = payload
        break
      case types.GET_WALLET_ADDRESS_SUCCESS:
        draft.walletsAddress = payload
        break

      default:
        return { ...state }
    }
  })
}
