import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ButtonSubmit from 'widgets/ButtonSubmit'
// import ButtonSecondary from 'widgets/ButtonSecondary'
import './styles.scss'
import AuthCard from '../AuthCard'
import { resendAuthenLoginAccountCode, verifyEmail } from 'state/auth/actions'
import InputCode from 'components/InputCode'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const VerifyEmailCode = ({ type, formValue, initialField, token, onFinish, onBack, children, ...props }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [timeCountDown, setTimeCountDown] = useState(50)
  const history = useHistory()

  const onSubmit = (value) => {
    const code = Object.values(value || codeValue).filter((o) => o)
    if (code.length < initialField) {
      setErrorMess(
        <>
          <FormattedMessage id="Please enter" /> {initialField} <FormattedMessage id="characters from email of you." />
        </>,
      )
    } else {
      setErrorMess('')
      setLoading(true)
      switch (type) {
        case 'login':
          dispatch(
            verifyEmail(
              {
                token,
                code: code.join(''),
                os: window.navigator.platform,
                device_id: window.navigator.appVersion,
              },
              (receipt) => {
                onFinish({ code: codeValue, type: 'is_email', ...receipt })
                setLoading(false)
              },
              () => {
                setLoading(false)
              },
            ),
          )
          break
        default:
          break
      }
    }
  }

  const onChangeCode = (value) => {
    setCodeValue(value)
    const code = Object.values(value).filter((o) => o)
    if (code.length >= initialField) {
      onSubmit(value)
    }
  }

  const resendCode = () => {
    switch (type) {
      case 'login':
        dispatch(
          resendAuthenLoginAccountCode(
            {
              token,
            },
            () => {
              setTimeCountDown(50)
            },
          ),
        )
        break
      default:
        break
    }
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => clearInterval(countDownInterval)
  }, [])

  return (
    <AuthCard className="verify-code fadeIn" {...props}>
      <div style={{ textAlign: 'center', marginBottom: '32px' }}>
        <img src="/images/dice.png" style={{ maxWidth: '35%' }} alt="Verification code" />
      </div>

      <h2 className="verify-title">
        <FormattedMessage id="Verification code" />
      </h2>
      <div className="verify-input">
        <InputCode
          type="number"
          value={codeValue}
          initialField={initialField}
          hasErrored={Boolean(errorMess)}
          onChange={onChangeCode}
        />
      </div>
      <div className="verify-resend">
        <div style={{ opacity: timeCountDown > 0 ? '1' : '0' }}>
          <FormattedMessage id="Resend in" /> {timeCountDown}s
        </div>
        {timeCountDown <= 0 && (
          <a onClick={resendCode} role="presentation">
            <FormattedMessage id="Resend" />
          </a>
        )}
      </div>
      <div className="verify-error">{errorMess}</div>
      <div className="verify-action">
        <ButtonSubmit style={{ borderRadius: '35px', width: '150px' }} loading={loading} onClick={onSubmit}>
          <FormattedMessage id="Done" />
        </ButtonSubmit>
      </div>
    </AuthCard>
  )
}

VerifyEmailCode.defaultProps = {
  initialField: 6,
  onFinish: () => null,
  onBack: () => null,
}

export default React.memo(VerifyEmailCode)
