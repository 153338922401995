import './styles.scss'
import React from 'react'
import BackLink from 'components/BackLink'
import { FormattedMessage } from 'react-intl'

const Page404 = () => (
  <div className="warp-page-404">
    <div className="content">
      <div>
        <div className="box-content-text">
          <div>
            <h1>
              <FormattedMessage id="oops..!" /> <br /> <FormattedMessage id="page not found." />
            </h1>
            <p>
              <FormattedMessage id="The requested URL was not found." />
            </p>
          </div>
          <a className="go-back" href="/">
            <BackLink label={<FormattedMessage id="Home" />} />
          </a>
        </div>
        <div className="box-image-text">
          <img className="image-text" src="/images/404/text-404.png" alt="" />
        </div>
      </div>

      <img className="stripes" src="/images/404/background.png" alt="" />
    </div>
  </div>
)

export default Page404
