import produce from 'immer'
import * as Types from './constants'

const initialState = {
  historyBuyList: {
    data: undefined,
    page: 0,
    total: 0,
  },

  listGame: {
    data: undefined,
    page: 0,
    total: 0,
  },

  gameDetail: {
    data: undefined,
  },

  gameReport: {
    data: undefined,
  },
}

export default function BuyLotteryReducer(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.GET_HISTORY_BUY_SUCCESS:
        draft.historyBuyList = payload
        break

      case Types.GET_LIST_GAME_SUCCESS:
        draft.listGame = payload
        break

      case Types.GET_GAME_DETAIL_SUCCESS:
        draft.gameDetail = payload
        break

      case Types.GET_GAME_REPORT_SUCCESS:
        draft.gameReport = payload
        break

      default:
        return { ...state }
    }
  })
}
