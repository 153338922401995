import React from 'react'
import { useHistory } from 'react-router-dom'
import './styles.scss'
import { BackIcon } from 'widgets/Svg'

const BackLink = ({ icon, label, to, ...props }) => {
  const history = useHistory()
  return (
    <div className="back-link" onClick={() => history.push(to)} role="presentation" {...props}>
      {icon}
      <div className="back-title">{label}</div>
    </div>
  )
}

BackLink.defaultProps = {
  icon: <BackIcon />,
  label: '',
  to: '/',
}

export default BackLink
