import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 13 26" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.843 25.4999C10.5655 25.5008 10.2913 25.4419 10.0406 25.3276C9.78988 25.2133 9.56899 25.0464 9.39417 24.8392L0.422554 14.126C0.149353 13.8065 0 13.4057 0 12.9921C0 12.5786 0.149353 12.1778 0.422554 11.8583L9.70994 1.14503C10.0252 0.780394 10.4783 0.551087 10.9695 0.507556C11.4606 0.464024 11.9497 0.609836 12.329 0.912911C12.7083 1.21599 12.9469 1.6515 12.9921 2.12365C13.0374 2.59579 12.8857 3.06589 12.5705 3.43053L4.26753 13.0011L12.2918 22.5716C12.519 22.8337 12.6633 23.1528 12.7076 23.4913C12.752 23.8297 12.6945 24.1733 12.5421 24.4814C12.3897 24.7894 12.1487 25.0491 11.8476 25.2296C11.5464 25.4101 11.1978 25.5039 10.843 25.4999Z"
      fill="white"
    />
  </Svg>
)

export default Icon
