import React, { useState } from 'react'
import './styles.scss'
import ModalScan from 'components/ModalScan'
import ControlInputText from 'components/ControlInputText'
import { FormattedMessage } from 'react-intl'

const ShareLink = ({ text, label, value, notica, onChange, ...props }) => {
  const [showNoti, setShowNoti] = useState(false)
  const [pasted, setPasted] = useState(false)
  const [, setVal] = useState('')
  const [qrscan, setQrscan] = useState('No result')
  const [showScan, setShowScan] = useState(false)
  const [open, setOpen] = useState(false)

  const handlePaste = async () => {
    setPasted(true)
    setShowNoti(true)
    onChange(await navigator.clipboard.readText())
    setTimeout(() => {
      setShowNoti(false)
    }, 1000)
  }
  const handleChange = (e) => {
    if (!pasted) {
      setVal(e.target.value)
    }
    setPasted(false)
  }
  const setShowScanQr = () => {
    setOpen((prev) => !prev)
  }
  const handleScan = (data) => {
    if (data) {
      onChange(data)
      setOpen(false)
      setQrscan(data)
    }
  }
  const handleError = (err) => {
    console.error(err)
  }

  return (
    <div className="box-show-withdraw-address-text" {...props}>
      <p>{label}</p>
      <div className="wrapper-wd-input-text">
        <ControlInputText value={value} onChange={onChange} {...props} />
        <div className="address-action">
          <img
            role="presentation"
            onClick={handlePaste}
            value={qrscan}
            onChange={(e) => handleChange(e)}
            src="/images/icons/copy-outline.png"
            alt=""
          />
          <img src="/images/icons/icon-qr.png" role="presentation" alt="" onClick={setShowScanQr} />

          {qrscan !== 'No result' && (
            <div className="tooltip-qr">
              <FormattedMessage id="Scan Success" />
            </div>
          )}
          {showNoti && <div className="tooltip">{notica || 'Pasted'}</div>}
        </div>
      </div>

      {open && (
        <ModalScan
          value={value ?? '--'}
          handleScan={handleScan}
          handleError={handleError}
          visible={open}
          setOpen={setOpen}
          title={<FormattedMessage id="Scan to Withdraw" />}
          showScan={showScan}
          setShowScan={setShowScan}
        />
      )}
    </div>
  )
}

export default ShareLink
