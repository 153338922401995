import * as types from './constants'

export const getTransactionList = (payload) => ({
  type: types.GET_TRANSACTIONS_LIST,
  payload,
})
export const getTransactionListSuccess = (payload) => ({
  type: types.GET_TRANSACTIONS_LIST_SUCCESS,
  payload,
})
