import React from 'react'
import { Col, Row } from 'antd'
import './styles.scss'
import { FormattedMessage } from 'react-intl'

const HowToPlay = () => (
  <div className="warp-how-to-play" id="how_to_play">
    <p>
      <FormattedMessage id="How to play" />
    </p>

    <Row gutter={[30, 24]} className="warp-how-to-play-item">
      <Col span={24} sm={{ span: 8 }}>
        <div className="how-to-play-item">
          <img className="how-to-play-item-pc" src="/images/Home/how-to-play/item-1.png" alt="" />
          <img className="how-to-play-item-mb" src="/images/Home/how-to-play/item-1-mb.png" alt="" />
          <div>
            <p>
              <FormattedMessage id="BUY TICKETS" />
            </p>
            <p>
              <FormattedMessage id="Buy ticket with $1, and choose numbers for ticket." />
            </p>
          </div>
        </div>
      </Col>
      <Col span={24} sm={{ span: 8 }}>
        <div className="how-to-play-item">
          <img className="how-to-play-item-pc" src="/images/Home/how-to-play/item-3.png" alt="" />
          <img className="how-to-play-item-mb" src="/images/Home/how-to-play/item-3-mb.png" alt="" />
          <div>
            <p>
              <FormattedMessage id="WAIT FOR THE DRAW" />
            </p>
            <p>
              <FormattedMessage id="Draw results will be taken from top reputable places and close 30 minutes before Drawing Launch" />
            </p>
          </div>
        </div>
      </Col>
      <Col span={24} sm={{ span: 8 }}>
        <div className="how-to-play-item">
          <img className="how-to-play-item-pc" src="/images/Home/how-to-play/item-2.png" alt="" />
          <img className="how-to-play-item-mb" src="/images/Home/how-to-play/item-2-mb.png" alt="" />
          <div>
            <p>
              <FormattedMessage id="CHECK FOR PRIZES" />
            </p>
            <p>
              <FormattedMessage id="After the draw is over, go to My Ticket to check your prize" />
            </p>
          </div>
        </div>
      </Col>
    </Row>

    <div className="warp-how-to-play-note">
      <Row gutter={16}>
        <Col span={24} order={2} lg={{ order: 1, span: 18 }} className="warp-how-to-play-note-content">
          <p>
            <FormattedMessage id="Jackpot Instructions:" />
          </p>
          <ul>
            <li>
              <FormattedMessage id="Lottery results will be taken from the top prestigious Jackpot locations in the world." />
            </li>
          </ul>

          <div className="wrap-table">
            <table className="wrap-table-pc">
              <tbody>
                <tr>
                  <td>
                    <FormattedMessage id="Monday" />
                  </td>
                  <td>
                    <FormattedMessage id="Tuesday" />
                  </td>
                  <td>
                    <FormattedMessage id="Wednesday" />
                  </td>
                  <td>
                    <FormattedMessage id="Thursday" />
                  </td>
                  <td>
                    <FormattedMessage id="Friday" />
                  </td>
                  <td>
                    <FormattedMessage id="Saturday" />
                  </td>
                  <td>
                    <FormattedMessage id="Sunday" />
                  </td>
                </tr>

                <tr>
                  <td className="active">
                    1:00 PM UTC <br /> PCSO (Philippines)
                  </td>
                  <td />
                  <td className="active">
                    1:00 PM UTC <br />
                    PCSO (Philippines)
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>

                <tr>
                  <td />
                  <td className="active">
                    11:00 AM UTC <br /> Vietlott (Vietnam)
                  </td>
                  <td />
                  <td className="active">
                    11:00 AM UTC <br />
                    Vietlott (Vietnam)
                  </td>
                  <td />
                  <td className="active">
                    11:00 AM UTC <br />
                    Vietlott (Vietnam)
                  </td>
                  <td />
                </tr>

                <tr>
                  <td />
                  <td />

                  <td />
                  <td />
                  <td className="active">
                    11:00 AM UTC <br />
                    SportToTo (Malaysia)
                  </td>
                  <td />
                  <td className="active">
                    11:00 AM UTC <br />
                    SportToTo (Malaysia)
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="wrap-table-mb">
              <tbody>
                <tr>
                  <td>
                    <FormattedMessage id="Monday" />
                  </td>
                  <td className="active">1:00 PM UTC PCSO (Philippines)</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="Tuesday" />
                  </td>
                  <td className="active">11:00 AM UTC Vietlott (Vietnam)</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="Wednesday" />
                  </td>
                  <td className="active">11:00 AM UTC SportToTo (Malaysia)</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="Thursday" />
                  </td>
                  <td className="active">11:00 AM UTC Vietlott (Vietnam)</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="Friday" />
                  </td>
                  <td className="active">1:00 PM UTC PCSO (Philippines)</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="Saturday" />
                  </td>
                  <td className="active">11:00 AM UTC Vietlott (Vietnam)</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="Sunday" />
                  </td>
                  <td className="active">11:00 AM UTC SportToTo (Malaysia)</td>
                </tr>
              </tbody>
            </table>
          </div>

          <ul>
            <li>
              <FormattedMessage id="You can buy a ticket for $1. Ticket sales will close 30 minutes before Drawing Launch." />
            </li>
            <li>
              <FormattedMessage id="The player chooses six numbers for each ticket 1 to 55." />
            </li>
            <li>
              <FormattedMessage id="You can select numbers manually or automatically." />
            </li>
            <li>
              <FormattedMessage id="Each draw generates six numbers." />
            </li>
            <li>
              <FormattedMessage id="The more numbers you match, the bigger prizes you will win." />
            </li>
          </ul>

          <p>
            <FormattedMessage id="PRIZE POOL DISTRIBUTION:" />
          </p>
          <ul>
            <li>
              <FormattedMessage id="Prize Pool will be directly distributed after tickets sales collection and before daily closing sale time." />
            </li>
            <li>
              <FormattedMessage id="Fifty (50%) percent of every Ticket sale is distributed to following Prizes below:" />
            </li>
          </ul>

          <table>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="Prize" />
                </td>
                <td style={{ minWidth: window.innerWidth > 768 ? '120px' : '87px' }}>
                  <FormattedMessage id="Result" />
                </td>
                <td>
                  <FormattedMessage id="Prize value (USD)" />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Jackpot" />
                </td>
                <td>○ ○ ○ ○ ○ ○</td>
                <td>
                  <FormattedMessage id="(Minimum of $100,000 and accumulated)" />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="First prize" />
                </td>
                <td>○ ○ ○ ○ ○</td>
                <td>$2,000</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Second prize" />
                </td>
                <td>○ ○ ○ ○</td>
                <td>$50</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="Third prize" />
                </td>
                <td>○ ○ ○</td>
                <td>$5</td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col
          span={24}
          order={1}
          lg={{ order: 2, span: 6 }}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <img src="/images/Home/how-to-play/note.png" alt="" />
        </Col>
      </Row>
    </div>
  </div>
)

export default HowToPlay
