import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'antd'
import './styles.scss'
import AuthWrapper from 'modules/auth/components/AuthWrapper'
import AuthCard from 'modules/auth/components/AuthCard'
import Input from 'widgets/Input'
import FormItem from 'widgets/FormItem'
import ButtonPrimary from 'widgets/ButtonPrimary'
import AuthenCode from 'modules/auth/components/VerifyAuthenCode'
import { FormattedMessage, injectIntl } from 'react-intl'

const NewPassword = ({ intl }) => {
  const [form] = Form.useForm()
  const [view, setView] = useState('login')

  const onFormSubmit = () => {
    form.validateFields().then(() => {
      setView('authen')
    })
  }

  return (
    <AuthWrapper>
      <div className="wrapper-form-newpass">
        {view === 'login' ? (
          <AuthCard className="form-newpass fadeIn">
            <h2>
              <FormattedMessage id="New Password" />
            </h2>
            <Form layout="vertical" form={form} onFinish={onFormSubmit}>
              <FormItem
                label={
                  <span>
                    <FormattedMessage id="Password" />
                  </span>
                }
                name="password"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="Please enter password field" />,
                  },
                ]}
              >
                <Input placeholder="**********" type="password" />
              </FormItem>
              <FormItem
                label={
                  <span>
                    <FormattedMessage id="Confirm Password" />
                  </span>
                }
                name="confirm"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="Please confirm your password!" />,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(intl.formatMessage({ id: 'The two passwords that you entered do not match!' })),
                      )
                    },
                  }),
                ]}
              >
                <Input placeholder="**********" type="password" />
              </FormItem>
              <FormItem>
                <ButtonPrimary type="submit" className="form-action" style={{ maxWidth: '150px' }}>
                  <FormattedMessage id="CHANGE" />
                </ButtonPrimary>
              </FormItem>
            </Form>
            <div className="form-links">
              <FormattedMessage id="Already have Account.?" />
              <Link to="/login">
                <FormattedMessage id="Sign In" />
              </Link>
            </div>
          </AuthCard>
        ) : (
          <AuthenCode />
        )}
      </div>
    </AuthWrapper>
  )
}

export default injectIntl(NewPassword)
