import React, { useMemo } from 'react'
import { Table } from 'antd'
import './style.scss'

const TableWrapper = ({ columns, ...props }) => {
  const handleColumns = useMemo(() => {
    if (window.innerWidth < 768) {
      return columns.filter((item) => item.mobileHidden !== true)
    }
    return columns
  }, [columns])
  return (
    <>
      <Table
        scroll={{ y: window.innerWidth < 768 ? 'max-content' : 272, x: 'max-content' }}
        columns={handleColumns}
        rowKey={(record) => `${record?.id || record?.title}`}
        {...props}
      />
    </>
  )
}

export default React.memo(TableWrapper)
