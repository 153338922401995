import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import './styles.scss'
import { FormattedMessage, injectIntl } from 'react-intl'
import { SearchIcon, LanguageIcon } from 'widgets/Svg'
import { linksPrivate, menuGame } from 'components/Menu/config'
import ButtonSecondary from 'widgets/ButtonSecondary'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { Select } from 'antd'
import { setLanguage } from 'state/app/actions'

const { Option } = Select

const ModalMenu = ({ intl, visible, setModalMenu, isLogin }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { optionLanguage, language } = useSelector((state) => state.app)

  const handleChangeLang = (lang) => {
    dispatch(setLanguage({ language: lang }))
  }

  return (
    <>
      {visible && (
        <div className="modal-menu">
          <div className="modal-menu-box-input">
            <input placeholder={intl.formatMessage({ id: 'Find your game' })} />
            <SearchIcon />
          </div>

          <div className="wrapper-modal-menu-box-menu">
            <div className="modal-menu-box-menu">
              <ul>
                {linksPrivate.map((entry, index) => {
                  const isHttp = entry?.href?.startsWith('http')
                  const Tag = isHttp ? 'a' : Link
                  const propsLink = isHttp ? { href: entry.href, target: entry.target } : { to: entry.href }

                  // Icon
                  const isImage = typeof entry?.icon === 'string'
                  const Icon = isImage ? 'img' : entry?.icon
                  const iconProps = isImage ? { src: entry?.icon, alt: '' } : {}
                  return (
                    <li
                      key={`modal-menu-item-${index}`}
                      className={location.pathname.split('/').includes(entry.label.toLowerCase()) ? 'active' : ''}
                    >
                      <Tag {...propsLink} rel="noreferrer" onClick={() => setModalMenu(false)}>
                        {entry.icon && (
                          <div>
                            <Icon {...iconProps} />
                          </div>
                        )}
                        <FormattedMessage id={entry.label} />
                      </Tag>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="modal-menu-box-menu">
              <p>
                <FormattedMessage id="Game" />
              </p>
              <ul>
                {menuGame.map((entry) => {
                  // Icon
                  const isImage = typeof entry?.icon === 'string'
                  const Icon = isImage ? 'img' : entry?.icon
                  const iconProps = isImage ? { src: entry?.icon, alt: '' } : {}
                  return (
                    <li key={entry.label} className={location.pathname === entry.href ? 'active' : ''}>
                      <Link to={entry.href}>
                        {entry.icon && (
                          <div>
                            <Icon {...iconProps} />
                          </div>
                        )}
                        <div className="menu-label">
                          <FormattedMessage id={entry.label} />
                          {entry.tag && (
                            <div className="menu-tag">
                              <FormattedMessage id={entry.tag} />
                            </div>
                          )}
                        </div>
                      </Link>
                    </li>
                  )
                })}

                <li className="menu-language">
                  <LanguageIcon />
                  <Select
                    defaultValue={language}
                    onChange={handleChangeLang}
                    dropdownClassName="dropdownClassName"
                    dropdownMatchSelectWidth={150}
                  >
                    {optionLanguage.map((item, index) => (
                      <Option value={item.id} key={`language-${index}`}>
                        <div
                          className="image-flag"
                          style={{
                            backgroundImage: `url(${item.image})`,
                          }}
                        />
                        <span className="text-flag">{item.label}</span>
                      </Option>
                    ))}
                  </Select>
                </li>
              </ul>
            </div>
          </div>

          {!isLogin && (
            <div className="modal-menu-actions">
              <Link to="/login">
                <ButtonPrimary size="larger" onClick={() => setModalMenu(false)}>
                  <FormattedMessage id="Sign in" />
                </ButtonPrimary>
              </Link>
              <Link to="/register">
                <ButtonSecondary size="larger" onClick={() => setModalMenu(false)}>
                  <FormattedMessage id="Sign up" />
                </ButtonSecondary>
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default injectIntl(ModalMenu)
