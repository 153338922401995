import './styles.scss'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const ComingSoon = () => (
  <div className="soon-container">
    <div className="content">
      <span>
        <FormattedMessage id="COMING SOON" />
      </span>
      <p>
        <FormattedMessage id="We will be celebrating the launch of our new site very soon!" />
      </p>
    </div>
  </div>
)

export default ComingSoon
