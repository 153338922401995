import './styles.scss'
import React from 'react'
import SelectWithImage from 'widgets/SelectWithImage'

const ControlInputSelect = ({ label, dataSelect, showAll, defaultValue, ...props }) => (
  <div className="input-select-item">
    {label ? <p>{label}</p> : ''}
    <SelectWithImage label={label} showAll={showAll} defaultValue={defaultValue} dataSelect={dataSelect} {...props} />
  </div>
)

export default React.memo(ControlInputSelect)
