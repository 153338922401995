import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles.scss'
import DefaultLayout from 'layouts/DefaultLayout'
import Banner from './component/banner'
import LotteryResults from './component/lotteryResults'
import HowToPlay from './component/howToPlay'
import { getGameDetailState, getListGame } from 'state/lottery/actions'
import moment from 'moment'

const Home = () => {
  const dispatch = useDispatch()

  const [gameDetail, setGameDetail] = useState()
  const { listGame } = useSelector((state) => state.lottery)
  const [currentGame, setCurrentGame] = useState()
  const [dayEnable, setDayEnable] = useState([])
  const [day, setDay] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    document.querySelector('body').style.overflow = 'unset'
  }, [])

  const [paramsGetGameDetail, setParamsGetGameDetail] = useState({
    game_code: moment(new Date().getTime()).format('YYYYMMDD'),
  })

  useEffect(() => {
    dispatch(
      getGameDetailState(
        paramsGetGameDetail,
        (data) => setGameDetail(data),
        () => setGameDetail(null),
      ),
    )
  }, [dispatch, paramsGetGameDetail])

  useEffect(() => {
    dispatch(getListGame({ page: 1, pageSize: 100 }))
  }, [dispatch])

  useEffect(() => {
    if (
      listGame?.rows?.filter(
        (item) => item.start_date * 1000 < new Date().getTime() && new Date().getTime() < item.end_date * 1000,
      )?.[0]
    ) {
      setCurrentGame(
        listGame?.rows?.filter(
          (item) => item.start_date * 1000 < new Date().getTime() && new Date().getTime() < item.end_date * 1000,
        )?.[0],
      )
    } else {
      setCurrentGame(
        listGame?.rows
          ?.filter((item) => item.start_date * 1000 < new Date().getTime())
          ?.sort((a, b) => (a.game_code > b.game_code ? -1 : 1))?.[0],
      )
    }
  }, [listGame])

  useEffect(() => {
    if (listGame.rows) {
      const arrayForSort = [...listGame.rows]
        ?.filter((item) => item.start_date * 1000 < new Date().getTime())
        ?.sort((a, b) => (a.game_code > b.game_code ? -1 : 1))

      setDayEnable(arrayForSort?.map((item) => item.game_code) || [])
    }
  }, [listGame])

  return (
    <div className="home-container fadeIn">
      <Banner currentGame={currentGame} day={day} setLoading={setLoading} loading={loading} />
      <LotteryResults
        setParamsGetGameDetail={setParamsGetGameDetail}
        gameDetail={gameDetail}
        listGame={listGame}
        currentGame={currentGame}
        dayEnable={dayEnable}
        day={day}
        setDay={setDay}
        loading={loading}
      />
      <HowToPlay />
    </div>
  )
}

Home.Layout = DefaultLayout

export default Home
