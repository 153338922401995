import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 28 29" fill="none" {...props}>
    <path
      d="M20.44 20.17H7.56C7.25025 20.17 7 20.4202 7 20.73V21.36C7 21.437 7.063 21.5 7.14 21.5H20.86C20.937 21.5 21 21.437 21 21.36V20.73C21 20.4202 20.7498 20.17 20.44 20.17ZM9.54975 18.7C9.58475 18.7 9.61975 18.6965 9.65475 18.6912L12.5982 18.175C12.6332 18.168 12.6665 18.1523 12.691 18.126L20.1093 10.7077C20.1255 10.6916 20.1383 10.6723 20.1471 10.6512C20.1559 10.63 20.1604 10.6073 20.1604 10.5844C20.1604 10.5615 20.1559 10.5388 20.1471 10.5176C20.1383 10.4964 20.1255 10.4772 20.1093 10.461L17.2008 7.55075C17.1675 7.5175 17.1237 7.5 17.0765 7.5C17.0293 7.5 16.9855 7.5175 16.9523 7.55075L9.534 14.969C9.50775 14.9952 9.492 15.0267 9.485 15.0617L8.96875 18.0052C8.95173 18.099 8.95781 18.1955 8.98647 18.2864C9.01514 18.3772 9.06552 18.4597 9.13325 18.5267C9.24875 18.6388 9.394 18.7 9.54975 18.7Z"
      fill="url(#paint0_linear_370_27034)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_370_27034"
        x1="7.56593"
        y1="14.9292"
        x2="21.0046"
        y2="10.3726"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42FD24" />
        <stop offset="1" stopColor="#13FFF1" stopOpacity="0.96" />
      </linearGradient>
    </defs>
  </Svg>
)

export default Icon
