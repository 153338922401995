import React from 'react'
import { Form } from 'antd'
import './styles.scss'

/**
 *
 * @param {*} FormItem
 * @param label
 * @param formType = input | checkbox | select
 */

const FormItem = ({ label, formType, children, ...props }) => (
  <Form.Item className={`form-item-${formType || 'input'}`} label={label} {...props}>
    {children}
  </Form.Item>
)

export default FormItem
