/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ControlInputSelect from 'components/ControlInputSelect'
import ButtonSubmit from 'widgets/ButtonSubmit'
import ButtonGradient from 'widgets/ButtonGradient'
import './styles.scss'
import { Col, Row } from 'antd'
import { BackIcon } from 'widgets/Svg'
import { toast } from 'react-toastify'
import { buyLottery, getGameDetailState, getListGame } from 'state/lottery/actions'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { getCurrency } from 'state/wallets/actions'
import CountUp from 'react-countup'
import { padLeadingZeros } from 'helpers/CommonHelper'
import Misc from 'helpers/Misc'
import { FormattedMessage, injectIntl } from 'react-intl'

const BuyLottery = ({ intl }) => {
  const dispatch = useDispatch()
  const bannerRef = useRef(null)

  const { currency } = useSelector((state) => state.wallets)
  const { listGame } = useSelector((state) => state.lottery)

  const [gameDetail, setGameDetail] = useState()
  const [selectCurrency, setSelectCurrency] = useState('')
  const [amount, setAmount] = useState(1)
  const [numberSelect, setNumberSelect] = useState({})
  const [numberSelectActive, setNumberSelectActive] = useState(0)
  const [view, setView] = useState(0)
  const [buyLoading, setBuyLoading] = useState(false)
  const [disabledBuy, setDisabledBuy] = useState(0)
  const [gameDetailNextDay] = useState()
  const [currentGame, setCurrentGame] = useState()

  const qtyNumberLottery = gameDetail?.gameType?.highest_number
  const qtyNumberLotteryBuy = gameDetail?.gameType?.max_number

  const optionCurrency = useMemo(() => {
    if (currency) {
      return currency.map((item) => ({
        label: item.title,
        value: item.code,
        type: item.type,
        currency_code: item.code,
        image: item.icon,
        _id: item._id,
      }))
    }
    return undefined
  }, [currency])

  const [paramsGameDetail, setParamsGameDetail] = useState({
    game_code: '',
  })

  const [paramsGameDetailNextDay] = useState({
    game_code: moment(new Date().getTime() + 86400000).format('YYYYMMDD'),
  })

  useEffect(() => {
    setParamsGameDetail({ game_code: currentGame?.game_code })
  }, [currentGame])

  useEffect(() => {
    dispatch(getListGame({ page: 1, pageSize: 100 }))
  }, [dispatch])

  useEffect(() => {
    setSelectCurrency(JSON.stringify(optionCurrency?.filter((item) => item._id === gameDetail?.currency)?.[0]))
  }, [gameDetail?.currency, optionCurrency])

  useEffect(() => {
    dispatch(
      getGameDetailState(
        paramsGameDetail,
        (data) => {
          setGameDetail(data)
          setDisabledBuy(0)
        },
        () => {
          setGameDetail(null)
          setDisabledBuy(2)
        },
      ),
    )
  }, [dispatch, paramsGameDetail])

  useEffect(() => {
    setCurrentGame(
      listGame?.rows?.filter(
        (item) => item.start_date * 1000 < new Date().getTime() && new Date().getTime() < item.end_date * 1000,
      )?.[0],
    )
  }, [listGame])

  const handlerChooseNumber = (number) => {
    const listNumber = numberSelect[numberSelectActive]

    if (listNumber?.indexOf(number) > -1) {
      listNumber.forEach((numberItemSelect, index) => {
        if (numberItemSelect === number) {
          listNumber.splice(index, 1)
        }
      })
    } else if (listNumber.length < qtyNumberLotteryBuy) {
      listNumber.push(padLeadingZeros(number))
    } else {
      toast.error(
        <>
          {intl.formatMessage({ id: 'you only select' })} {qtyNumberLotteryBuy} {intl.formatMessage({ id: 'number' })}
        </>,
      )
    }

    setNumberSelect((prev) => ({
      ...prev,
      [numberSelectActive]: listNumber,
    }))
  }

  const handleRandom = async () => {
    const min = 1
    const max = qtyNumberLottery
    const mapListNumber = {}

    const count = amount ?? 1
    for (let k = 0; k < count; k++) {
      let i = 0
      const listNumber = []

      while (i < qtyNumberLotteryBuy) {
        const rand = Math.floor(min + Math.random() * (max - min))
        const padZero = padLeadingZeros(rand)
        if (!listNumber.includes(padZero)) {
          listNumber.push(padZero)
          i++
        }
        // eslint-disable-next-line no-await-in-loop
        await Misc.sleep(20)
        mapListNumber[k] = listNumber
        setNumberSelect({ ...mapListNumber })
      }
    }
  }

  const onBuyTicketManual = () => {
    const lotteryBuy = []
    if (!amount || amount < 1) {
      toast.error(intl.formatMessage({ id: 'Enter Quantity' }))
      return false
    }

    // Check all number array is enough qtyNumber
    for (let i = 0; i < Object.keys(numberSelect).length; i++) {
      const item = numberSelect[i]
      if (item.length < qtyNumberLotteryBuy) {
        toast.error(
          `${intl.formatMessage({ id: 'you must select' })} ${qtyNumberLotteryBuy} ${intl.formatMessage({ id: 'number' })}`,
        )
        return false
      }
    }
    let k = 0
    while (lotteryBuy.length < amount) {
      lotteryBuy.push(`${numberSelect[k].toString().replace(/,/g, '-')}`)
      k++
    }
    const params = {
      game_id: gameDetail?._id,
      quantity: amount,
      lottery: lotteryBuy,
    }

    setBuyLoading(true)
    dispatch(
      buyLottery(
        params,
        () => {
          setNumberSelect([])
          dispatch(getCurrency({ page: 1, pageSize: 100 }))
          setBuyLoading(false)
        },
        () => {
          setBuyLoading(false)
        },
      ),
    )
  }

  const onBuyTicketAuto = () => {
    const params = {
      game_id: gameDetail?._id,
      quantity: amount,
      lottery: [],
    }
    setBuyLoading(true)
    dispatch(
      buyLottery(
        params,
        () => {
          setNumberSelect([])
          dispatch(getCurrency({ page: 1, pageSize: 100 }))
          setBuyLoading(false)
        },
        () => {
          setBuyLoading(false)
        },
      ),
    )
  }

  const onBuyTicket = () => {
    if (view === 1) {
      onBuyTicketManual()
    } else {
      onBuyTicketAuto()
    }
  }

  useEffect(() => {
    const currentTime = new Date()
    const currentTimeUtc = new Date(
      currentTime.getUTCFullYear(),
      currentTime.getUTCMonth(),
      currentTime.getUTCDate(),
      currentTime.getUTCHours(),
      currentTime.getUTCMinutes(),
      currentTime.getUTCSeconds(),
    ).getTime()

    const spaceTimeOfPrizeAndEnd =
      (gameDetail?.gameSource?.end_time?.split(':')[0] * 60 * 60 + gameDetail?.gameSource?.end_time?.split(':')[1] * 60) * 1000 -
      (gameDetail?.gameSource?.prize_announcement_time?.split(':')[0] * 60 * 60 +
        gameDetail?.gameSource?.prize_announcement_time?.split(':')[1] * 60) *
        1000

    const nextTimeLottoSpin = new Date(gameDetail?.end_date * 1000)
    const nextTimeLottoSpinUtc =
      new Date(
        nextTimeLottoSpin.getUTCFullYear(),
        nextTimeLottoSpin.getUTCMonth(),
        nextTimeLottoSpin.getUTCDate(),
        nextTimeLottoSpin.getUTCHours(),
        nextTimeLottoSpin.getUTCMinutes(),
        nextTimeLottoSpin.getUTCSeconds(),
      ).getTime() - spaceTimeOfPrizeAndEnd

    const nextTimeEnd = new Date(gameDetail?.end_date * 1000)
    const nextTimeEndUtc = new Date(
      nextTimeEnd.getUTCFullYear(),
      nextTimeEnd.getUTCMonth(),
      nextTimeEnd.getUTCDate(),
      nextTimeEnd.getUTCHours(),
      nextTimeEnd.getUTCMinutes(),
      nextTimeEnd.getUTCSeconds(),
    ).getTime()

    if (nextTimeLottoSpinUtc < currentTimeUtc && nextTimeEndUtc > currentTimeUtc) {
      setDisabledBuy(1)
    } else if (nextTimeEndUtc < currentTimeUtc) {
      dispatch(
        getGameDetailState(
          paramsGameDetailNextDay,
          (data) => {
            setGameDetail(data)
          },
          () => {
            setGameDetail(null)
            setDisabledBuy(2)
          },
        ),
      )
    }
  }, [disabledBuy, dispatch, gameDetail, paramsGameDetailNextDay])

  useEffect(() => {
    if (gameDetailNextDay) {
      setParamsGameDetail((prev) => ({
        ...prev,
        game_code: moment(new Date().getTime() + 86400000).format('YYYYMMDD'),
      }))

      setDisabledBuy(0)
    }
  }, [gameDetailNextDay])

  const handleImageLoad = () => {
    const scaleFactor = bannerRef.current.clientWidth / 990
    const height = bannerRef.current.clientHeight * (1 / scaleFactor)
    const width = bannerRef.current.clientWidth * (1 / scaleFactor)
    bannerRef.current.style.width = `${width}px`
    bannerRef.current.style.height = `${height}px`
    bannerRef.current.style.transform = `scale(${scaleFactor})`
    bannerRef.current.style.marginBottom = `-${height - height * scaleFactor}px`
  }

  return (
    <>
      <div className="wrapper-buy-lottery">
        <div className="buy-ticket-container-banner" ref={bannerRef}>
          <img src="/images/my-ticket/banner.png" alt="banner" onLoad={handleImageLoad} />
          <span style={{ display: 'flex' }}>
            <span>$</span>
            <CountUp end={gameDetail?.gameType?.total_reward / 10 ** 18} separator="," />
          </span>
        </div>

        <div className="buy-lottery-ticket">
          <p>
            <Link to="/">
              <BackIcon />
              <FormattedMessage id="Buy Lottery Ticket" />
            </Link>
          </p>

          <div className="buy-lottery-ticket-content">
            {disabledBuy !== 2 ? (
              <>
                <div className="buy-lottery-ticket-item">
                  <p>
                    <FormattedMessage id="Pay with" />
                  </p>

                  <div className="buy-lottery-ticket-item-content">
                    <div className="item-content-left">
                      <div className="box-select">
                        {optionCurrency && selectCurrency && (
                          <ControlInputSelect
                            dataSelect={optionCurrency}
                            value={selectCurrency}
                            onChange={setSelectCurrency}
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="item-content-right">
                      <p>
                        <FormattedMessage id="Balance" />
                      </p>
                      <p>
                        {currency?.filter(
                          (item) => item.code === (selectCurrency && JSON.parse(selectCurrency)?.currency_code),
                        )?.[0]?.balance /
                          10 ** 18}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="buy-lottery-ticket-item">
                  <p>
                    <FormattedMessage id="Quantity" />
                  </p>

                  <div className="buy-lottery-ticket-item-content">
                    <div className="item-content-left">
                      <div className="content-box-input">
                        <input type="number" value={amount} onChange={(e) => setAmount(+e.target.value)} />

                        <ul className="choose-amount">
                          <li className={amount === 10 ? 'active' : ''} onClick={() => setAmount(10)} role="presentation">
                            10
                          </li>
                          <li className={amount === 50 ? 'active' : ''} onClick={() => setAmount(50)} role="presentation">
                            50
                          </li>
                          <li className={amount === 100 ? 'active' : ''} onClick={() => setAmount(100)} role="presentation">
                            100
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="item-content-right">
                      <p>
                        <FormattedMessage id="Total cost" /> :
                      </p>
                      <p style={{ color: '#ECD400' }}>
                        {(gameDetail?.price / 10 ** 18) * amount} {selectCurrency && JSON.parse(selectCurrency)?.currency_code}
                      </p>
                    </div>
                  </div>

                  <p className="note">
                    {gameDetail?.price / 10 ** 18} {selectCurrency && JSON.parse(selectCurrency)?.currency_code}{' '}
                    <FormattedMessage id="per ticket" />
                  </p>
                </div>

                <div className="buy-lottery-ticket-item">
                  <div className="buy-lottery-ticket-action">
                    <Row
                      gutter={[
                        { xs: 8, sm: 16, md: 20, lg: 24 },
                        { xs: 24, sm: 30, md: 40, lg: 30 },
                      ]}
                      justify="center"
                      align="center"
                    >
                      <Col span={12}>
                        {view === 0 ? (
                          <ButtonGradient
                            className="buy-lottery-ticket-auto"
                            gradient="linear-gradient(to right, #A7FF65, #2DFF0B)"
                            background="#183216"
                            radius="15px"
                          >
                            <FormattedMessage id="Auto" />
                          </ButtonGradient>
                        ) : (
                          <button type="button" className="buy-lottery-ticket-manual" onClick={() => setView(0)}>
                            <FormattedMessage id="Auto" />
                          </button>
                        )}
                      </Col>
                      <Col span={12}>
                        {view === 1 ? (
                          <ButtonGradient
                            className="buy-lottery-ticket-auto"
                            gradient="linear-gradient(to right, #A7FF65, #2DFF0B)"
                            background="#183216"
                            radius="15px"
                          >
                            <FormattedMessage id="Manual" />
                          </ButtonGradient>
                        ) : (
                          <button type="button" className="buy-lottery-ticket-manual" onClick={() => setView(1)}>
                            <FormattedMessage id="Manual" />
                          </button>
                        )}
                      </Col>

                      {view === 1 ? (
                        <Col span={24}>
                          <div className="wrapper-choose-number">
                            <div className="wrapper-choose-number-header">
                              <p>
                                <FormattedMessage id="6 digits optional" />
                              </p>
                              <button type="button" onClick={handleRandom}>
                                <FormattedMessage id="Random" />
                              </button>
                            </div>
                            <div className="wrapper-choose-number-body">
                              <ul>
                                {[...Array(qtyNumberLottery)].map((x, i) => (
                                  <li key={i}>
                                    <p
                                      onClick={() => handlerChooseNumber(padLeadingZeros(i + 1))}
                                      role="presentation"
                                      className={
                                        numberSelect[numberSelectActive]
                                          ? numberSelect[numberSelectActive]?.indexOf(padLeadingZeros(i + 1)) > -1
                                            ? 'active'
                                            : ''
                                          : ''
                                      }
                                    >
                                      <span>{padLeadingZeros(i + 1)}</span>
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        ''
                      )}

                      <Col span={24} style={{ textAlign: 'center' }}>
                        <div className="wrap-list-number">
                          {Object.values(numberSelect).length !== 0 &&
                            view === 1 &&
                            Object.values(numberSelect).map((item, index) => (
                              <ul
                                className={`my-ticket ${index === numberSelectActive ? 'active' : ''}`}
                                key={`parent-${item}-${index}`}
                                onClick={() => setNumberSelectActive(index)}
                                role="presentation"
                              >
                                {new Array(qtyNumberLotteryBuy).fill(undefined).map((_, index) => (
                                  <li key={index}>{item?.[index] ? padLeadingZeros(item?.[index]) : '-'}</li>
                                ))}
                              </ul>
                            ))}
                        </div>

                        {disabledBuy === 1 ? (
                          <ButtonSubmit disabled style={{ width: '300px', maxWidth: '70%' }}>
                            <FormattedMessage id="Number Generator" />
                          </ButtonSubmit>
                        ) : disabledBuy === 2 ? (
                          <ButtonSubmit disabled style={{ width: '300px', maxWidth: '70%' }}>
                            <FormattedMessage id="End Of Sale" />
                          </ButtonSubmit>
                        ) : (
                          <ButtonSubmit loading={buyLoading} style={{ width: '300px', maxWidth: '70%' }} onClick={onBuyTicket}>
                            <FormattedMessage id="Buy Ticket" />
                          </ButtonSubmit>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            ) : (
              <div style={{ textAlign: 'center', minHeight: '300px', display: 'flex', alignItems: 'center' }}>
                <ButtonSubmit disabled style={{ width: '300px', maxWidth: '70%', margin: 'auto' }}>
                  <FormattedMessage id="Coming soon" />
                </ButtonSubmit>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default injectIntl(BuyLottery)
