import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'
import './style.scss'

const PrivateFooter = ({ links, location }) => (
  <div className="private-footer">
    {/* <ul>
      {links.map((item, index) => {
        const isHttp = item?.href?.startsWith('http')
        const Tag = isHttp ? 'a' : Link
        const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
        return (
          <li key={`menu-mb-${index}`} className={location.pathname === item.href ? 'active' : ''} role="presentation">
            <Tag {...propsLink} rel="noreferrer">
              {item.label}
            </Tag>
          </li>
        )
      })}
    </ul> */}

    <div className="private-footer-content">
      <Row gutter={[15, 24]}>
        <Col span={24} lg={{ span: 8 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="wrapper-logo-footer">
            <Link to="/">
              <img src="/images/logo-footer.png" alt="" />
            </Link>
            <p>
              <span>Lottery CeDeFi</span> is a community-based crypto lottery that offers their players the best online lottery
              experience possible!
            </p>
          </div>
        </Col>

        <Col span={24} lg={{ span: 8 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="wrapper-footer-center">
            <ul className="menu-footer">
              <li>
                <Link to="/">Promotions & Bonuses</Link>
              </li>
              <li>
                <Link to="/">FAQ</Link>
              </li>
              <li>
                <Link to="/">About Us</Link>
              </li>
              <li>
                <Link to="/">Contacs</Link>
              </li>
            </ul>

            <ul>
              <li>
                <a href="https://url.lotcedefi.io/WIWjikFF" target="_blank" rel="noreferrer">
                  <img src="/images/socials/twiter.png" alt="" />
                </a>
              </li>
              {/* <li>
                <a href="/" target="_blank" rel="noreferrer" >
                  <img src="/images/socials/fb.png" alt="" />
                </a>
              </li> */}
              <li>
                <a href="https://url.lotcedefi.io/AJrrOfmu" target="_blank" rel="noreferrer">
                  <img src="/images/socials/discord.png" alt="" />
                </a>
              </li>
              <li>
                <a href="https://url.lotcedefi.io/JxMuRnAo" target="_blank" rel="noreferrer">
                  <img src="/images/socials/telegram.png" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </Col>

        <Col span={24} lg={{ span: 8 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="wrapper-footer-right">
            <div className="wrapper-support-chat">
              <div className="support-chat">
                <p>Have a Questions ?</p>
                <p>Support Chat</p>
              </div>
            </div>

            <p>Accepted Currency</p>

            <ul>
              <li>
                <Link to="/">
                  <img src="/images/coins/btc.png" alt="" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="/images/coins/ethe.png" alt="" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="/images/coins/btc-blue.png" alt="" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="/images/coins/usdt.png" alt="" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="/images/coins/coin-5.png" alt="" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="/images/coins/dog.png" alt="" />
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col span={24} className="footer-version">
          <p>Version 1.0.0</p>
        </Col>
      </Row>
    </div>
  </div>
)

export default PrivateFooter
