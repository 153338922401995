import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { ToastContainer } from 'react-toastify'
import 'antd/dist/antd.css'
import 'react-toastify/dist/ReactToastify.css'
import './assets/css/global.scss'
import { languages } from 'language/config'
import { PrivateLayout } from 'layouts'
import { getAuthToken } from 'helpers/auth'
import fetchHelper from 'helpers/FetchHelper'
import SignIn from 'modules/auth/pages/Signin'
import SignUp from 'modules/auth/pages/Signup'
import ForgotPassword from 'modules/auth/pages/ForgotPassword'
import ResetPassword from 'modules/auth/pages/ResetPassword'
import Page404 from 'modules/404'
import ComingSoon from 'modules/comingSoon'
import Home from 'modules/home'
import Account from 'modules/account'
import Wallet from 'modules/wallet'
import AffiliateProgram from 'modules/AffiliateProgram'
import Security from 'modules/security'
import PrimaryKYC from 'modules/primaryKYC'
import Withdraw from 'modules/withdraw'
import VerifyCode from 'modules/auth/pages/VerifyCode'
import NewPassword from 'modules/auth/pages/NewPassword'
import Deposit from 'modules/deposit'
import BuyLottery from 'modules/buy-lottery'
import MyTicket from 'modules/my-ticket'
import { getProfile } from 'state/user/actions'
import { getStaticData, getListCountry } from 'state/app/actions'
import { getChainList, getCurrency, getWalletAddress } from 'state/wallets/actions'
import History from 'modules/wallet-history'
import AccountActivity from 'modules/AccountActivity'
import RedirectTo from 'RedirectTo'

const isLogin = () => {
  const authToken = getAuthToken()
  if (authToken) {
    fetchHelper.addDefaultHeader('Authorization', `Bearer ${authToken}`)
  }
  return Boolean(authToken)
}

const DefaultRoute = ({ component: Component, ...rest }) => {
  const execPath = rest.location.search?.startsWith('?sponsorKey=') || rest.location.search?.startsWith('?sponsorkey=')
  const Layout = PrivateLayout
  return (
    <Route
      {...rest}
      render={(props) =>
        execPath ? (
          <Redirect to={{ ...rest.location, pathname: '/register', state: { from: props.location } }} />
        ) : (
          <Layout {...rest} isLogin={isLogin()}>
            <Component {...props} />
          </Layout>
        )
      }
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }) => {
  const Layout = Component.Layout || PrivateLayout
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLogin() ? (
          <Layout {...rest} isLogin={isLogin()}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: '/buy-ticket', state: { from: props.location } }} />
        )
      }
    />
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const Layout = Component.Layout || PrivateLayout
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <Layout {...rest} isLogin={isLogin()}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

if (process.env.NODE_ENV === 'production') {
  window.console.log = () => {}
  window.console.warn = () => {}
}

function App() {
  const dispatch = useDispatch()
  const { language } = useSelector((state) => state.app)
  // useConfigRecaptcha()
  const hadValidToken = isLogin()

  useEffect(() => {
    if (hadValidToken) {
      dispatch(getChainList())
      dispatch(getListCountry())
      dispatch(getProfile())
      dispatch(getStaticData())
    }
  }, [dispatch, hadValidToken])

  /**
   * Fetch wallet
   */
  useEffect(() => {
    if (hadValidToken) {
      dispatch(getCurrency({ page: 1, pageSize: 100 }))
      dispatch(getWalletAddress({ page: 1, pageSize: 100 }))
    }
  }, [dispatch, hadValidToken])

  return (
    <>
      <ToastContainer />
      <IntlProvider locale={language || 'en'} messages={languages[language]}>
        <Router>
          <Switch>
            {/* All */}
            <DefaultRoute exact path="/" component={Home} />
            <DefaultRoute exact path="/404" component={Page404} />
            <DefaultRoute exact path="/coming-soon" component={ComingSoon} />

            {/* Public */}
            <PublicRoute exact path="/register" component={SignUp} />
            <PublicRoute exact path="/login" component={SignIn} />
            <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
            <PublicRoute exact path="/reset-password" component={ResetPassword} />
            <PublicRoute exact path="/verify-code" component={VerifyCode} />
            <PublicRoute exact path="/new-password" component={NewPassword} />

            {/* Private */}
            <PrivateRoute exact path="/profile" component={Account} />
            <PrivateRoute exact path="/wallet" component={Wallet} />
            <PrivateRoute exact path="/affiliate-program" component={AffiliateProgram} />
            <PrivateRoute exact path="/wallet/deposit/:id" component={Deposit} />
            <PrivateRoute exact path="/wallet/withdraw/:id" component={Withdraw} />
            <PrivateRoute exact path="/wallet/history" component={History} />
            {/* <PrivateRoute exact path="/stake" component={Stake} /> */}
            {/* <PrivateRoute exact path="/stake/stake-history/:id" component={StakingHistory} /> */}
            <PrivateRoute exact path="/security" component={Security} />
            <PrivateRoute exact path="/security/primary-kyc" component={PrimaryKYC} />
            <PrivateRoute exact path="/security/account-activity" component={AccountActivity} />
            <PrivateRoute exact path="/buy-ticket" component={BuyLottery} />
            <PrivateRoute exact path="/my-ticket" component={MyTicket} />

            {/* Other */}
            <Route component={RedirectTo} />
          </Switch>
        </Router>
      </IntlProvider>
    </>
  )
}

export default App
