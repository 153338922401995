import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import './styles.scss'
import ButtonSecondary from 'widgets/ButtonSecondary'
import ModalTurnOn2FA, { MODAlTYPE } from 'components/ModalTurnOn2FA'
import { create2FA } from 'state/auth/actions'
import ButtonDisable from 'widgets/ButtonDisable'
import { FormattedMessage } from 'react-intl'

const SECURITY_TYPE = {
  EMAIL: 'email',
  AUTHY: 'authy',
}

const Security = () => {
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.user)
  const [modal2FA, setModal2FA] = useState({ toggle: false, dataModal: null, type: '' })
  const handleTurnOnTypeVerify = (type) => {
    switch (type) {
      case SECURITY_TYPE.EMAIL:
        break
      case SECURITY_TYPE.AUTHY:
        if (userInfo.status_2fa === 0) {
          dispatch(
            create2FA(
              (receipt) => {
                setModal2FA({ toggle: true, dataModal: receipt, type: MODAlTYPE.VERIFY })
              },
              (e) => {
                toast.error(e?.message || 'Has an error, Please try again late.')
              },
            ),
          )
        } else {
          setModal2FA({ toggle: true, dataModal: {}, type: MODAlTYPE.UPDATE })
        }
        break
      default:
        break
    }
  }

  const handleDisableAccount = () => {
    // Soon
  }

  return (
    <div className="warp-page-security">
      <div className="page-security-heading">
        {window.innerWidth >= 768 && (
          <h2>
            <FormattedMessage id="Security" />
          </h2>
        )}
      </div>
      <div className="page-security-content">
        <div className="page-security-content-body">
          <div className="security-item">
            <p>
              <FormattedMessage id="Email Verification" />
              <ButtonSecondary
                className="btn-verify"
                hoverAble={false}
                style={{
                  width: window.innerWidth >= 768 ? '118px' : '90px',
                  height: window.innerWidth >= 768 ? '42px' : '35px',
                }}
              >
                Default
              </ButtonSecondary>
            </p>
            <p>Increase your password strength to enhance account security</p>
          </div>

          <div className="security-item">
            <p>
              Lottery CeDeFi Wallet/Google Authenticator
              {/* 0: chưa 1: active 2: disable */}
              {userInfo.status_2fa === 1 ? (
                <ButtonDisable
                  className="btn-disable"
                  style={{
                    width: window.innerWidth >= 768 ? '118px' : '90px',
                    height: window.innerWidth >= 768 ? '42px' : '35px',
                  }}
                  onClick={() => handleTurnOnTypeVerify(SECURITY_TYPE.AUTHY)}
                >
                  <FormattedMessage id="Disable" />
                </ButtonDisable>
              ) : (
                <ButtonSecondary
                  className="btn-verify"
                  style={{
                    width: window.innerWidth >= 768 ? '118px' : '90px',
                    height: window.innerWidth >= 768 ? '42px' : '35px',
                  }}
                  onClick={() => handleTurnOnTypeVerify(SECURITY_TYPE.AUTHY)}
                >
                  <FormattedMessage id="Verify" />
                </ButtonSecondary>
              )}
            </p>
            <p>
              <FormattedMessage id="Set up your Lottery CeDeFi Wallet /Google Authenticator to provide an extra security" />
            </p>
          </div>

          <div className="security-item">
            <p>
              <FormattedMessage id="Primary KYC" />
              {userInfo.kycInfo ? (
                <Link to="#">
                  <ButtonSecondary
                    className="btn-verify"
                    hoverAble={false}
                    style={{
                      width: window.innerWidth >= 768 ? '118px' : '90px',
                      height: window.innerWidth >= 768 ? '42px' : '35px',
                    }}
                  >
                    <FormattedMessage id="Actived" />
                  </ButtonSecondary>
                </Link>
              ) : (
                <Link to="/security/primary-kyc">
                  <ButtonSecondary
                    className="btn-verify"
                    style={{
                      width: window.innerWidth >= 768 ? '118px' : '90px',
                      height: window.innerWidth >= 768 ? '42px' : '35px',
                    }}
                  >
                    <FormattedMessage id="Verify" />
                  </ButtonSecondary>
                </Link>
              )}
            </p>
            <p>
              <FormattedMessage id="Increase your 24-hour withdrawal unlimited" />
            </p>
          </div>
          <div className="security-item">
            <p>
              <FormattedMessage id="Account Activity" />
              <Link to="/security/account-activity">
                <ButtonSecondary
                  className="btn-verify"
                  style={{
                    width: window.innerWidth >= 768 ? '118px' : '90px',
                    height: window.innerWidth >= 768 ? '42px' : '35px',
                  }}
                >
                  <FormattedMessage id="View" />
                </ButtonSecondary>
              </Link>
            </p>
            <p>
              <FormattedMessage id="Last login" />: 2022-03-18 09:21{' '}
            </p>
          </div>

          <div className="security-item">
            <p>
              <FormattedMessage id="Disable Account" />
              <ButtonSecondary
                className="btn-disable"
                style={{
                  width: window.innerWidth >= 768 ? '118px' : '90px',
                  height: window.innerWidth >= 768 ? '42px' : '35px',
                }}
                hoverAble={false}
                onClick={handleDisableAccount}
              >
                <FormattedMessage id="Soon" />
              </ButtonSecondary>
            </p>
            <p>
              <FormattedMessage id="Disable your account immediately" />
            </p>
          </div>
        </div>
      </div>
      <ModalTurnOn2FA
        visible={modal2FA.toggle}
        dataModal={modal2FA.dataModal}
        modalType={modal2FA.type}
        onCancel={() => setModal2FA({ toggle: false, dataModal: null })}
      />
    </div>
  )
}

export default Security
