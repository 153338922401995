import { Col, Row } from 'antd'
import './style.scss'
import InputCopyRef from 'components/InputCopyRef'
import { DOMAIN } from 'constants/index'
import { FormattedMessage } from 'react-intl'

export default function ReferrerCard({ className, userInfo }) {
  const codeSponsor = userInfo?.ref_code
  const link = `${DOMAIN}?sponsorKey=${userInfo?.ref_code}`
  return (
    <div className={`wrap-card-ref ${className}`}>
      <div className="box-info-sponserkey">
        <div className="password-left">
          <Row gutter={[25, 25]} style={{ marginBottom: 0 }}>
            <Col className="networks-ref" xs={24} sm={12}>
              <InputCopyRef code={codeSponsor} title={<FormattedMessage id="Referrals Code" />} />
            </Col>
            <Col className="networks-ref" xs={24} sm={12}>
              <InputCopyRef code={link} title={<FormattedMessage id="Referrals Link" />} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

ReferrerCard.defaultProps = {
  className: '',
}
