import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ButtonSubmit from 'widgets/ButtonSubmit'
import './styles.scss'
import { Form } from 'antd'
import FormItem from 'widgets/FormItem'
import AuthCard from '../AuthCard'
import { forgotPassword, requestForgotPassword } from 'state/auth/actions'
import InputCode from 'components/InputCode'
import { useHistory, Link } from 'react-router-dom'
import Input from 'widgets/Input'
import { ArrowBackIcon } from 'widgets/Svg'
import { FormattedMessage, injectIntl } from 'react-intl'

const defaultTimeCountDown = 50

const VerifyResetPasswordCode = ({
  intl,
  setView,
  email,
  initialField,
  onFinish,
  children,
  title,
  timeresend,
  settimeresend,
  ...props
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [timeCountDown, setTimeCountDown] = useState(defaultTimeCountDown)

  const onSubmit = () => {
    form.validateFields().then((values) => {
      if (codeValue.length < initialField) {
        setErrorMess(
          <>
            <FormattedMessage id="Please enter" /> {initialField} <FormattedMessage id="characters from email of you." />
          </>,
        )
      } else {
        setErrorMess('')
        setLoading(true)
        dispatch(
          forgotPassword(
            {
              ...values,
              email: `${email}`,
              code: codeValue,
            },
            () => {
              history.push('/login')
              setLoading(false)
            },
            () => {
              setLoading(false)
            },
          ),
        )
      }
    })
  }

  const onChangeCode = (value) => {
    setCodeValue(value)
  }

  const resendCode = () => {
    dispatch(
      requestForgotPassword(
        {
          email: `${email}`,
        },
        () => {
          if (settimeresend) {
            settimeresend(defaultTimeCountDown)
            setTimeCountDown(defaultTimeCountDown)
          } else {
            setTimeCountDown(defaultTimeCountDown)
          }
        },
      ),
    )
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  useEffect(() => {
    if (timeresend && timeresend > 0) {
      setTimeCountDown(timeresend)
    }
  }, [timeresend])

  return (
    <div className="form-verify-forgot fadeIn">
      <AuthCard className="verify-code fadeIn" {...props}>
        <img src="/images/icons/fotgot-password.svg" alt="" />

        <Link to="/">
          <ArrowBackIcon />
        </Link>

        <h2 className="verify-title">{title ? `${title}` : 'Reset Password'}</h2>
        <Form layout="vertical" form={form}>
          <FormItem
            label={
              <span>
                <FormattedMessage id="Enter your new password" />
              </span>
            }
            name="new_password"
            rules={[
              {
                required: true,
                message: <FormattedMessage id="Please enter password field" />,
              },
              {
                min: 10,
                message: (
                  <FormattedMessage id={`The 'password' field length must be greater than or equal to 10 characters long.`} />
                ),
              },
            ]}
          >
            <Input placeholder="************" type="password" />
          </FormItem>

          <FormItem
            label={
              <span>
                <FormattedMessage id="Confirm Password" />
              </span>
            }
            name="confirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: <FormattedMessage id="Please confirm your password!" />,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(intl.formatMessage({ id: 'The two passwords that you entered do not match!' })))
                },
              }),
            ]}
          >
            <Input placeholder="**********" type="password" />
          </FormItem>
          {/* <div className="verify-error">{errorMess}</div> */}
          <div className="verify-input">
            {/* <p>Verification code</p> */}
            <InputCode
              type="number"
              value={codeValue}
              initialField={initialField}
              hasErrored={Boolean(errorMess)}
              onChange={onChangeCode}
            />
          </div>
          <div className="verify-resend">
            <div className="time-count" style={{ opacity: timeCountDown > 0 ? '1' : '0' }}>
              <FormattedMessage id="Resend in" /> {timeCountDown}s
            </div>
            {timeCountDown <= 0 && (
              <a onClick={() => resendCode()} role="presentation">
                <FormattedMessage id="Resend" />
              </a>
            )}
          </div>

          <div className="verify-error">{errorMess}</div>
          <div
            className="verify-action"
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <ButtonSubmit
              loading={loading}
              onClick={onSubmit}
              style={{
                color: history.location.pathname === '/register' ? '#000' : '#fff',
                with: '100%',
              }}
            >
              <FormattedMessage id="Done" />
            </ButtonSubmit>
            {/* <p>
                Or login via <a href="/verify-code">Google Authenticator</a>
              </p> */}
          </div>
        </Form>
      </AuthCard>
    </div>
  )
}

VerifyResetPasswordCode.defaultProps = {
  onFinish: () => null,
  initialField: 6,
}

export default injectIntl(React.memo(VerifyResetPasswordCode))
