/* eslint-disable react/destructuring-assignment */
import React from 'react'
import './styles.scss'
import { Table, Pagination } from 'antd'
import { CONTRACT_NFT } from 'constants/index'
import { padLeadingZeros } from 'helpers/CommonHelper'
import { FormattedMessage } from 'react-intl'

const TableWinner = ({ dataSource, gameResult }) => {
  const columns = [
    {
      title: <FormattedMessage id="Customer id" />,
      dataIndex: 'customer_id',
      key: 'customer_id',
      width: window.innerWidth > 991 ? 250 : 150,
    },
    {
      title: <FormattedMessage id="Ticket" />,
      dataIndex: 'lottery_detail',
      key: 'lottery_detail',
      render: (text) => (
        <div className="ticket-current">
          {text?.lottery?.split('-')?.map((item, index) => (
            <p key={`ticket-current-${index}`} className={`${gameResult?.indexOf(padLeadingZeros(item)) > -1 && 'active'}`}>
              {padLeadingZeros(item)}
            </p>
          ))}
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Matches" />,
      dataIndex: 'max_number',
      key: 'max_number',
      // eslint-disable-next-line no-unneeded-ternary
      render: (text) => text,
    },
    {
      title: <FormattedMessage id="Prizes" />,
      dataIndex: 'total_prize',
      key: 'total_prize',
      // eslint-disable-next-line no-unneeded-ternary
      render: (text) => `${text / 10 ** 18}$`,
    },
    {
      title: <FormattedMessage id="NFT ID" />,
      dataIndex: 'lottery_detail',
      key: 'lottery_detail',
      // eslint-disable-next-line no-unneeded-ternary
      render: (text) => (
        <a target="_blank" rel="noreferrer" href={`https://polygonscan.com/token/${CONTRACT_NFT}?a=${text?.nft_id}`}>
          {text?.nft_id}
        </a>
      ),
    },
  ]

  return (
    <div className="table-winner-list">
      {dataSource?.length > 0 && <img className="winner" src="/images/icons/crown.png" alt="" />}
      <Table rowKey={(record) => record._id} dataSource={dataSource} columns={columns} pagination={false} />
      {/* <Pagination total={100} showSizeChanger={false} /> */}
    </div>
  )
}

export default TableWinner
