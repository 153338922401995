export const socials = [
  {
    id: 1,
    label: 'Telegram',
    link_to: 'https://beurl.app/zodi-telegram',
    target: '_blank',
    img_url: '/images/socials/tele.png',
  },
  {
    id: 1,
    label: 'Twitter',
    link_to: 'https://beurl.app/zodi-twitter',
    target: '_blank',
    img_url: '/images/socials/twiter.png',
  },
  {
    id: 1,
    label: 'Facebook',
    link_to: 'https://beurl.app/zodi-facebook',
    target: '_blank',
    img_url: '/images/socials/fb.png',
  },
  {
    id: 1,
    label: 'Youtube',
    link_to: 'https://beurl.app/zodi-youtube',
    target: '_blank',
    img_url: '/images/socials/youtube.png',
  },
]

export const link2 = null
