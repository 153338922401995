import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.9828 2.10547C10.6313 2.10547 3.05859 9.65073 3.05859 18.9476C3.05859 28.2444 10.6313 35.7897 19.9828 35.7897C29.3513 35.7897 36.9409 28.2444 36.9409 18.9476C36.9409 9.65073 29.3513 2.10547 19.9828 2.10547ZM31.7231 12.2107H26.7254C26.1952 10.1248 25.4101 8.11119 24.3875 6.21494C27.4778 7.27263 30.0818 9.401 31.7231 12.2107ZM19.9998 5.54126C21.4059 7.56231 22.5071 9.80231 23.2355 12.2107H16.764C17.4925 9.80231 18.5937 7.56231 19.9998 5.54126ZM6.8873 22.316C6.61624 21.2381 6.44683 20.1097 6.44683 18.9476C6.44683 17.7855 6.61624 16.657 6.8873 15.5792H12.6134C12.4779 16.6907 12.3762 17.8023 12.3762 18.9476C12.3762 20.0928 12.4779 21.2044 12.6134 22.316H6.8873ZM8.27648 25.6844H13.2741C13.8162 27.7897 14.5955 29.8107 15.612 31.6802C12.5185 30.6283 9.91271 28.4984 8.27648 25.6844ZM13.2741 12.2107H8.27648C9.91271 9.39672 12.5185 7.26685 15.612 6.21494C14.5895 8.11119 13.8043 10.1248 13.2741 12.2107ZM19.9998 32.3539C18.5937 30.3328 17.4925 28.0928 16.764 25.6844H23.2355C22.5071 28.0928 21.4059 30.3328 19.9998 32.3539ZM23.964 22.316H16.0355C15.8831 21.2044 15.7645 20.0928 15.7645 18.9476C15.7645 17.8023 15.8831 16.6739 16.0355 15.5792H23.964C24.1165 16.6739 24.2351 17.8023 24.2351 18.9476C24.2351 20.0928 24.1165 21.2044 23.964 22.316ZM24.3875 31.6802C25.404 29.8107 26.1833 27.7897 26.7254 25.6844H31.7231C30.0818 28.4941 27.4778 30.6225 24.3875 31.6802ZM27.3861 22.316C27.5217 21.2044 27.6233 20.0928 27.6233 18.9476C27.6233 17.8023 27.5217 16.6907 27.3861 15.5792H33.1122C33.3833 16.657 33.5527 17.7855 33.5527 18.9476C33.5527 20.1097 33.3833 21.2381 33.1122 22.316H27.3861Z"
      fill="#FFE288"
    />
  </Svg>
)

export default Icon
