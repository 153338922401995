import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ButtonSubmit from 'widgets/ButtonSubmit'
import './styles.scss'
import AuthCard from '../AuthCard'
import { verifyAuthenActiveAccountCode, resendAuthenActiveAccountCode } from 'state/auth/actions'
import InputCode from 'components/InputCode'
import { FormattedMessage } from 'react-intl'

const defaultTimeCountDown = 50

const VerifyActiveAccountCode = ({ email, initialField, token, onFinish, children, ...props }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [timeCountDown, setTimeCountDown] = useState(defaultTimeCountDown)

  const onSubmit = () => {
    if (codeValue.length < initialField) {
      setErrorMess(
        <>
          <FormattedMessage id="Please enter" /> {initialField} <FormattedMessage id="characters from email of you." />
        </>,
      )
    } else {
      setErrorMess('')
      setLoading(true)
      dispatch(
        verifyAuthenActiveAccountCode(
          {
            email: `${email}`,
            code: codeValue,
          },
          () => {
            history.push('/login')
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        ),
      )
    }
  }

  const onChangeCode = (value) => {
    if (value.length >= initialField) {
      setErrorMess('')
    }
    setCodeValue(value)
  }

  const resendCode = () => {
    dispatch(
      resendAuthenActiveAccountCode(
        {
          email: `${email}`,
        },
        () => {
          setTimeCountDown(defaultTimeCountDown)
        },
      ),
    )
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  return (
    <AuthCard className="verify-code fadeIn" {...props} style={{ height: 'fit-content' }}>
      <div style={{ textAlign: 'center', marginBottom: '32px' }}>
        <img src="/images/dice.png" style={{ maxWidth: '35%' }} alt="Verification code" />
      </div>

      <h2 className="verify-title">
        <FormattedMessage id="Verification code" />
      </h2>
      <div className="verify-input">
        <InputCode value={codeValue} initialField={initialField} hasErrored={Boolean(errorMess)} onChange={onChangeCode} />
      </div>
      <div className="verify-resend">
        <div style={{ opacity: timeCountDown > 0 ? '1' : '0' }}>
          <FormattedMessage id="Resend in" /> {timeCountDown}s
        </div>
        {timeCountDown <= 0 && (
          <a onClick={resendCode} role="presentation">
            <FormattedMessage id="Resend" />
          </a>
        )}
      </div>
      <div className="verify-error">{errorMess}</div>
      <div className="verify-action">
        <ButtonSubmit style={{ borderRadius: '35px', width: '150px' }} loading={loading} onClick={onSubmit}>
          <FormattedMessage id="Done" />
        </ButtonSubmit>
        {/* <ButtonSecondary onClick={onBack}>Back</ButtonSecondary> */}
        {/* <p>
          Or login via <a href="/verify-code">Google Authenticator</a>
        </p> */}
      </div>
    </AuthCard>
  )
}

VerifyActiveAccountCode.defaultProps = {
  initialField: 6,
  onFinish: () => null,
  // onBack: () => null,
}

export default React.memo(VerifyActiveAccountCode)
