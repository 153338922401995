import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Input } from 'antd'
import './style.scss'
import { CopyIcon } from 'widgets/Svg'
import { FormattedMessage } from 'react-intl'

const Index = ({ className, title, value, disabled, accessCopy = true, link }) => {
  const [copySuccess, setCopySuccess] = useState(false)

  const onCopy = () => {
    if (disabled || !accessCopy) return
    setCopySuccess(true)
    setTimeout(() => {
      setCopySuccess(false)
    }, 5000)
  }

  // if (!value) return <></>;
  return (
    <>
      <div className={`input-coppy-wrapper ${className}`}>
        <div className="style-input-title">{title && <h3 className="title">{title}</h3>}</div>
        <CopyToClipboard onCopy={onCopy} text={value}>
          <div className={`style-input-copy ${disabled ? 'not-allowed' : ''}`}>
            <div className="unit">
              <div className="wrapper-input">
                <Input
                  readOnly
                  suffix={
                    <p>
                      <FormattedMessage id="copy" />
                    </p>
                  }
                  value={value || 'null'}
                  // height={10}
                />
                {copySuccess && (
                  <div className="copy-success">
                    <FormattedMessage id="Success" />
                  </div>
                )}
                {link && <Link className="link" to={link} />}
              </div>
            </div>
          </div>
        </CopyToClipboard>
      </div>
    </>
  )
}

export default Index
