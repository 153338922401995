import './styles.scss'
import React from 'react'
import { Table } from 'antd'

const RecentTransactionDesktop = ({ columns, dataSource, ...props }) => (
  <Table
    scroll={{ y: window.innerWidth < 768 ? 'max-content' : 304, x: 'max-content' }}
    className="hisotry-recent-transaction-table-desktop"
    columns={columns}
    dataSource={dataSource}
    {...props}
  />
)

export default RecentTransactionDesktop
