import React, { useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, DatePicker, Space } from 'antd'
import { debounce } from 'lodash'
import './styles.scss'
import BackLink from 'components/BackLink'
import { removeEmpty } from 'helpers/CommonHelper'
import RecentTransaction from 'components/History/recentTransaction'
import { getTransactionList } from 'state/transactions/actions'
import ControlInputText from 'components/ControlInputText'
import ControlInputSelect from 'components/ControlInputSelect'
import { FormattedMessage, injectIntl } from 'react-intl'

const { RangePicker } = DatePicker

const Form = ({ intl }) => {
  const dispatch = useDispatch()
  const { staticData } = useSelector((state) => state.app)
  const { chainList, currency } = useSelector((state) => state.wallets)
  const { rows, total } = useSelector((state) => state.transactions.transactionsList)

  const optionCurrency = useMemo(() => {
    if (currency) {
      return currency.map((item) => ({
        label: item.title,
        value: item.code,
        currency_code: item.code,
        image: item.icon,
      }))
    }
    return undefined
  }, [currency])

  const optionChain = useMemo(() => {
    if (chainList) {
      return chainList.map((item) => ({
        label: item.title,
        value: item.chainid,
        chain_code: item.code,
      }))
    }
    return undefined
  }, [chainList])

  const optionActions = useMemo(() => {
    if (staticData) {
      return (
        Object.values(staticData.actionTransaction)
          .filter(
            (item) =>
              item !== 'STAKE' &&
              item !== 'UNSTAKE' &&
              item !== 'FEE' &&
              item !== 'COMMISSION_STAKE' &&
              item !== 'REVERSE' &&
              item !== 'INTEREST' &&
              item !== 'BUY',
          )
          // eslint-disable-next-line array-callback-return
          .map((value) => ({ label: value, value }))
      )
    }
    return undefined
  }, [staticData])

  /**
   * Fetch txh history
   */
  const [txParams, setTxParams] = useState({
    page: 1,
    pageSize: 10,
    currency: undefined,
    chain_code: '',
    txhash: '',
    type: '',
    from_date: '',
    to_date: '',
  })
  useEffect(() => {
    dispatch(getTransactionList(removeEmpty(txParams)))
  }, [dispatch, txParams])

  const handleChangeTxh = debounce((value) => {
    setTxParams((prev) => ({ ...prev, txhash: value }))
  }, 300)

  return (
    <div className="warp-history-form">
      <div className="go-back">
        <BackLink label={<FormattedMessage id="History" />} to="/wallet" />
      </div>

      <div className="history-form">
        <Row gutter={[16, 16]} className="history-form-top">
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <div className="box-history-title">
              <p>
                <FormattedMessage id="Transaction Txh" />
              </p>
              <ControlInputText placeholder={intl.formatMessage({ id: 'Search Txh' })} onChange={handleChangeTxh} />
            </div>
          </Col>
        </Row>
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 32, lg: 32 },
            { xs: 8, sm: 16, md: 32, lg: 32 },
          ]}
          className="history-form-filter"
        >
          <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 5 }}>
            <div className="box-history-title">
              <ControlInputSelect
                title={<FormattedMessage id="Network" />}
                showAll="All network"
                defaultValue=""
                dataSelect={optionChain}
                onChange={(value) => setTxParams((prev) => ({ ...prev, chain_code: value ? JSON.parse(value).chain_code : '' }))}
              />
            </div>
          </Col>
          <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 5 }}>
            <div className="box-history-title">
              <ControlInputSelect
                title={<FormattedMessage id="Currency" />}
                showAll="All currency"
                defaultValue=""
                dataSelect={optionCurrency}
                onChange={(value) => setTxParams((prev) => ({ ...prev, currency: value ? JSON.parse(value).currency_code : '' }))}
              />
            </div>
          </Col>
          <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 5 }}>
            <div className="box-history-title">
              <ControlInputSelect
                title={<FormattedMessage id="Actions" />}
                showAll="All action"
                defaultValue=""
                dataSelect={optionActions}
                onChange={(value) => setTxParams((prev) => ({ ...prev, type: value ? JSON.parse(value).value : '' }))}
              />
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9 }}>
            <div className="box-history-title">
              <p>
                <FormattedMessage id="Date" />
              </p>
              <Space direction="vertical" style={{ width: '100%' }}>
                <RangePicker
                  placeholder={['1 Jan 2021', '3 May 2022']}
                  style={{ background: 'transparent', borderColor: '#34384C', borderRadius: '8px' }}
                  suffixIcon
                  onChange={(value) => {
                    setTxParams((prev) => ({
                      ...prev,
                      from_date: value ? (new Date(value[0].toString()).getTime() - 1000 * 60 * 60 * 7) / 1000 : '',
                      to_date: value ? (new Date(value[1].toString()).getTime() - 1000 * 60 * 60 * 7) / 1000 : '',
                    }))
                  }}
                />
              </Space>
            </div>
          </Col>
        </Row>

        {/* <DepositSuccessView dataEnd={rows?.[0]} />  */}

        <RecentTransaction txParams={txParams} rows={rows} total={total} setTxParams={setTxParams} />
      </div>
    </div>
  )
}

export default injectIntl(Form)
