import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 40 40" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 7.50014C17.5002 7.23945 17.419 6.98521 17.2676 6.773C17.1161 6.56079 16.9022 6.40123 16.6556 6.31665C16.409 6.23207 16.1421 6.22669 15.8924 6.30128C15.6426 6.37588 15.4223 6.5267 15.2625 6.73264L6.51251 17.9826C6.34182 18.2021 6.24915 18.4721 6.24915 18.7501C6.24915 19.0281 6.34182 19.2982 6.51251 19.5176L15.2625 30.7676C15.4223 30.9736 15.6426 31.1244 15.8924 31.199C16.1421 31.2736 16.409 31.2682 16.6556 31.1836C16.9022 31.0991 17.1161 30.9395 17.2676 30.7273C17.419 30.5151 17.5002 30.2608 17.5 30.0001V25.0126C24.2238 25.1526 27.4538 26.4289 29.0763 27.8164C30.6188 29.1351 30.9013 30.6864 31.195 32.3114L31.2713 32.7301C31.3274 33.0298 31.491 33.2986 31.7314 33.486C31.9719 33.6734 32.2725 33.7665 32.5768 33.7478C32.881 33.729 33.1679 33.5998 33.3836 33.3843C33.5992 33.1688 33.7286 32.8819 33.7475 32.5776C33.9613 29.1426 33.64 24.1651 31.2138 20.0014C28.8588 15.9601 24.6175 12.8526 17.5 12.5276V7.50014Z"
      fill="white"
    />
  </Svg>
)

export default Icon
