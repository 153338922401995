import React, { useEffect, useState } from 'react'
import DrawPieChartItem from './DrawPieChartItem'
import { Row, Col } from 'antd'
import { SubItemChartIcon } from 'widgets/Svg'
import './styles.scss'

/**
 * Custom draw chart
 * data param :
  const fakeDataChart = [
    {
      currency: 'BTC',
      percent: 50,
      balance: 127,
      isActive: true,
    },
    {
      currency: 'BNB',
      percent: 50,
      balance: 127,
      isActive: false,
    },
  ]
*/

const DrawPieChart = ({ dataChart, ...props }) => {
  const colorChart = [
    {
      color: '#EC8E00',
    },
    {
      color: '#3E10E4',
    },
    {
      color: '#FAFF00',
    },
    {
      color: '#00C32B',
    },
    {
      color: '#D4D4D4',
    },
  ]

  const [transformItemChart, setTransformItemChart] = useState([-90])
  const [itemWidthCircle, setItemWidthCircle] = useState([])
  const [textPercent, setTextPercent] = useState(100)
  const [textPercentColor, setTextPercentColor] = useState('#ffffff')
  const [totalPercent, setTotalPercent] = useState(0)
  const [hoverItemSub, setHoverItemSub] = useState()

  useEffect(() => {
    dataChart.map((_, index) => {
      if (index !== 0) {
        const percentBefore = dataChart[index - 1].percent
        setTransformItemChart((prev) => [...prev, percentBefore * 3.6 + prev[index - 1]])
      }
      return 0
    })
  }, [dataChart])

  useEffect(() => {
    const dataItemPercent = []
    dataChart.map((item) => {
      dataItemPercent.push(item.percent)

      if (item.percent !== '~') {
        setTotalPercent((prev) => prev + item.percent)
      }
      return 0
    })

    let i = 10
    dataItemPercent
      .sort((a, b) => b - a)
      .map((item) => {
        setItemWidthCircle((prev) => {
          i -= 1.7
          return [
            ...prev,
            {
              percent: item,
              width: i,
            },
          ]
        })

        return 0
      })
  }, [dataChart])

  return (
    <Row className="custom-chart-svg">
      <Col span={13}>
        <svg viewBox="0 0 40 40" style={{ width: '100%' }} {...props}>
          {dataChart.map((item, index) => {
            const percentBefore = index !== 0 ? dataChart[index - 1].percent : 0
            return (
              <DrawPieChartItem
                key={`draw-chart-key-${index}`}
                item={item}
                index={index}
                percentBefore={percentBefore}
                colorChart={colorChart}
                transformItemChart={transformItemChart}
                setTextPercent={setTextPercent}
                setTextPercentColor={setTextPercentColor}
                itemWidthCircle={itemWidthCircle}
                dataChart={dataChart}
                totalPercent={totalPercent}
                setHoverItemSub={setHoverItemSub}
              />
            )
          })}

          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dy=".3em"
            className="percent-item"
            style={{ fill: totalPercent ? textPercentColor : '#fff' }}
          >
            {totalPercent ? parseFloat(textPercent.toFixed(2)) : '0'}%
          </text>
        </svg>
      </Col>
      <Col span={11}>
        <div className="wrapper-sub">
          {dataChart.map((item, index) => (
            <div key={item.currency} className="wrapper-sub-item">
              <div>
                <div className="header-sub-item-currency">
                  <SubItemChartIcon
                    className={hoverItemSub === colorChart[index]?.color ? 'active' : ''}
                    fill={colorChart[index]?.color}
                  />{' '}
                  {item.currency}
                </div>
                <div className="header-sub-item-percent">{item.percent !== '~' ? parseFloat(item.percent.toFixed(2)) : `~`}%</div>
              </div>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  )
}

export default DrawPieChart
