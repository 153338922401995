import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1283 9.51256C11.4448 8.82915 10.3368 8.82915 9.65338 9.51256C8.96997 10.196 8.96997 11.304 9.65339 11.9874L15.3577 17.6918L9.51256 23.537C8.82915 24.2204 8.82915 25.3284 9.51256 26.0119C10.196 26.6953 11.304 26.6953 11.9874 26.0119L17.8326 20.1667L23.6777 26.0117C24.3611 26.6951 25.4691 26.6951 26.1525 26.0117C26.836 25.3283 26.836 24.2203 26.1525 23.5368L20.3075 17.6918L26.0117 11.9876C26.6951 11.3042 26.6951 10.1961 26.0117 9.5127C25.3283 8.82928 24.2203 8.82928 23.5368 9.5127L17.8326 15.2169L12.1283 9.51256Z"
      fill="white"
    />
    <circle cx="17.5" cy="18" r="17" stroke="white" strokeOpacity="0.5" />
  </Svg>
)

export default Icon
