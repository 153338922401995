import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 3.25C0 2.52065 0.289731 1.82118 0.805456 1.30546C1.32118 0.789731 2.02065 0.5 2.75 0.5H16.5C17.4725 0.5 18.4051 0.886308 19.0927 1.57394C19.7804 2.26158 20.1667 3.19421 20.1667 4.16667V4.658C20.7241 4.97981 21.1869 5.44267 21.5087 6.00005C21.8306 6.55744 22 7.18971 22 7.83333V18.8333C22 19.8058 21.6137 20.7384 20.9261 21.4261C20.2384 22.1137 19.3058 22.5 18.3333 22.5H4.58333C3.36776 22.5 2.20197 22.0171 1.34243 21.1576C0.482885 20.298 0 19.1322 0 17.9167V3.25ZM1.83333 3.25C1.83333 3.49312 1.92991 3.72627 2.10182 3.89818C2.27373 4.07009 2.50688 4.16667 2.75 4.16667H18.3333C18.3333 3.68044 18.1402 3.21412 17.7964 2.8703C17.4525 2.52649 16.9862 2.33333 16.5 2.33333H2.75C2.50688 2.33333 2.27373 2.42991 2.10182 2.60182C1.92991 2.77373 1.83333 3.00688 1.83333 3.25ZM15.5833 11.5C15.3402 11.5 15.1071 11.5966 14.9352 11.7685C14.7632 11.9404 14.6667 12.1736 14.6667 12.4167C14.6667 12.6598 14.7632 12.8929 14.9352 13.0648C15.1071 13.2368 15.3402 13.3333 15.5833 13.3333H17.4167C17.6598 13.3333 17.8929 13.2368 18.0648 13.0648C18.2368 12.8929 18.3333 12.6598 18.3333 12.4167C18.3333 12.1736 18.2368 11.9404 18.0648 11.7685C17.8929 11.5966 17.6598 11.5 17.4167 11.5H15.5833Z"
      fill="white"
    />
  </Svg>
)

export default Icon
