import './styles.scss'
import React from 'react'
import { Table } from 'antd'

const TableAccountActivity = ({ columns, dataSource, ...props }) => (
  <div className="wrapper-table-account-activity">
    <Table
      scroll={{ y: window.innerWidth < 768 ? 'max-content' : 304, x: 'max-content' }}
      className="table-account-activity"
      columns={columns}
      dataSource={dataSource}
      pagination
      {...props}
    />
  </div>
)

export default TableAccountActivity
