import React from 'react'
import { Pagination } from 'antd'
import './style.scss'

const PaginationAnt = ({ className, ...props }) => <Pagination className={`custom-pagination ${className} `} {...props} />

PaginationAnt.defaultProps = {
  className: '',
}
export default PaginationAnt
