import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import QRcode from 'qrcode.react'
import './styles.scss'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import InputCode from 'components/InputCode'
import ModalCustom from 'components/BaseModal'
import { update2FA, verify2FA } from 'state/auth/actions'
import { getProfile } from 'state/user/actions'
import InputCopy from 'components/InputCopy'
import ButtonSubmit from 'widgets/ButtonSubmit'
import { FormattedMessage } from 'react-intl'

export const MODAlTYPE = {
  CREATE: 'create',
  VERIFY: 'verify',
  UPDATE: 'update',
}
const initialField = 6

/**
 * @param {*} dataModal.code if is required for show QR Code
 * @param {*} modalType type of dispatch
 */

const ModalTurnOn2FA = ({ visible, dataModal, modalType, onCancel, ...props }) => {
  const dispatch = useDispatch()

  const { userInfo } = useSelector((state) => state.user)

  const [code, onChangeCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')

  const handleCancel = () => {
    setErrorMess('')
    setLoading(false)
    onChangeCode('')
    onCancel()
  }

  const handleOk = () => {
    if (code.length < initialField) return setErrorMess(<FormattedMessage id="An occurred error, Please try again late." />)
    setLoading(true)
    setErrorMess('')

    switch (modalType) {
      case MODAlTYPE.VERIFY:
        executeRecaptcha((tokenCaptcha) => {
          dispatch(
            verify2FA(
              {
                token: code,
                token_captcha: tokenCaptcha,
              },
              () => {
                setLoading(false)
                handleCancel()
                dispatch(getProfile())
              },
              () => {
                setLoading(false)
              },
            ),
          )
        })
        break
      case MODAlTYPE.UPDATE:
        executeRecaptcha((tokenCaptcha) => {
          dispatch(
            update2FA(
              {
                token: code,
                status: userInfo.status_2fa === 2,
                token_captcha: tokenCaptcha,
              },
              () => {
                setLoading(false)
                handleCancel()
                dispatch(getProfile())
              },
              () => {
                setLoading(false)
                dispatch(getProfile())
              },
            ),
          )
        })
        break
      default:
        setLoading(false)
        dispatch(getProfile())
        handleCancel()
        break
    }
  }

  const renderModalDescription = (type) => {
    switch (type) {
      case MODAlTYPE.VERIFY:
        return <FormattedMessage id="Get code from your Google Authenticator app on your mobile phone!" />
      case MODAlTYPE.UPDATE:
        return <FormattedMessage id="Get code from your Google Authenticator app on your mobile phone!" />
      default:
        return <FormattedMessage id="Get code from your email" />
    }
  }

  return (
    <ModalCustom
      title={
        <div className="modal-verify-cody-header">
          <FormattedMessage id="Backup key" />
        </div>
      }
      centered
      className="modal-2fa"
      visible={visible}
      loading={loading}
      onOke={handleOk}
      onCancel={handleCancel}
      {...props}
    >
      {dataModal?.data && (
        <>
          <div className="canvas-qrcode">
            <QRcode size={200} value={dataModal.data?.toString()} />
          </div>
          <p className="sub-model">
            <FormattedMessage id="Provided by" />{' '}
            <span>
              <FormattedMessage id="LotCeDeFi" />
            </span>
          </p>
          <p className="note-model">
            <FormattedMessage id="Please save this backup key in a secure location. This key will allow you recover your authenticator if you lose your phone. Without it resetting your authenticator will take at least 7 days." />
          </p>
        </>
      )}

      <div className="modal-2fa-box-input">
        <div className="modal-2fa-box-input-item">{dataModal?.code && <InputCopy value={dataModal.code} />}</div>

        <div className="modal-2fa-box-input-item">
          <p>
            <FormattedMessage id="Google Authenticator Code" />
          </p>
          <InputCode value={code} initialField={initialField} hasErrored={Boolean(errorMess)} onChange={onChangeCode} />
        </div>
      </div>

      {/* <div className="text-note">{renderModalDescription(modalType)}</div> */}
    </ModalCustom>
  )
}

export default ModalTurnOn2FA
