import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { linksPublic } from 'components/Menu/config'
import FooterAuth from '../FooterAuth'
import './styles.scss'
import { Resizable } from 're-resizable'
import ButtonSecondary from 'widgets/ButtonSecondary'
import ButtonPrimary from 'widgets/ButtonPrimary'
import WalletLogin from 'components/WalletLogin'

const AuthWrapper = ({ children }) => {
  // const [openForm, setOpenForm] = useState(location.pathname === '/login' ? true : false)
  const [openForm, setOpenForm] = useState(true)
  const location = useLocation()

  const handleSetOpenForm = () => {
    setOpenForm(true)
  }

  return (
    <div className="auth-container">
      <div className="auth-content-right">
        <div className="auth-body-right" style={{ display: openForm && 'block' }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default AuthWrapper
