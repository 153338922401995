import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { DECIMALS } from 'constants/index'
import { roundNumber } from 'helpers/CommonHelper'
import './styles.scss'
import { FormattedMessage } from 'react-intl'

const Amount = ({ baseCurrency, chainAttribute, userInfo, value, onChange, ...props }) => {
  const is2Fa = userInfo?.status_2fa === 1
  const feeBNB =
    chainAttribute?.withdraw_fee_chain !== undefined ? chainAttribute?.withdraw_fee_chain / 10 ** DECIMALS : undefined
  const feeToken = chainAttribute?.withdraw_fee_token ?? undefined
  const minimumAmount = 100
  return (
    <div className="box-amount">
      {!is2Fa && (
        <p className="amount-title">
          <FormattedMessage id="Amount" />
        </p>
      )}
      <div className="amount-available">
        <p>
          <span>
            <FormattedMessage id="Available:" />
          </span>
          <span>
            {baseCurrency?.balance !== undefined ? (
              <>
                <CurrencyFormat
                  value={roundNumber(baseCurrency.balance / 10 ** DECIMALS)}
                  displayType="text"
                  thousandSeparator
                  renderText={(v) => v}
                />
                <span style={{ marginLeft: '10px' }}>{baseCurrency.title}</span>
              </>
            ) : (
              '--'
            )}
          </span>
        </p>
      </div>

      <div className="box-input-amount">
        <input
          value={value}
          placeholder={`Minimum amount (per transaction) ${minimumAmount} USD`}
          onChange={(e) => onChange(e.target.value)}
          {...props}
        />
        {baseCurrency?.balance !== undefined && (
          <button type="button" onClick={() => onChange(roundNumber(baseCurrency.balance / 10 ** DECIMALS))}>
            Max
          </button>
        )}
      </div>
      <div className="wrapper-availabel-fee">
        <div className="availabel-bottom">
          <p>
            <FormattedMessage id="MaxAvailable:" />
            <span>
              {baseCurrency?.balance !== undefined ? (
                <>
                  <CurrencyFormat
                    value={roundNumber(baseCurrency.balance / 10 ** DECIMALS)}
                    displayType="text"
                    thousandSeparator
                    renderText={(v) => v}
                  />
                  {baseCurrency.title}
                </>
              ) : (
                '--'
              )}
            </span>
          </p>
        </div>
        <div className="amount-fee">
          {(feeBNB !== undefined || feeToken !== undefined) && (
            <>
              <p>
                <FormattedMessage id="Fee:" />
              </p>
              <p>
                {feeBNB !== undefined && (
                  <span>
                    {feeBNB}&nbsp;{chainAttribute?.native_token}
                  </span>
                )}
                {feeToken !== undefined && (
                  <span>
                    {feeToken}%&nbsp;{baseCurrency.code}
                  </span>
                )}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default Amount
