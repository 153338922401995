/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
import React, { useEffect, useRef } from 'react'
import QRCode from 'qrcode.react'
import './styles.scss'
import ModalCustom from 'components/BaseModal'
import QrReader from 'react-qr-reader'
import ButtonSubmit from 'widgets/ButtonSubmit'
import { FormattedMessage } from 'react-intl'

const ModalScan = ({ visible, value, setOpen, title, handleScan, handleError }) => {
  const copyStylesInline = (destinationNode, sourceNode) => {
    const containerElements = ['svg', 'g']
    for (let cd = 0; cd < destinationNode.childNodes.length; cd++) {
      const child = destinationNode.childNodes[cd]
      if (containerElements.indexOf(child.tagName) !== -1) {
        copyStylesInline(child, sourceNode.childNodes[cd])
        continue
      }
      const style = sourceNode.childNodes[cd].currentStyle || window.getComputedStyle(sourceNode.childNodes[cd])
      if (style === 'undefined' || style == null) continue
      for (let st = 0; st < style.length; st++) {
        child.style.setProperty(style[st], style.getPropertyValue(style[st]))
      }
    }
  }

  const triggerDownload = (imgURI, fileName) => {
    const evt = new MouseEvent('click', {
      view: window,
      bubbles: false,
      cancelable: true,
    })
    const a = document.createElement('a')
    a.setAttribute('download', fileName)
    a.setAttribute('href', imgURI)
    a.setAttribute('target', '_blank')
    a.dispatchEvent(evt)
  }

  const downloadSvg = (svg, fileName) => {
    const copy = svg.cloneNode(true)
    copyStylesInline(copy, svg)
    const canvas = document.createElement('canvas')
    const bbox = svg.getBBox()
    canvas.width = bbox.width
    canvas.height = bbox.height
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, bbox.width, bbox.height)
    const data = new XMLSerializer().serializeToString(copy)
    const DOMURL = window.URL || window.webkitURL || window
    const img = new Image()
    const svgBlob = new Blob([data], { type: 'image/svg+xml;charset=utf-8' })
    const url = DOMURL.createObjectURL(svgBlob)
    img.onload = function () {
      ctx.drawImage(img, 0, 0)
      DOMURL.revokeObjectURL(url)
      if (typeof navigator !== 'undefined' && navigator.msSaveOrOpenBlob) {
        const blob = canvas.msToBlob()
        navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        const imgURI = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
        triggerDownload(imgURI, fileName)
      }
      // document.removeChild(canvas)
    }
    img.src = url
  }

  return (
    <>
      <ModalCustom
        title={title}
        className="modal-qrcode-deposit"
        centered
        visible={Boolean(visible)}
        width={490}
        height={490}
        onCancel={() => setOpen(false)}
      >
        {title === 'Scan to Withdraw' ? (
          <div className="modal-deposit-qs-code">
            <QrReader delay={300} showViewFinder onScan={handleScan} onError={handleError} />
          </div>
        ) : (
          <div className="modal-deposit-qs-code" style={{ maxWidth: '204px' }}>
            <QRCode id="iconCheckmarkLg" renderAs="svg" size={200} value={value} />
          </div>
        )}
        <p className="sub-model">
          <FormattedMessage id="Provided by" />{' '}
          <span>
            <FormattedMessage id="LotCeDeFi" />
          </span>
        </p>

        {title === 'Scan to Deposit' && (
          <div className="modal-qrcode-deposit-action">
            <button
              onClick={() => {
                setOpen(false)
              }}
              className="modal-qrcode-deposit-action-share"
              type="button"
            >
              <FormattedMessage id="SHARE" />
            </button>
            <ButtonSubmit
              onClick={() => {
                setOpen(false)
              }}
            >
              <FormattedMessage id="SAVE" />
            </ButtonSubmit>
          </div>
        )}

        <div className="modal-scan-action-bg" />
      </ModalCustom>
    </>
  )
}

export default ModalScan
