import './styles.scss'
import React from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

const TableAccountActivity = ({ data, ...props }) => (
  <div className="wrapper-item-account-activity" {...props}>
    {data
      .slice()
      .sort((a, b) => b.last_login - a.last_login)
      .map((item, index) => (
        <div className="item-account-activity" key={`item-account-activity-${index}`}>
          <div>
            <p>{moment(item.last_login * 1000).format('YYYY/MM/DD')}</p>
            <p>
              <FormattedMessage id="Logout" />
            </p>
          </div>
          <div>
            <p>
              <FormattedMessage id="IP Address" />
            </p>
            <p>{item.ip}</p>
          </div>
          <div>
            <p>
              <FormattedMessage id="Device" />
            </p>
            <p>{item.os}</p>
          </div>
        </div>
      ))}
  </div>
)

export default TableAccountActivity
