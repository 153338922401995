import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="32" height="32" rx="10" fill="white" fillOpacity="0.1" />
    <path
      d="M21.8071 23L16.9891 18.1815C15.9554 18.9164 14.6833 19.2361 13.4249 19.0773C12.1665 18.9185 11.0137 18.2928 10.195 17.3241C9.37625 16.3554 8.95133 15.1146 9.00444 13.8474C9.05755 12.5802 9.58481 11.3792 10.4817 10.4825C11.3784 9.58534 12.5795 9.05781 13.8468 9.00448C15.1141 8.95114 16.3553 9.37589 17.3242 10.1945C18.293 11.0132 18.919 12.1659 19.0779 13.4243C19.2368 14.6827 18.917 15.9549 18.182 16.9887L23 21.8072L21.8079 22.9992L21.8071 23ZM14.0579 10.6865C13.2824 10.6863 12.5304 10.9534 11.9289 11.4429C11.3273 11.9323 10.9128 12.6141 10.7553 13.3735C10.5977 14.1328 10.7067 14.9232 11.064 15.6115C11.4212 16.2999 12.0047 16.8441 12.7164 17.1524C13.428 17.4607 14.2241 17.5144 14.9706 17.3043C15.7172 17.0942 16.3685 16.6332 16.8148 15.999C17.2612 15.3648 17.4753 14.5962 17.421 13.8226C17.3668 13.049 17.0475 12.3177 16.517 11.752L17.0271 12.2578L16.4521 11.6846L16.442 11.6745C16.1297 11.3603 15.7582 11.1112 15.349 10.9416C14.9397 10.772 14.5009 10.6853 14.0579 10.6865Z"
      fill="white"
    />
    <rect x="0.5" y="0.5" width="31" height="31" rx="9.5" stroke="white" strokeOpacity="0.2" />
  </Svg>
)

export default Icon
