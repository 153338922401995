import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import InputCode from 'components/InputCode'
import ButtonSubmit from 'widgets/ButtonSubmit'
import { verifyEmail } from 'state/auth/actions'
import AuthCard from '../AuthCard'
import { FormattedMessage } from 'react-intl'

const VerifyAuthenCode = ({ type, initialField, token, onFinish, children, ...props }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [, setTimeCountDown] = useState(5)

  const onSubmit = (value) => {
    const code = Object.values(value || codeValue).filter((o) => o)
    if (code.length < initialField) {
      setErrorMess(`Please enter ${initialField} characters Google Authenticator on the Mobile App`)
    } else {
      setErrorMess('')
      setLoading(true)
      dispatch(
        verifyEmail(
          {
            token,
            code: code.join(''),
            os: window.navigator.platform,
            device_id: window.navigator.appVersion,
          },
          (receipt) => {
            onFinish({ code: codeValue, type: 'is_email', ...receipt })
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        ),
      )
    }
  }

  const handleChangeCode = (value) => {
    setCodeValue(value)
    const code = Object.values(value).filter((o) => o)
    if (code.length >= initialField) {
      onSubmit(value)
    }
  }

  // const resendCode2 = () => {
  //   switch (type) {
  //     case 'login':
  //       dispatch(
  //         resendAuthenLoginAccountCode(
  //           {
  //             token,
  //           },
  //           () => {
  //             setTimeCountDown(5)
  //           },
  //         ),
  //       )
  //       break
  //     default:
  //       break
  //   }
  // }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  return (
    <AuthCard className="authen-code fadeIn" {...props}>
      <div style={{ textAlign: 'center', marginBottom: '32px' }}>
        <img src="/images/dice.png" style={{ maxWidth: '35%' }} alt="Verification code" />
      </div>

      <h2 className="authen-title">
        <FormattedMessage id="Google Authenticator" />
      </h2>
      <p className="authen-des">
        <FormattedMessage id="Enter" /> {initialField} <FormattedMessage id="characters or past copied text" />
      </p>
      <div className="authen-input">
        <InputCode initialField={initialField} value={codeValue} onChange={handleChangeCode} />
      </div>
      {/* <div className="verify-resend">
        <div style={{ opacity: timeCountDown > 0 ? '1' : '0' }}>Resend in {timeCountDown}s</div>
        {timeCountDown <= 0 && (
          <a onClick={resendCode2} role="presentation">
            Resend
          </a>
        )}
      </div> */}
      <div className="authen-error">{errorMess}</div>
      <div className="authen-action">
        <ButtonSubmit style={{ borderRadius: '35px', width: '150px' }} size="standard" loading={loading} onClick={onSubmit}>
          <FormattedMessage id="Done" />
        </ButtonSubmit>
      </div>
      {/* <div className="authen-exclamation">
        <ExclamationIcon />
        <p>The Lottery CeDeFi wallets/Google Authenticator app protects your withdrawals and Lottery CeDeFi wallets account</p>
      </div> */}
      {/* <p>
        Or login via <a href="/login">Verification code</a>
      </p> */}
    </AuthCard>
  )
}

VerifyAuthenCode.defaultProps = {
  initialField: 6,
  onFinish: () => null,
}

export default VerifyAuthenCode
