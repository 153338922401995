import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'antd'
import CardBalance from './components/CardBalance'
import './style.scss'
import DrawPieChart from 'components/DrawPieChart'
import WalletsList from './components/WalletsList'

const Wallet = () => {
  const { currency, balance } = useSelector((state) => state.wallets)
  /**
   * Handle  wallet chart percent
   */
  const [dataChart, setDataChart] = useState(undefined)
  useEffect(() => {
    if (currency && balance) {
      const topWallet = currency.slice(0, 4)
      const totalBalance = currency.reduce((accum, wallet) => accum + wallet?.balance * wallet.usd_rate, 0)
      const totalTopBalance = topWallet.reduce((accum, wallet) => accum + wallet?.balance * wallet.usd_rate, 0)

      const walletPercent = topWallet.map((item, index) => {
        const percent = ((item.balance * item.usd_rate) / totalTopBalance) * 100
        return {
          currency: item.title,
          percent: percent || 0,
          balance: item.balance / 1e18,
          isActive: index === 0,
        }
      })

      const otherPercent = ((totalBalance - totalTopBalance) / totalBalance) * 100
      walletPercent.push({
        currency: 'Others',
        balance: otherPercent || 0,
        percent: '~',
        isActive: false,
      })
      setDataChart(walletPercent)
    }
  }, [balance, currency])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="wallet-container">
      <div className="wallet-body">
        <div className="wallet-content">
          <Row gutter={[30, 30]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <CardBalance currency={currency} />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              {dataChart && <DrawPieChart dataChart={dataChart} />}
            </Col>
            <Col span={24}>
              <WalletsList currency={currency} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Wallet
