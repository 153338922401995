import './styles.scss'
import React from 'react'
import Form from './component/form'
import { Link } from 'react-router-dom'
import ButtonDisable from 'widgets/ButtonDisable'
import { FormattedMessage } from 'react-intl'

const AccountActivity = () => (
  <>
    <div className="warp-page-account-activity">
      <Form />
    </div>
    <Link
      style={{ display: window.innerWidth <= 991 ? 'block' : 'none' }}
      className="activity-btn-logout"
      to="/security/account-activity"
    >
      <ButtonDisable className="btn-disable" style={{ width: '130px', height: '35px' }}>
        <FormattedMessage id="Log Out All" />
      </ButtonDisable>
    </Link>
  </>
)

export default AccountActivity
