/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import ProfileAccount from 'components/ProfileAccount'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'
import TotalBalance from '../TotalBalance'
import './style.scss'
import BackLink from 'components/BackLink'
import { SearchIcon, ArrowBackMenuIcon } from 'widgets/Svg'
import ModalMenu from 'components/ModalMenu'
import { setLanguage } from 'state/app/actions'
import { Select } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'

const { Option } = Select

const PrivateHeader = ({ intl, links, isMobile, isLogin, showMenu, toggleMenu }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { currency } = useSelector((state) => state.wallets)
  const baseToken = useMemo(() => currency?.find((item) => item.code === 'ZUKI'), [currency])
  const [menu, setMenu] = useState(false)
  const { optionLanguage, language } = useSelector((state) => state.app)

  useEffect(() => {
    if (menu) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  }, [menu])

  const handleChangeLang = (lang) => {
    dispatch(setLanguage({ language: lang }))
  }

  const pathName = history.location.pathname.split('/')

  const pathNameTitle = () => {
    if (pathName.includes('stake-history')) {
      return <h2>Staking History</h2>
    }
    if (pathName.includes('stake')) {
      return <h2>Stake</h2>
    }
    if (pathName.includes('primary-kyc')) {
      return <h2>Primary KYC</h2>
    }
    if (pathName.includes('profile')) {
      return <h2>Account</h2>
    }
    if (pathName.includes('account-activity')) {
      return <h2>Account Activity</h2>
    }
    if (pathName.includes('security')) {
      return <h2>Security</h2>
    }
    if (pathName.includes('history')) {
      return <h2>History</h2>
    }
    if (pathName.includes('deposit')) {
      return <h2>Deposits</h2>
    }
    if (pathName.includes('withdraw')) {
      return <h2>Withdraw</h2>
    }
    if (pathName.includes('wallet')) {
      return <h2>Wallet</h2>
    }
    if (pathName.includes('account')) {
      return <h2>Account</h2>
    }
    if (pathName.includes('buy-ticket')) {
      return <h2>Buy Ticket</h2>
    }
    if (pathName.includes('my-ticket')) {
      return <h2>My Ticket</h2>
    }
  }

  return (
    <>
      <div className={`wrapper-private-header-mobile ${menu ? 'active' : ''}`}>
        <header className="private-header-mobile">
          {pathNameTitle() && (
            <div className="header-left header-left-title" role="presentation" onClick={() => history.goBack()}>
              <ArrowBackMenuIcon /> {pathNameTitle()}
            </div>
          )}

          <div className={`header-left ${pathNameTitle() ? 'has-title' : ''}`}>
            <div className="header-left-box-search">
              <SearchIcon />
              <input placeholder={intl.formatMessage({ id: 'Find your game' })} />
            </div>

            <Link to="/">
              <div className="panel-logo">
                <img src="/images/logo-text.png" title="Lottery CeDeFi wallet" alt="Lottery CeDeFi wallet" />
                <img src="/images/halo-logo-mb.png" title="Lottery CeDeFi wallet" alt="Lottery CeDeFi wallet" />
              </div>
            </Link>
          </div>

          {/* <div>
            <Select
              // open
              defaultValue={language}
              onChange={handleChangeLang}
              dropdownClassName="dropdownClassName"
              dropdownMatchSelectWidth={150}
            >
              {optionLanguage.map((item, index) => (
                <Option value={item.id} key={`language-${index}`}>
                  <div
                    className="image-flag"
                    style={{
                      backgroundImage: `url(${item.image})`,
                    }}
                  />
                  <span className="text-flag">{item.label}</span>
                </Option>
              ))}
            </Select>
          </div> */}

          <div className="header-right">
            {isLogin ? (
              <>
                <TotalBalance baseToken={baseToken} style={{ marginRight: window.innerWidth >= 768 ? '23px' : '4px' }} />
                <ProfileAccount hideName style={{ marginLeft: window.innerWidth >= 768 ? '23px' : '10px' }} />
              </>
            ) : window.innerWidth > 991 ? (
              <>
                {pathName.includes('login') ? (
                  <Link to="/login">
                    <ButtonPrimary size="larger">
                      <FormattedMessage id="Sign in" />
                    </ButtonPrimary>
                  </Link>
                ) : (
                  <Link to="/login">
                    <ButtonSecondary size="larger">
                      <FormattedMessage id="Sign in" />
                    </ButtonSecondary>
                  </Link>
                )}

                {pathName.includes('register') ? (
                  <Link to="/register">
                    <ButtonPrimary size="larger">
                      <FormattedMessage id="Sign up" />
                    </ButtonPrimary>
                  </Link>
                ) : (
                  <Link to="/register">
                    <ButtonSecondary size="larger">
                      <FormattedMessage id="Sign up" />
                    </ButtonSecondary>
                  </Link>
                )}
              </>
            ) : (
              ''
            )}

            <div className={`toggle-menu ${menu ? 'active' : ''}`} role="presentation" onClick={() => setMenu((prev) => !prev)}>
              <span />
              <span />
              <span />
            </div>
          </div>

          <div className="menu-content-mobile" data-visible={showMenu.toString()}>
            {isMobile && showMenu && (
              <div className="collapse-icon">
                <input type="checkbox" id="menu" checked={showMenu} onChange={toggleMenu} />
                <label htmlFor="menu" className="icon">
                  <div className="menu" />
                </label>
              </div>
            )}
            <div className="menu-body">
              <ul className="menu-link">
                {links.map((item, index) => {
                  const isHttp = item?.href?.startsWith('http')
                  const Tag = isHttp ? 'a' : Link
                  const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
                  return (
                    <li
                      key={`menu-mb-${index}`}
                      className={history.location.pathname === item.href ? 'active' : ''}
                      role="presentation"
                      onClick={toggleMenu}
                    >
                      <Tag {...propsLink} rel="noreferrer">
                        {item.label}
                      </Tag>
                    </li>
                  )
                })}
              </ul>
              {!isLogin && (
                <div className="menu-actions-mobile">
                  <Link to="/register" onClick={toggleMenu}>
                    <ButtonSecondary
                      size="larger"
                      style={{
                        color: history.location.pathname === '/register' ? '#000' : '#fff',
                        background: history.location.pathname === '/register' ? '#fff' : 'transparent',
                      }}
                    >
                      Sign up
                    </ButtonSecondary>
                  </Link>
                  <Link to="/login" onClick={toggleMenu}>
                    <ButtonPrimary
                      size="larger"
                      style={{
                        color: history.location.pathname === '/login' ? '#000' : '#fff',
                        background: history.location.pathname === '/login' ? '#fff' : 'transparent',
                      }}
                    >
                      Sign in
                    </ButtonPrimary>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </header>
      </div>

      <ModalMenu visible={menu} setModalMenu={setMenu} isLogin={isLogin} />
    </>
  )
}
export default injectIntl(PrivateHeader)
