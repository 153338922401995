import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg width="18" height="16" viewBox="0 0 18 16" fill="none" {...props}>
    <path
      d="M16.875 6.92934H3.5325L7.61625 2.25833C7.80721 2.03954 7.89908 1.75748 7.87165 1.47419C7.84422 1.1909 7.69974 0.929595 7.47 0.747748C7.24026 0.565902 6.94407 0.478415 6.64659 0.504533C6.34911 0.530652 6.07471 0.668237 5.88375 0.887021L0.25875 7.31502C0.220906 7.36615 0.187064 7.41986 0.1575 7.47572C0.1575 7.52928 0.1575 7.56142 0.0787502 7.61499C0.0277579 7.73783 0.00105876 7.86859 0 8.00067C0.00105876 8.13275 0.0277579 8.26351 0.0787502 8.38635C0.0787502 8.43992 0.0787499 8.47206 0.1575 8.52562C0.187064 8.58148 0.220906 8.63519 0.25875 8.68632L5.88375 15.1143C5.98952 15.2353 6.12198 15.3325 6.2717 15.3992C6.42142 15.4658 6.58473 15.5003 6.75 15.5C7.01286 15.5005 7.2676 15.4133 7.47 15.2536C7.58392 15.1637 7.67808 15.0532 7.7471 14.9286C7.81612 14.8039 7.85864 14.6675 7.87223 14.5272C7.88581 14.3869 7.8702 14.2454 7.82627 14.1108C7.78235 13.9763 7.71098 13.8513 7.61625 13.743L3.5325 9.072H16.875C17.1734 9.072 17.4595 8.95913 17.6705 8.75822C17.8815 8.5573 18 8.2848 18 8.00067C18 7.71653 17.8815 7.44404 17.6705 7.24312C17.4595 7.04221 17.1734 6.92934 16.875 6.92934Z"
      fill="white"
    />
  </Svg>
)

export default Icon
