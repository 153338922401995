import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 16 19" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 17.4766C0 17.1782 0.118526 16.892 0.329505 16.6811C0.540483 16.4701 0.826631 16.3516 1.125 16.3516H14.625C14.9234 16.3516 15.2095 16.4701 15.4205 16.6811C15.6315 16.892 15.75 17.1782 15.75 17.4766C15.75 17.7749 15.6315 18.0611 15.4205 18.2721C15.2095 18.483 14.9234 18.6016 14.625 18.6016H1.125C0.826631 18.6016 0.540483 18.483 0.329505 18.2721C0.118526 18.0611 0 17.7749 0 17.4766ZM3.70463 8.80619C3.91559 8.59528 4.20169 8.4768 4.5 8.4768C4.79831 8.4768 5.08441 8.59528 5.29537 8.80619L6.75 10.2608V1.72656C6.75 1.42819 6.86853 1.14205 7.0795 0.931067C7.29048 0.720089 7.57663 0.601563 7.875 0.601562C8.17337 0.601563 8.45952 0.720089 8.6705 0.931067C8.88147 1.14205 9 1.42819 9 1.72656V10.2608L10.4546 8.80619C10.5584 8.69874 10.6825 8.61303 10.8198 8.55407C10.957 8.49511 11.1047 8.46408 11.254 8.46278C11.4034 8.46148 11.5516 8.48995 11.6898 8.54651C11.8281 8.60308 11.9537 8.68661 12.0593 8.79224C12.1649 8.89787 12.2485 9.02348 12.305 9.16174C12.3616 9.3 12.3901 9.44814 12.3888 9.59751C12.3875 9.74689 12.3564 9.89451 12.2975 10.0318C12.2385 10.169 12.1528 10.2932 12.0454 10.3969L8.67037 13.7719C8.45941 13.9828 8.17331 14.1013 7.875 14.1013C7.57669 14.1013 7.29059 13.9828 7.07963 13.7719L3.70463 10.3969C3.49372 10.186 3.37524 9.89987 3.37524 9.60156C3.37524 9.30325 3.49372 9.01716 3.70463 8.80619Z"
      fill="url(#paint0_linear_1713_33898)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1713_33898"
        x1="0.636673"
        y1="10.1534"
        x2="16.1296"
        y2="5.55696"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42FD24" />
        <stop offset="1" stopColor="#13FFF1" stopOpacity="0.96" />
      </linearGradient>
    </defs>
  </Svg>
)

export default Icon
