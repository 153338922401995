import React from 'react'
import { Link } from 'react-router-dom'
import { WithdrawIcon, DepositIcon } from 'widgets/Svg'
import './style.scss'

const WalletActions = ({ item }) => {
  // const menu = (
  //   <Menu>
  //     <Menu.Item>
  //       <Link to={`/wallet/deposit/${item.code}`}>
  //         <button type="button">Deposit</button>
  //       </Link>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <Link to={`/wallet/withdraw/${item.code}`}>
  //         <button type="button">Withdraw</button>
  //       </Link>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <Link to={`/stake/stake-history/${item.code}`}>
  //         <button type="button">Staking</button>
  //       </Link>
  //     </Menu.Item>
  //   </Menu>
  // )

  const menu = [
    {
      link: `/wallet/deposit/${item.code}`,
      icon: <WithdrawIcon />,
    },
    {
      link: `/wallet/withdraw/${item.code}`,
      icon: <DepositIcon />,
    },
  ]
  return (
    // <Dropdown overlay={menu} trigger={['click']}>
    //   <div className="ant-dropdown-link">
    //     <MoreHorizontal />
    //   </div>
    // </Dropdown>
    <div className="wallet-table-action">
      {menu.map((item, index) => (
        <Link to={`${item.link}`} key={index}>
          {item.icon}
        </Link>
      ))}
    </div>
  )
}

export default WalletActions
