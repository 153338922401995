import React from 'react'
import CurrencyFormat from 'react-currency-format'
import './styles.scss'
import { formatCode, formatDate, roundNumber } from 'helpers/CommonHelper'
import { DECIMALS } from 'constants/index'
import { getBscScanLink } from 'utils'
import { FormattedMessage } from 'react-intl'

const ItemRecentTransactionMobile = ({ data, setViewDetails, setModalVerifyCode, ...props }) => (
  <div className="item-recent-transaction" {...props}>
    <div>
      <p>{formatDate(data.createdAt * 1000)}</p>

      {data.status !== 'CREATED' ? (
        <a
          className="item-recent-transaction-view"
          role="presentation"
          onClick={() => setViewDetails({ toggle: true, dataModal: data })}
        >
          <FormattedMessage id="View" />
        </a>
      ) : (
        <a className="recent-transaction-table-view" role="presentation" onClick={() => setModalVerifyCode(data)}>
          <FormattedMessage id="Verify" />
        </a>
      )}
    </div>
    <div>
      <p>
        <FormattedMessage id="TxID" />
      </p>
      <p>
        {data.status === 'COMPLETED' ? (
          <span href={getBscScanLink(data.txhash, 'transaction', data.chain)} target="_blank" rel="noreferrer">
            {formatCode(data.txhash, 5, 5)}
          </span>
        ) : (
          data.status
        )}
      </p>
    </div>
    <div>
      <p>
        <FormattedMessage id="Status" />
      </p>
      <p data-status={data.status}>
        <span>{data.status}</span>
      </p>
    </div>
    <div>
      <p>
        <FormattedMessage id="Action" />
      </p>
      <p>{data.action}</p>
    </div>
    <div>
      <p>
        <FormattedMessage id="Amount" />
      </p>
      <p>
        <CurrencyFormat
          value={roundNumber(data.amount / 10 ** DECIMALS, 6)}
          displayType="text"
          thousandSeparator
          renderText={(v) => v}
        />
      </p>
    </div>
    <div>
      <p>
        <FormattedMessage id="Currency" />
      </p>
      <p>{data.currency}</p>
    </div>
    <div>
      <p>
        <FormattedMessage id="Network" />
      </p>
      <p>{data.chain}</p>
    </div>
    {/* <div>
        <p>Fee</p>
        <p>{data.fee / 10 ** DECIMALS}</p>
      </div> */}
  </div>
)

export default ItemRecentTransactionMobile
