import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'antd'
import './styles.scss'
import AuthWrapper from 'modules/auth/components/AuthWrapper'
import AuthCard from 'modules/auth/components/AuthCard'
import Input from 'widgets/Input'
import FormItem from 'widgets/FormItem'
import ButtonSubmit from 'widgets/ButtonSubmit'
import AuthenCode from 'modules/auth/components/VerifyResetPasswordCode'
import { requestForgotPassword } from 'state/auth/actions'
import { Link } from 'react-router-dom'
import { ArrowBackIcon } from 'widgets/Svg'
import { FormattedMessage } from 'react-intl'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [view, setView] = useState(0)
  const [email, setEmail] = useState('')

  const onFormSubmit = () => {
    form.validateFields().then((values) => {
      dispatch(
        requestForgotPassword(values, () => {
          setEmail(values.email)
          setView(1)
        }),
      )
    })
  }

  return (
    <AuthWrapper>
      <div className="wrapper-form-forgot">
        {view === 0 ? (
          <AuthCard className="form-forgot fadeIn">
            <img src="/images/icons/fotgot-password.svg" alt="" />

            <Link to="/">
              <ArrowBackIcon />
            </Link>

            <h2 className="forgot-title">
              <FormattedMessage id="Forgot Password" />
            </h2>
            <Form layout="vertical" form={form} onFinish={onFormSubmit}>
              <FormItem
                label={
                  <span className="forgot-label">
                    <FormattedMessage id="Enter your email address" />
                  </span>
                }
                name="email"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="Please enter email field" />,
                  },
                ]}
              >
                <Input placeholder="example@gmail.com" type="email" />
              </FormItem>

              <FormItem>
                <ButtonSubmit type="submit" className="form-action" style={{ maxWidth: '100%' }}>
                  <FormattedMessage id="CONTINUE" />
                </ButtonSubmit>
              </FormItem>
            </Form>
          </AuthCard>
        ) : (
          <AuthenCode email={email} setView={setView} />
        )}
      </div>
    </AuthWrapper>
  )
}

export default ForgotPassword
