/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react'
import './styles.scss'
import { Table, Pagination } from 'antd'
import { getHistoryBuyState } from 'state/lottery/actions'
import { useDispatch } from 'react-redux'
import { CONTRACT_NFT } from 'constants/index'
import { padLeadingZeros } from 'helpers/CommonHelper'
import { FormattedMessage } from 'react-intl'

const TableTransactionMyTicket = ({ day, gameDetail, historyBuyList, setHistoryBuyList, gameResult }) => {
  const dispatch = useDispatch()

  const [paramsHistoryBuyList, setParamsHistoryBuyList] = useState({
    page: 1,
    pageSize: 5,
    game_code: undefined,
  })

  useEffect(() => {
    dispatch(
      getHistoryBuyState(
        paramsHistoryBuyList,
        (data) => setHistoryBuyList(data),
        () => setHistoryBuyList(null),
      ),
    )
  }, [dispatch, paramsHistoryBuyList, setHistoryBuyList])

  useEffect(() => {
    setParamsHistoryBuyList((prev) => ({
      ...prev,
      game_code: day,
    }))
  }, [day])

  const handlerChangePage = (pageChange) => {
    setParamsHistoryBuyList((prev) => ({ ...prev, page: pageChange }))
  }

  const totalReward = (rs, record) => {
    let match = 0
    const tk = record?.lottery

    tk.split('-').map((item) => {
      if (rs?.indexOf(padLeadingZeros(item)) > -1) {
        match += 1
      }
      return match
    })

    switch (match) {
      case 6:
        return `${record?.reward?.total_prize / 10 ** 18}$`

      case 5:
        return '2000$'

      case 4:
        return '500$'

      case 3:
        return '5$'

      default:
        return '--'
    }
  }

  const columns = [
    {
      title: <FormattedMessage id="Ticket" />,
      dataIndex: 'lottery',
      key: 'lottery',
      render: (text) => (
        <div className="ticket-current">
          {text.split('-').map((item, index) => (
            <p key={index} className={`${gameResult?.indexOf(padLeadingZeros(item)) > -1 && 'active'}`}>
              {padLeadingZeros(item)}
            </p>
          ))}
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Prizes" />,
      dataIndex: 'reward',
      key: 'reward',
      // eslint-disable-next-line no-unneeded-ternary
      render: (text, record) => <>{totalReward(gameResult, record)}</>,
    },
    {
      title: <FormattedMessage id="NFT ID" />,
      dataIndex: 'nft_id',
      key: 'nft_id',
      // eslint-disable-next-line no-unneeded-ternary
      render: (text) => (
        <a target="_blank" rel="noreferrer" href={`https://polygonscan.com/token/${CONTRACT_NFT}?a=${text}`}>
          {text}
        </a>
      ),
    },
  ]

  return (
    <div className="my-ticket-table">
      <Table rowKey={(recode) => recode?._id} dataSource={historyBuyList?.rows} columns={columns} pagination={false} />
      <Pagination
        total={historyBuyList?.total}
        showSizeChanger={false}
        onChange={(page) => handlerChangePage(page)}
        pageSize={paramsHistoryBuyList.pageSize}
        defaultCurrent={1}
      />
    </div>
  )
}

export default TableTransactionMyTicket
