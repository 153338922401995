import React, { useMemo, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { EyeOutlined } from '@ant-design/icons'
import './styles.scss'
import { DECIMALS } from 'constants/index'
import { formatDate, roundNumber } from 'helpers/CommonHelper'
import RecentTransactionDesktop from '../RecentTransactionDesktop'
import ItemRecentTransactionMobile from '../ItemRecentTransactionMobile'
import ModalViewDetails from '../ModalViewDetails'
import Nodata from 'components/Nodata'
import { FormattedMessage } from 'react-intl'
import PaginationAnt from 'components/PaginationAnt'

const RecentTransaction = ({ rows, total, txParams, setModalVerifyCode, setTxParams }) => {
  const [viewDetails, setViewDetails] = useState({ toggle: false, dataModal: null })
  const columns = useMemo(
    () => [
      {
        title: <FormattedMessage id="Action" />,
        dataIndex: 'action',
        render: (text) => <p className="recent-transaction-table-action">{text}</p>,
      },
      {
        title: <FormattedMessage id="Network" />,
        dataIndex: 'chain',
        render: (text) => (
          <p className="recent-transaction-table-network" style={{ justifyContent: 'center', display: 'flex' }}>
            {text}
          </p>
        ),
      },
      {
        title: (
          <div className="recent-transaction-table-time-header" style={{ textAlign: 'left' }}>
            <FormattedMessage id="Date" />
          </div>
        ),
        // width: 20,
        dataIndex: 'createdAt',
        render: (text) => (
          <p className="recent-transaction-table-time" style={{ whiteSpace: 'nowrap' }}>
            {formatDate(text * 1000)}
          </p>
        ),
      },
      {
        title: <FormattedMessage id="Amount" />,
        dataIndex: 'amount',
        // width: 30,
        render: (text, record) => (
          <p className="recent-transaction-table-amount">
            {[
              'COMMISSION_LOTTERY',
              'BONUS_ACTIVE',
              'COMMISSION_STAKE',
              'COMMISSION_WIN_LOTTERY',
              'DEPOSIT',
              'UNSTAKE',
              'WIN_LOTTERY',
            ].includes(record.action) ? (
              <>
                <CurrencyFormat
                  value={roundNumber(text / 10 ** DECIMALS, 6)}
                  displayType="text"
                  thousandSeparator
                  suffix={` ${record.currency}`}
                  renderText={(v) => <p style={{ color: '#04d100' }}>+{v}</p>}
                />{' '}
              </>
            ) : (
              <>
                <CurrencyFormat
                  style={{ color: 'red' }}
                  value={roundNumber(text / 10 ** DECIMALS, 6)}
                  displayType="text"
                  thousandSeparator
                  suffix={` ${record.currency}`}
                  renderText={(v) => <p style={{ color: 'red' }}>-{v}</p>}
                />{' '}
              </>
            )}
          </p>
        ),
      },

      // {
      //   title: <FormattedMessage id="Currency" />,
      //   dataIndex: 'currency',
      //   render: (item, record) => (
      //     <div className="coin-data" style={{ justifyContent: 'center', display: 'flex' }}>
      //       <img src={record.icon} alt="" />
      //       <div className="coin-data-label">
      //         <div className="data-label-text">{item}</div>
      //       </div>
      //     </div>
      //   ),
      // },
      {
        title: <FormattedMessage id="Status" />,
        dataIndex: 'status',
        // width: 40,
        render: (text, record) => (
          <div className="recent-transaction-table-status" data-status={record.status}>
            <p>{text}</p>
          </div>
        ),
      },

      {
        title: 'Actions',
        key: 'operation',
        // width: 10,
        render: (_, record) => {
          if (record.status !== 'CREATED') {
            return (
              <a
                className="recent-transaction-table-view"
                role="presentation"
                onClick={() => setViewDetails({ toggle: true, dataModal: record })}
              >
                <EyeOutlined />
              </a>
            )
          }
          return (
            <a className="recent-transaction-table-view" role="presentation" onClick={() => setModalVerifyCode(record)}>
              <FormattedMessage id="Verify" />
            </a>
          )
        },
      },
    ],
    [setModalVerifyCode],
  )

  return (
    <div className="box-recent-transaction">
      <p className="recent-transaction-title">
        <FormattedMessage id="Recent Transaction" />
      </p>
      {window.innerWidth <= 991 ? (
        <div style={{ minHeight: '100px', background: '#00271A' }}>
          {rows?.length > 0 ? (
            rows?.map((item) => (
              <ItemRecentTransactionMobile
                data={item}
                key={item.id}
                setViewDetails={setViewDetails}
                setModalVerifyCode={setModalVerifyCode}
              />
            ))
          ) : (
            <Nodata />
          )}
          <PaginationAnt
            size="small"
            style={{ textAlign: 'center' }}
            total={total}
            current={txParams.page}
            showSizeChanger
            onChange={(page, pageSize) => {
              setTxParams((prev) => ({
                ...prev,
                page,
                pageSize,
              }))
            }}
          />
        </div>
      ) : (
        <RecentTransactionDesktop
          columns={columns}
          dataSource={rows || []}
          scroll={{ x: 800 }}
          rowKey={(record) => record._id}
          pagination={{
            total,
            current: txParams.page,
            showSizeChanger: true,
            showTotal: (tt) => `${tt} items`,
            onChange: (page, pageSize) => {
              setTxParams((prev) => ({
                ...prev,
                page,
                pageSize,
              }))
            },
          }}
        />
      )}

      <ModalViewDetails visible={viewDetails.toggle} dataModal={viewDetails.dataModal} close={setViewDetails} />
    </div>
  )
}

export default RecentTransaction
