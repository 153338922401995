import React from 'react'
import Svg from '../Svg'

const Icon = ({ fill, ...props }) => (
  <Svg viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 7C13 5.71442 12.6188 4.45771 11.9046 3.38879C11.1903 2.31987 10.1752 1.48675 8.98744 0.994783C7.79972 0.502813 6.49279 0.374092 5.23191 0.624896C3.97104 0.8757 2.81285 1.49476 1.90381 2.40381C0.994765 3.31285 0.3757 4.47104 0.124896 5.73191C-0.125908 6.99279 0.00281343 8.29972 0.494783 9.48744C0.986753 10.6752 1.81987 11.6903 2.88879 12.4046C3.95771 13.1188 5.21442 13.5 6.5 13.5L6.5 10.25C5.85721 10.25 5.22886 10.0594 4.6944 9.70228C4.15994 9.34516 3.74338 8.83758 3.49739 8.24372C3.25141 7.64986 3.18705 6.99639 3.31245 6.36596C3.43785 5.73552 3.74738 5.15642 4.2019 4.7019C4.65642 4.24738 5.23552 3.93785 5.86596 3.81245C6.49639 3.68705 7.14986 3.75141 7.74372 3.99739C8.33758 4.24338 8.84516 4.65994 9.20228 5.1944C9.55939 5.72886 9.75 6.35721 9.75 7H13Z"
      fill={`${fill || '#00C32B'}`}
    />
  </Svg>
)

export default Icon
