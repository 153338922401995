import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 11 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 11.25C0.5 11.0511 0.579018 10.8603 0.71967 10.7197C0.860322 10.579 1.05109 10.5 1.25 10.5H10.25C10.4489 10.5 10.6397 10.579 10.7803 10.7197C10.921 10.8603 11 11.0511 11 11.25C11 11.4489 10.921 11.6397 10.7803 11.7803C10.6397 11.921 10.4489 12 10.25 12H1.25C1.05109 12 0.860322 11.921 0.71967 11.7803C0.579018 11.6397 0.5 11.4489 0.5 11.25Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.28822 3.53025C8.14758 3.67085 7.95685 3.74984 7.75797 3.74984C7.5591 3.74984 7.36837 3.67085 7.22772 3.53025L6.25797 2.5605L6.25797 8.25C6.25797 8.44891 6.17896 8.63968 6.0383 8.78033C5.89765 8.92098 5.70689 9 5.50797 9C5.30906 9 5.11829 8.92098 4.97764 8.78033C4.83699 8.63968 4.75797 8.44891 4.75797 8.25L4.75797 2.5605L3.78822 3.53025C3.71904 3.60188 3.63628 3.65902 3.54478 3.69833C3.45327 3.73763 3.35486 3.75832 3.25527 3.75919C3.15569 3.76005 3.05693 3.74108 2.96476 3.70337C2.87259 3.66565 2.78885 3.60996 2.71843 3.53955C2.64801 3.46913 2.59232 3.38539 2.55461 3.29321C2.5169 3.20104 2.49792 3.10228 2.49878 3.0027C2.49965 2.90311 2.52034 2.8047 2.55965 2.7132C2.59895 2.62169 2.65609 2.53893 2.72772 2.46975L4.97772 0.21975C5.11837 0.079147 5.3091 0.000159938 5.50797 0.000159955C5.70685 0.000159972 5.89758 0.0791471 6.03822 0.21975L8.28822 2.46975C8.42883 2.6104 8.50781 2.80113 8.50781 3C8.50781 3.19887 8.42883 3.3896 8.28822 3.53025Z"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1713_38641"
        x1="0.924449"
        y1="11.296"
        x2="2.61858"
        y2="7.27502"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42FD24" />
        <stop offset="1" stopColor="#13FFF1" stopOpacity="0.96" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1713_38641"
        x1="8.2649"
        y1="4.22414"
        x2="2.14718"
        y2="5.60913"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42FD24" />
        <stop offset="1" stopColor="#13FFF1" stopOpacity="0.96" />
      </linearGradient>
    </defs>
  </Svg>
)

export default Icon
