import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { getDateToDate } from 'helpers/Date'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup'
import { getGameDetailState } from 'state/lottery/actions'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { Spin } from 'antd'

const Banner = ({ currentGame, day, loading, setLoading }) => {
  const dispatch = useDispatch()
  const bannerRef = useRef(null)
  const [gameDetail, setGameDetail] = useState()
  const [lottoSpinning, setLottoSpinning] = useState(false)

  const [counter, updateCounter] = useState({
    weekdays: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    totalRemain: null,
  })

  const [paramsGameDetail, setParamsGameDetail] = useState({
    game_code: '',
  })

  useEffect(() => {
    setParamsGameDetail({ game_code: currentGame?.game_code })
  }, [currentGame])

  useEffect(() => {
    setParamsGameDetail({ game_code: day })
  }, [day])

  useEffect(() => {
    setLoading(true)
    dispatch(
      getGameDetailState(
        paramsGameDetail,
        (data) => {
          setGameDetail(data)
          setLoading(false)
        },
        () => {
          setGameDetail(null)
          setLoading(false)
        },
      ),
    )
  }, [dispatch, paramsGameDetail, setLoading])

  useEffect(() => {
    let timer = 0
    let timer2 = 0
    const spaceTimeOfPrizeAndEnd =
      (gameDetail?.gameSource?.end_time.split(':')[0] * 60 * 60 + gameDetail?.gameSource?.end_time.split(':')[1] * 60) * 1000 -
      (gameDetail?.gameSource?.prize_announcement_time.split(':')[0] * 60 * 60 +
        gameDetail?.gameSource?.prize_announcement_time.split(':')[1] * 60) *
        1000

    const nextTimeLottoSpin = new Date(gameDetail?.end_date * 1000)
    const nextTimeLottoSpinUtc =
      new Date(
        nextTimeLottoSpin.getUTCFullYear(),
        nextTimeLottoSpin.getUTCMonth(),
        nextTimeLottoSpin.getUTCDate(),
        nextTimeLottoSpin.getUTCHours(),
        nextTimeLottoSpin.getUTCMinutes(),
        nextTimeLottoSpin.getUTCSeconds(),
      ).getTime() - spaceTimeOfPrizeAndEnd

    const nextTimeEnd = new Date(gameDetail?.end_date * 1000)
    const nextTimeEndUtc = new Date(
      nextTimeEnd.getUTCFullYear(),
      nextTimeEnd.getUTCMonth(),
      nextTimeEnd.getUTCDate(),
      nextTimeEnd.getUTCHours(),
      nextTimeEnd.getUTCMinutes(),
      nextTimeEnd.getUTCSeconds(),
    ).getTime()

    function getTimeToLottoEndSpin() {
      const currentTime = new Date()
      const currentTimeUtc = new Date(
        currentTime.getUTCFullYear(),
        currentTime.getUTCMonth(),
        currentTime.getUTCDate(),
        currentTime.getUTCHours(),
        currentTime.getUTCMinutes(),
        currentTime.getUTCSeconds(),
      ).getTime()

      if (nextTimeEndUtc >= currentTimeUtc) {
        updateCounter(getDateToDate(currentTimeUtc, nextTimeEndUtc))
      } else {
        setLottoSpinning(false)
        clearInterval(timer2)
        setParamsGameDetail('')
        setGameDetail('')
      }
    }

    function getTimeToLottoSpinning() {
      const currentTime = new Date()
      const currentTimeUtc = new Date(
        currentTime.getUTCFullYear(),
        currentTime.getUTCMonth(),
        currentTime.getUTCDate(),
        currentTime.getUTCHours(),
        currentTime.getUTCMinutes(),
        currentTime.getUTCSeconds(),
      ).getTime()

      if (nextTimeLottoSpinUtc >= currentTimeUtc) {
        updateCounter(getDateToDate(currentTimeUtc, nextTimeLottoSpinUtc))
      } else if (nextTimeLottoSpinUtc < currentTimeUtc) {
        setLottoSpinning(true)
        clearInterval(timer)
        getTimeToLottoEndSpin()
        timer2 = setInterval(() => getTimeToLottoEndSpin(), 1000)
      }
    }
    getTimeToLottoSpinning()
    timer = setInterval(() => getTimeToLottoSpinning(), 1000)

    return () => {
      clearInterval(timer)
      clearInterval(timer2)
    }
  }, [gameDetail])

  const handleImageLoad = () => {
    const scaleFactor = bannerRef.current.clientWidth / 990
    const height = bannerRef.current.clientHeight * (1 / scaleFactor)
    const width = bannerRef.current.clientWidth * (1 / scaleFactor)
    bannerRef.current.style.width = `${width}px`
    bannerRef.current.style.height = `${height}px`
    bannerRef.current.style.transform = `scale(${scaleFactor})`
    bannerRef.current.style.marginBottom = `-${height - height * scaleFactor}px`
  }

  return (
    <div className="wrapper-banner">
      <div className="warp-banner" ref={bannerRef}>
        <img src="/images/home/banner/banner-home-2.png" alt="Banner" onLoad={handleImageLoad} />

        {!loading ? (
          <>
            <div className="warp-banner-halo-1" />
            <div className="warp-banner-halo-2" />
            <div className="warp-banner-halo-3" />

            <div className="warp-banner-halo-circle warp-banner-halo-circle-1">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-2">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-3">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-4">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-5">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-6">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-7">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-8">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-9">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-10">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-11">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-12">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-13">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-14">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-15">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-16">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-17">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-18">
              <span />
              <span />
            </div>
            <div className="warp-banner-halo-circle warp-banner-halo-circle-19">
              <span />
              <span />
            </div>

            {gameDetail?.game_code && gameDetail?.end_date < new Date().getTime() && (
              <p className="warp-banner-content-game-id">
                <FormattedMessage id="Game Id" />: <span>{gameDetail?.game_code}</span>
              </p>
            )}

            <div className="warp-banner-content">
              <div className="warp-banner-content-money">
                <img src="/images/Home/banner/banner-title.png" alt="Next Draw In" />
                <span style={{ display: 'flex' }}>
                  {gameDetail ? (
                    <>
                      <span>$</span>
                      <CountUp end={gameDetail?.gameType?.total_reward / 10 ** 18} separator="," />
                    </>
                  ) : (
                    <span style={{ width: 'max-content' }}>
                      <FormattedMessage id="Game Ended" />
                    </span>
                  )}
                </span>
              </div>

              <div style={{ visibility: !gameDetail && 'hidden' }} className="warp-banner-wrapper-content">
                <p className="warp-banner-content-title">{gameDetail?.gameSource?.title}</p>

                <div className="warp-banner-content-countdown">
                  <div className="banner-countdown-item">
                    <span>{counter.hours + counter.days * 24 + counter.weekdays * 7}</span>
                    <span>
                      <FormattedMessage id="Hour" />
                    </span>
                  </div>

                  <div className="banner-countdown-item">
                    <span>{counter.minutes}</span>
                    <span>
                      <FormattedMessage id="Min" />
                    </span>
                  </div>

                  <div className="banner-countdown-item">
                    <span>{counter.seconds}</span>
                    <span>
                      <FormattedMessage id="Second" />
                    </span>
                  </div>
                </div>

                <div className="banner-button">
                  {lottoSpinning ? (
                    <button type="button">
                      <p>
                        <FormattedMessage id="Number Generator" />
                      </p>
                    </button>
                  ) : (
                    <Link to="/buy-ticket">
                      <button type="button">
                        <p>
                          <FormattedMessage id="Buy Ticket" />
                        </p>
                        <p>
                          <FormattedMessage id="Price" />: {gameDetail?.price / 10 ** 18}$
                        </p>
                      </button>
                    </Link>
                  )}
                </div>

                <p className="warp-banner-content-note">
                  <FormattedMessage id="Don't miss your chance!" /> <span>{gameDetail?.totalSold}</span>{' '}
                  <FormattedMessage id="tickets have been sold today!" />
                </p>

                <a href="#how_to_play">
                  <p className="warp-banner-content-rules">
                    <FormattedMessage id="Rules" /> {'>'}
                    {'>'}
                  </p>
                </a>
              </div>
            </div>
          </>
        ) : (
          <Spin spinning={loading} style={{ position: 'absolute', left: '50%', top: '50%' }} />
        )}
      </div>
    </div>
  )
}

export default Banner
