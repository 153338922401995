import * as types from './constants'
import { DEFAULT_LANGUAGE } from 'language/config'

export const setLanguage = ({ language = DEFAULT_LANGUAGE, reload = false }) => ({
  type: types.SET_LANGUAGE,
  payload: language,
  reload: Boolean(`${reload}`),
})

export const getStaticData = (payload) => ({
  type: types.GET_STATIC_DATA,
  payload,
})

export const getStaticDataSuccess = (payload) => ({
  type: types.GET_STATIC_DATA_SUCCESS,
  payload,
})

export const getListCountry = (payload) => ({
  type: types.GET_LIST_COUNTRY,
  payload,
})

export const getListCountrySuccess = (payload) => ({
  type: types.GET_LIST_COUNTRY_SUCCESS,
  payload,
})
