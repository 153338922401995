/* eslint-disable no-nested-ternary */
import useLast from 'hooks/useLast'
import React, { useState } from 'react'
import './styles.scss'

const r = 15

const DrawPieChartItem = ({
  item,
  index,
  percentBefore,
  colorChart,
  transformItemChart,
  setTextPercent,
  setTextPercentColor,
  itemWidthCircle,
  dataChart,
  totalPercent,
  setHoverItemSub,
  ...props
}) => {
  const [hover, setHover] = useState(false)

  const onMouseEnterHandler = () => {
    setHover(true)
    setTextPercent(item.percent)
    setTextPercentColor(colorChart[index]?.color)
    setHoverItemSub(colorChart[index]?.color)
  }

  const onMouseLeaveHandler = () => {
    setHover(false)
    setHoverItemSub(null)
  }

  return (
    <>
      {totalPercent ? (
        <>
          {item.percent !== '~' && (
            <circle
              onMouseEnter={() => onMouseEnterHandler()}
              onMouseLeave={() => onMouseLeaveHandler()}
              className="draw-pie-chart-item"
              style={{
                transform: hover
                  ? `rotate(${transformItemChart?.[index]}deg) scale(1.05)`
                  : `rotate(${transformItemChart?.[index]}deg)`,
                transformOrigin: 'center',
              }}
              stroke={colorChart[index]?.color}
              strokeWidth={`${itemWidthCircle?.filter((el) => el.percent === item.percent)?.[0]?.width}`}
              strokeDasharray={`${2 * Math.PI * r * (item.percent / 100)},${2 * Math.PI * r}`}
              fill="none"
              cx="50%"
              cy="50%"
              r={`${r}`}
              {...props}
            />
          )}
        </>
      ) : (
        <circle
          onMouseEnter={() => onMouseEnterHandler()}
          onMouseLeave={() => onMouseLeaveHandler()}
          className="draw-pie-chart-item"
          style={{
            transform: hover ? `rotate(-90deg) scale(1.05)` : `rotate(-90deg)`,
            transformOrigin: 'center',
          }}
          stroke="#666"
          strokeWidth="8"
          strokeDasharray={`${2 * Math.PI * r * (100 / 100)},${2 * Math.PI * r}`}
          fill="none"
          cx="50%"
          cy="50%"
          r={`${r}`}
          {...props}
        />
      )}
    </>
  )
}

export default DrawPieChartItem
