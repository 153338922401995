import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format'
import './style.scss'
import TableWrapper from 'components/TableWrapper'
import { roundNumber } from 'helpers/CommonHelper'
import { WithdrawIcon, DepositIcon, BtnSearchIcon } from 'widgets/Svg'
import Loading from 'widgets/Loading/Loading'
import { DECIMALS } from 'constants/index'
import { Switch, Input } from 'antd'
import WalletActions from '../WalletActions'
import { FormattedMessage } from 'react-intl'

const { Search } = Input

const WalletsList = ({ currency }) => {
  const [smallerThanOne, setSmallerThanOne] = useState(false)
  const [currencySorting, setCurrencySorting] = useState(currency)
  const [searchCode, setSearchCode] = useState()
  const [openSearch, setOpenSearch] = useState(0)

  useEffect(() => {
    if (smallerThanOne) {
      setCurrencySorting(currency.filter((item) => item.usdValue > 1))
    } else {
      setCurrencySorting(currency)
    }
  }, [currency, smallerThanOne])

  const onChange = (checked) => {
    setSmallerThanOne(checked)
  }
  const handleSearchCode = (value) => {
    setSearchCode(value.toUpperCase())
  }
  const header = [
    {
      title: <FormattedMessage id="Currency" />,
      dataIndex: 'title',
      width: window.innerWidth > 991 && '30%',
      render: (text, record) => (
        <div className="coin-data">
          <img src={record.icon} alt="" />
          <div className="coin-data-label">
            <div className="data-label-text">{text}</div>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <FormattedMessage id="Amount" />
        </div>
      ),
      dataIndex: 'balance',
      width: window.innerWidth > 991 && '20%',
      render: (text) =>
        text !== undefined ? (
          <div style={{ textAlign: 'center' }}>
            <CurrencyFormat
              value={roundNumber(text / 10 ** DECIMALS, 6)}
              displayType="text"
              thousandSeparator
              // suffix={` ${record.title}`}
            />
          </div>
        ) : (
          <Loading />
        ),
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <FormattedMessage id="USD value" />
        </div>
      ),
      dataIndex: 'usdValue',
      width: window.innerWidth > 991 && '20%',
      mobileHidden: true,
      render: (text, record) =>
        record.balance !== undefined && record.balance !== null ? (
          <div style={{ textAlign: 'center' }}>
            <CurrencyFormat value={roundNumber(text, 2)} displayType="text" thousandSeparator suffix="$" />
          </div>
        ) : (
          <Loading />
        ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <div className="actions">
          <Link to={`/wallet/deposit/${record.code}`}>
            <button type="button">
              <DepositIcon /> <FormattedMessage id="Deposit" />
            </button>
          </Link>
          <Link to={`/wallet/withdraw/${record.code}`}>
            <button type="button">
              <WithdrawIcon /> <FormattedMessage id="Withdraw" />
            </button>
          </Link>
          {/* <Link to={`/stake/stake-history/${record.code}`}>
            <button type="button">Staking</button>
          </Link> */}
        </div>
      ),
    },
  ]
  const header2 = [
    {
      title: <FormattedMessage id="Currency" />,
      dataIndex: 'title',
      width: '30%',
      render: (text, record) => (
        <div className="coin-data">
          <img src={record.icon} alt="" />
          <div className="coin-data-label">
            <div className="data-label-text">{text}</div>
          </div>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Amount" />,
      dataIndex: 'balance',
      width: '30%',
      render: (text) =>
        text !== undefined ? (
          <CurrencyFormat
            value={roundNumber(text / 10 ** DECIMALS, 6)}
            displayType="text"
            thousandSeparator
            // suffix={` ${record.title}`}
          />
        ) : (
          <Loading />
        ),
    },
    {
      title: <FormattedMessage id="USD value" />,
      dataIndex: 'usd_rate',
      width: '30%',
      // mobileHidden: true,
      render: (text, record) =>
        record.balance !== undefined && record.balance !== null ? (
          <>
            <CurrencyFormat
              value={roundNumber(text * (record.balance / 10 ** DECIMALS), 2)}
              displayType="text"
              thousandSeparator
              suffix="$"
            />
          </>
        ) : (
          <Loading />
        ),
    },
    {
      dataIndex: <FormattedMessage id="action" />,
      render: (_, record) => (
        <div className="wallet-item-right">
          <div className="actions">
            <WalletActions item={record} />
          </div>
        </div>
      ),
    },
  ]

  return (
    <div className="wallet-list-container">
      <div className="navigation-header">
        <div className="navigation-left">
          {openSearch ? (
            <div className="navigation-right">
              <Search
                placeholder="Search Currency"
                value={searchCode}
                onChange={(value) => handleSearchCode(value.target.value)}
              />
            </div>
          ) : (
            <h2>
              <FormattedMessage id="My Assets" />
            </h2>
          )}

          <div className="right-switch">
            {!openSearch && (
              <div>
                <Switch onChange={onChange} />
                <p>
                  <FormattedMessage id="Hide Small Balance" />
                </p>
              </div>
            )}

            <BtnSearchIcon onClick={() => setOpenSearch((prev) => !prev)} />
          </div>
        </div>

        {window.innerWidth > 991 && (
          <div className="navigation-right">
            <Search placeholder="Search Currency" value={searchCode} onChange={(value) => handleSearchCode(value.target.value)} />
          </div>
        )}
      </div>

      {window.innerWidth >= 768 && (
        <>
          {searchCode ? (
            <TableWrapper
              className="table-wallet-list-desktop"
              columns={header}
              dataSource={
                currencySorting.filter((entry) =>
                  Object.values(entry).some((val) => typeof val === 'string' && val.includes(searchCode)),
                ) || []
              }
              rowKey={(record) => record._id}
              pagination={false}
            />
          ) : (
            <TableWrapper
              className="table-wallet-list-desktop"
              columns={header}
              dataSource={currencySorting || []}
              rowKey={(record) => record._id}
              pagination={false}
            />
          )}
        </>
      )}
      {window.innerWidth <= 768 && (
        <>
          {searchCode ? (
            <TableWrapper
              className="wallet-list-mobile"
              columns={header2}
              dataSource={
                currencySorting.filter((entry) =>
                  Object.values(entry).some((val) => typeof val === 'string' && val.includes(searchCode)),
                ) || []
              }
              rowKey={(record) => record._id}
              pagination={false}
            />
          ) : (
            <TableWrapper
              className="wallet-list-mobile"
              columns={header2}
              dataSource={currencySorting || []}
              rowKey={(record) => record._id}
              pagination={false}
            />
          )}
        </>
      )}
      {/* <ModalCreateWallet visible={modalCreateWallet.toggle} onCancel={setModalCreateWallet} /> */}
    </div>
  )
}
export default WalletsList
