import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12 6L3 11.1962L3 0.803847L12 6Z" fill="#41FB21" />
    <path d="M12 1V11" stroke="#41FB21" />
  </Svg>
)

export default Icon
