import * as types from './constants'

export const buyLottery = (payload, cbs, cbe) => ({
  type: types.BUY_LOTTERY,
  payload,
  cbs,
  cbe,
})

export const getHistoryBuy = (payload) => ({
  type: types.GET_HISTORY_BUY,
  payload,
})
export const getHistoryBuySuccess = (payload) => ({
  type: types.GET_HISTORY_BUY_SUCCESS,
  payload,
})

export const getHistoryBuyState = (payload, cbs, cbe) => ({
  type: types.GET_HISTORY_BUY_STATE,
  payload,
  cbs,
  cbe,
})

export const getListGame = (payload, cbs, cbe) => ({
  type: types.GET_LIST_GAME,
  payload,
  cbs,
  cbe,
})
export const getListGameSuccess = (payload) => ({
  type: types.GET_LIST_GAME_SUCCESS,
  payload,
})

export const getGameDetail = (payload) => ({
  type: types.GET_GAME_DETAIL,
  payload,
})
export const getGameDetailSuccess = (payload) => ({
  type: types.GET_GAME_DETAIL_SUCCESS,
  payload,
})

export const getGameDetailState = (payload, cbs, cbe) => ({
  type: types.GET_GAME_DETAIL_STATE,
  payload,
  cbs,
  cbe,
})

export const getWinnerList = (payload, cbs, cbe) => ({
  type: types.GET_WINNER_LIST,
  payload,
  cbs,
  cbe,
})

export const getGameReport = (payload, cbs, cbe) => ({
  type: types.GET_GAME_REPORT,
  payload,
  cbs,
  cbe,
})
export const getGameReportSuccess = (payload) => ({
  type: types.GET_GAME_REPORT_SUCCESS,
  payload,
})
