/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Form } from 'antd'
import './styles.scss'

import Input from 'widgets/Input'
import FormItem from 'widgets/FormItem'
import AuthWrapper from 'modules/auth/components/AuthWrapper'
import AuthCard from 'modules/auth/components/AuthCard'
import ButtonSubmit from 'widgets/ButtonSubmit'
import { postSignup } from 'state/auth/actions'
import VerifyActiveAccountCode from 'modules/auth/components/VerifyActiveAccountCode'
import { getListCountry } from 'state/app/actions'
import ControlInputSelect from 'components/ControlInputSelect'
import { regexPhone } from '../../../../utils/Regex'
import { ArrowBackIcon } from 'widgets/Svg'
import { FormattedMessage, injectIntl } from 'react-intl'

const Signup = ({ intl, history, location }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { listCountry } = useSelector((state) => state.app)
  const [view, setView] = useState(history.location.state?.view || 0)
  const [email, setEmail] = useState(history.location.state?.email || null)
  const [inputCountry, setInputCountry] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(getListCountry())
  }, [dispatch])

  const dataSelectCountry = useMemo(() => {
    if (listCountry) {
      return listCountry.map((item) => ({
        ...item,
        label: item.title,
        value: item.code,
      }))
    }
    return []
  }, [listCountry])

  // useEffect(() => {
  //   setInputCountry(dataSelectCountry?.[0]?.value)
  // }, [dataSelectCountry])

  const onFormSubmit = () => {
    setLoading(true)
    form.validateFields().then((values) => {
      if (!values.password || !values.confirm) return
      if (values.password !== values.confirm) return

      delete values.confirm
      const formData = {
        ...values,
        country: inputCountry,
      }

      if (!formData.sponsorKey) delete formData.sponsorKey

      setEmail(values.email)

      dispatch(
        postSignup(
          formData,
          () => {
            setView(1)
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        ),
      )
    })
  }

  useEffect(() => {
    const url = new URLSearchParams(location.search)
    const sponsorKey = url.get('sponsorKey') || url.get('sponsorkey')
    form.setFieldsValue({
      sponsorKey: sponsorKey || '',
    })
  }, [location])

  return (
    <AuthWrapper>
      <div className="wrapper-form-signup">
        {view === 0 ? (
          <AuthCard className="form-signup fadeIn">
            <div className="header-auth-card">
              <div>
                <Link to="/login">
                  <span>
                    <FormattedMessage id="Sign in" />
                  </span>
                </Link>
                <Link className="active" to="/register">
                  <span>
                    <FormattedMessage id="Sign up" />
                  </span>
                </Link>
              </div>

              <Link to="/">
                <ArrowBackIcon />
              </Link>
            </div>

            <div className="form-signup-container">
              <Form layout="vertical" form={form} onFinish={onFormSubmit}>
                <FormItem
                  label={
                    <span className="form-title">
                      <FormattedMessage id="Email" />
                    </span>
                  }
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="Please enter email field" />,
                    },
                  ]}
                >
                  <Input placeholder="example@gmail.com" />
                </FormItem>
                <FormItem
                  label={
                    <span className="form-title">
                      <FormattedMessage id="Password" />
                    </span>
                  }
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="Please enter password field" />,
                    },
                    {
                      min: 10,
                      message: (
                        <FormattedMessage
                          id={`The 'password' field length must be greater than or equal to 10 characters long.`}
                        />
                      ),
                    },
                  ]}
                >
                  <Input placeholder="**********" type="password" />
                </FormItem>
                <FormItem
                  label={
                    <span className="form-title">
                      <FormattedMessage id="Confirm Password" />
                    </span>
                  }
                  name="confirm"
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="Please confirm your password!" />,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(intl.formatMessage({ id: 'The two passwords that you entered do not match!' })),
                        )
                      },
                    }),
                  ]}
                >
                  <Input placeholder="**********" type="password" />
                </FormItem>
                <FormItem
                  label={
                    <span className="form-title">
                      <FormattedMessage id="First name" />
                    </span>
                  }
                  name="fistname"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="Please enter fistname field" />,
                    },
                  ]}
                >
                  <Input placeholder={intl.formatMessage({ id: 'Please enter your first name' })} type="text" />
                </FormItem>
                <FormItem
                  label={
                    <span className="form-title">
                      <FormattedMessage id="Last name" />
                    </span>
                  }
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="Please enter lastname field" />,
                    },
                  ]}
                >
                  <Input placeholder={intl.formatMessage({ id: 'Please enter your last name' })} type="text" />
                </FormItem>
                <FormItem
                  label={
                    <span className="form-title">
                      <FormattedMessage id="Phone number" />
                    </span>
                  }
                  name="phone_number"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="Please enter phone number field" />,
                    },
                    { pattern: regexPhone, message: <FormattedMessage id="Invalid Phone number" /> },
                  ]}
                >
                  <Input placeholder="123456789" type="text" />
                </FormItem>
                <FormItem
                  label={
                    <span className="form-title-country">
                      <FormattedMessage id="Country" />
                    </span>
                  }
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="Please enter country field" />,
                    },
                  ]}
                >
                  <ControlInputSelect
                    style={{ width: '100%' }}
                    placeholder="Select Country"
                    dataSelect={dataSelectCountry}
                    value={inputCountry || undefined}
                    onChange={(v) => setInputCountry(v ? JSON.parse(v).value : v.value)}
                    showSearch
                    name="country"
                  />
                </FormItem>
                <FormItem
                  label={
                    <span className="form-title">
                      <FormattedMessage id="Sponsor key" />
                    </span>
                  }
                  name="sponsorKey"
                >
                  <Input placeholder={intl.formatMessage({ id: 'Sponsor key' })} type="text" />
                </FormItem>
                <FormItem
                  className="form-item-checkbox"
                  name="agreement"
                  formType="checkbox"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error(<FormattedMessage id="Should accept agreement" />)),
                    },
                  ]}
                >
                  <Checkbox>
                    <FormattedMessage id="I have read and agree to User Agreement and Privacy Policy." />
                  </Checkbox>
                </FormItem>
                <FormItem>
                  <ButtonSubmit type="submit" className="form-action" style={{ maxWidth: '100%' }} loading={loading}>
                    <FormattedMessage id="SIGN UP" />
                  </ButtonSubmit>
                </FormItem>
              </Form>
            </div>
          </AuthCard>
        ) : (
          // <VerifyActiveAccountCode email={email} token={view?.data?.token} />
          <VerifyActiveAccountCode email={email} token={view?.data?.token} />
        )}
      </div>
    </AuthWrapper>
  )
}

export default injectIntl(Signup)
