import React from 'react'
import { Spin } from 'antd'
import './styles.scss'

/**
 * @Dev
 * @param {@} className
 * @param {@} size small |standard | larger
 */

const ButtonDisable = ({
  gradient,
  background,
  radius,
  className,
  size,
  disabled,
  loading,
  arrow,
  hoverAble,
  onClick,
  children,
  ...props
}) => (
  <button
    className={`btn-gradient ${className}`}
    type="button"
    disabled={disabled}
    data-size={size ? size.toString() : 'standard'}
    data-loading={loading.toString()}
    data-hoverable={hoverAble.toString()}
    onClick={() => {
      if (!loading && !disabled) {
        onClick()
      }
    }}
    style={{ backgroundImage: `${gradient}`, borderRadius: radius }}
    {...props}
  >
    <div style={{ backgroundColor: background, borderRadius: radius }}>
      <p>{children}</p>
      {arrow}
      <Spin spinning={loading} />
    </div>
  </button>
)

ButtonDisable.defaultProps = {
  className: '',
  arrow: '',
  disabled: false,
  loading: false,
  hoverAble: true,
  onClick: () => null,
}

export default ButtonDisable
