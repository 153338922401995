import React from 'react'
import { Redirect } from 'react-router-dom'

// Redirects to register but only replace the pathname
export function RedirectTo({ location }) {
  const execPath = location.search?.startsWith('?sponsorKey=') || location.search?.startsWith('?sponsorkey=')
  return <Redirect to={{ ...location, pathname: execPath ? '/register' : '/' }} />
}

export default RedirectTo
